import React, { useEffect, useState, useRef } from "react";
import { Table, InputGroup, ToggleButton, Button, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import sr from "date-fns/locale/sr-Latn";
import ErrorModal from "../components/errorModal";
import Modal from "react-bootstrap/Modal";
import Loader from "../components/loader";
registerLocale("sr", sr);

export default function ModulesAndCities() {
  const [modulesList, setModulesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const moduleId = "c783eed3cfc1c978fe76e15af007e0d0";
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [deleteType, setDeleteType] = useState("");
  const [editModule, setEditModule] = useState(false);
  const [selectedModule, setSelectedModule] = useState([]);
  const [editCity, setEditCity] = useState(false);
  const [selectedCity, setSelectedCity] = useState([]);
  const [addModule, setAddModule] = useState(false);
  const [addCity, setAddCity] = useState(false);
  const [phone, setPhone] = useState([]);
  const [email, setEmail] = useState([]);
  const [web, setWeb] = useState([]);
  const [metaValue, setMetaValue] = useState("");
  const phoneField = useRef();
  const emailField = useRef();
  const webField = useRef();

  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_modules_cities.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setModulesList(receivedData.modules_list);
          setCitiesList(receivedData.cities_list);
          setTimeout(() => {
            setLoading(false);
            setAuthorized(true);
          }, 200);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  }, []);

  const handleActiveModule = (index, module_id, active) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        module_id: module_id,
        active: active,
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_toggle_module_state.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.response) {
          let newData = modulesList.map((module, i) => {
            if (i === index) {
              module.active = responseData.active;
              return module;
            } else {
              return module;
            }
          });
          setModulesList(newData);
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  const handleActiveCity = (index, selected_city_id, active) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        selected_city_id: selected_city_id,
        active: active,
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_toggle_city_state.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.response) {
          let newData = citiesList.map((city, i) => {
            if (i === index) {
              city.active = responseData.active;
              return city;
            } else {
              return city;
            }
          });
          setCitiesList(newData);
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  const closeDeleteModal = () => {
    setDeleteId("");
    setDeleteName("");
    setDeleteType("");
    setDeleteModal(false);
  };

  const handleDelete = (type, id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        type: type,
        id: id,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_delete_resource.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          if (type === "module") {
            setModulesList(
              modulesList.filter((module) => module.module_id !== id)
            );
          } else {
            setCitiesList(citiesList.filter((city) => city.city_id !== id));
          }
          setDeleteId("");
          setDeleteName("");
          setDeleteType("");
          setDeleteModal(false);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const showModule = (module_id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        module_id: module_id,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_single_module.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setSelectedModule(receivedData);
          setEditModule(true);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const handleSaveModule = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        module_id: selectedModule.module_id,
        module_name: selectedModule.module_name,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_edit_single_module.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setModulesList(receivedData);
          setEditModule(false);
          setAddModule(false);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const showCity = (selected_city_id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        selected_city_id: selected_city_id,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_single_city2.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setSelectedCity(receivedData.single_city);
          setPhone(receivedData.phone);
          setEmail(receivedData.email);
          setWeb(receivedData.web);
          setEditCity(true);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const saveMeta = (meta_type) => {
    if (metaValue !== "") {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          city_id: global.getCity(),
          selected_city_id: selectedCity.city_id,
          meta_type: meta_type,
          meta_value: metaValue,
          session_id: localStorage.getItem("authToken"),
          module: moduleId,
        }),
      };
      fetch(
        process.env.REACT_APP_BACKEND_ROOT + "_private/_edit_meta.php",
        requestOptions
      )
        .then((response) => response.json())
        .then((responseData) => {
          if (!responseData.response) {
            setPhone(responseData.phone);
            setEmail(responseData.email);
            setWeb(responseData.web);
            setMetaValue("");
          } else {
            setErrorMessage(responseData.response);
            setError(true);
          }
        });
    }
  };

  const deleteMeta = (meta_id, meta_type) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        meta_id: meta_id,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_delete_meta.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          if (meta_type === "phone") {
            setPhone(phone.filter((items) => items.meta_id !== meta_id));
          }
          if (meta_type === "email") {
            setEmail(email.filter((items) => items.meta_id !== meta_id));
          }
          if (meta_type === "web") {
            setWeb(web.filter((items) => items.meta_id !== meta_id));
          }
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const handleSaveCity = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        selected_city_id: selectedCity.city_id,
        name_sr: selectedCity.name_sr,
        name_en: selectedCity.name_en,
        name_cp: selectedCity.name_cp,
        lat: selectedCity.lat,
        lng: selectedCity.lng,
        url: selectedCity.url,
        path: selectedCity.path,
        app_name_sr: selectedCity.app_name_sr,
        app_name_en: selectedCity.app_name_en,
        app_name_cp: selectedCity.app_name_cp,
        android_app: selectedCity.android_app,
        ios_app: selectedCity.ios_app,
        facebook_page: selectedCity.facebook_page,
        alias: selectedCity.alias,
        shortlink: selectedCity.shortlink,
        description_sr: selectedCity.description_sr,
        description_en: selectedCity.description_en,
        description_cp: selectedCity.description_cp,
        publisher_sr: selectedCity.publisher_sr,
        publisher_en: selectedCity.publisher_en,
        publisher_cp: selectedCity.publisher_cp,
        use_cp: selectedCity.use_cp,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_edit_single_city.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setCitiesList(receivedData);
          setEditCity(false);
          setAddCity(false);
          setSelectedCity([]);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  return (
    <>
      <div className="container-lg pb-5">
        <div className="row">
          <div className="col-md mt-4 mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                <h3 className="mb-0">Moduli i gradovi</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body p-4">
                <div className="row mb-3">
                  <div className="col-md-8">
                    <h5 className="mb-0">Moduli</h5>
                  </div>
                  <div className="col-md-4 text-right">
                    <button
                      className="icon-16 grey-blue ms-2"
                      title="Dodaj modul"
                      onClick={() => {
                        setAddModule(true);
                      }}
                    >
                      <i className="bi bi-plus-circle-fill"></i>
                    </button>
                  </div>
                </div>
                <Table>
                  <thead>
                    <tr>
                      <th>Naziv modula</th>
                      <th>ID</th>
                      <th className="text-right">Funkcije</th>
                    </tr>
                  </thead>
                  <tbody>
                    {authorized &&
                      !loading &&
                      modulesList.map((module, i) => (
                        <tr className="table-hover" key={module.module_id}>
                          <td className="standard-size">
                            {module.module_name}
                          </td>
                          <td className="standard-size">{module.module_id}</td>
                          <td align={"right"} width="20%">
                            <button
                              className="icon-16 grey-blue ms-2"
                              title="Uredi modul"
                              onClick={() => {
                                showModule(module.module_id);
                              }}
                            >
                              <i className="bi bi-pencil-fill"></i>
                            </button>

                            <button
                              className={
                                module.active === "1"
                                  ? "icon-16 green ms-2"
                                  : "icon-16 red ms-2"
                              }
                              title={
                                module.active === "1"
                                  ? "Aktivan"
                                  : "Nije aktivan"
                              }
                              onClick={() =>
                                handleActiveModule(
                                  i,
                                  module.module_id,
                                  module.active
                                )
                              }
                            >
                              {module.active === "1" ? (
                                <i className="bi bi-toggle-on"></i>
                              ) : (
                                <i className="bi bi-toggle-off"></i>
                              )}
                            </button>
                            <button
                              className="icon-16 grey-red ms-2"
                              title="Obriši modul"
                              onClick={() => {
                                setDeleteModal(true);
                                setDeleteId(module.module_id);
                                setDeleteName(module.module_name);
                                setDeleteType("module");
                              }}
                            >
                              <i className="bi bi-trash3-fill"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body p-4">
                <div className="row mb-3">
                  <div className="col-md-8">
                    <h5 className="mb-0">Gradovi</h5>
                  </div>
                  <div className="col-md-4 text-right">
                    <button
                      className="icon-16 grey-blue ms-2"
                      title="Dodaj grad"
                      onClick={() => {
                        setAddCity(true);
                      }}
                    >
                      <i className="bi bi-plus-circle-fill"></i>
                    </button>
                  </div>
                </div>
                <Table>
                  <thead>
                    <tr>
                      <th>Naziv grada</th>
                      <th>ID</th>
                      <th className="text-right">Funkcije</th>
                    </tr>
                  </thead>
                  <tbody>
                    {authorized &&
                      !loading &&
                      citiesList.map((city, i) => (
                        <tr className="table-hover" key={city.city_id}>
                          <td className="standard-size">{city.name_sr}</td>
                          <td className="standard-size">{city.city_id}</td>
                          <td align={"right"} width="20%">
                            <button
                              className="icon-16 grey-blue ms-2"
                              title="Uredi grad"
                              onClick={() => {
                                showCity(city.city_id);
                              }}
                            >
                              <i className="bi bi-pencil-fill"></i>
                            </button>
                            <button
                              className={
                                city.active === "1"
                                  ? "icon-16 green ms-2"
                                  : "icon-16 red ms-2"
                              }
                              title={
                                city.active === "1" ? "Aktivan" : "Nije aktivan"
                              }
                              onClick={() =>
                                handleActiveCity(i, city.city_id, city.active)
                              }
                            >
                              {city.active === "1" ? (
                                <i className="bi bi-toggle-on"></i>
                              ) : (
                                <i className="bi bi-toggle-off"></i>
                              )}
                            </button>
                            <button
                              className="icon-16 grey-red ms-2"
                              title="Obriši grad"
                              onClick={() => {
                                setDeleteModal(true);
                                setDeleteId(city.city_id);
                                setDeleteName(city.name_sr);
                                setDeleteType("city");
                              }}
                            >
                              <i className="bi bi-trash3-fill"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Loader loading={loading} />

      {!loading && (
        <>
          <Modal
            show={deleteModal}
            onHide={closeDeleteModal}
            backdrop="static"
            keyboard={false}
            centered
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {deleteType === "module" ? "Brisanje module" : "Brisanje grada"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row-md">
                <div className="col-md mb-2">
                  {deleteType === "module"
                    ? "Obrisati modul " + deleteName + "?"
                    : "Obrisati grad " + deleteName + "?"}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeDeleteModal}>
                Zatvori
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  handleDelete(deleteType, deleteId);
                }}
              >
                Obriši
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={editModule}
            onHide={() => {
              setSelectedModule([]);
              setEditModule(false);
            }}
            backdrop="static"
            keyboard={false}
            centered
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>Uredi modul</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Label className="mb-0 standard-size">
                Naziv modula
              </Form.Label>
              <InputGroup className="mb-2">
                <Form.Control
                  className="form-control form-control-sm standard-size"
                  type="text"
                  defaultValue={selectedModule.module_name}
                  onChange={(cur) => {
                    let newData = { ...selectedModule };
                    newData.module_name = cur.target.value;
                    setSelectedModule(newData);
                  }}
                />
              </InputGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setSelectedModule([]);
                  setEditModule(false);
                }}
              >
                Zatvori
              </Button>
              <Button variant="primary" onClick={handleSaveModule}>
                Sačuvaj
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={editCity}
            onHide={() => {
              setSelectedCity([]);
              setEditCity(false);
            }}
            backdrop="static"
            keyboard={false}
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Uredi grad</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-6">
                  <Form.Label className="mb-1 standard-size flex">
                    <span className="margin-2">Koristi ćirilično pismo</span>
                    <button
                      className={
                        selectedCity.use_cp === "1"
                          ? "icon-16 green ms-2"
                          : "icon-16 red ms-2"
                      }
                      title={
                        selectedCity.use_cp === "1" ? "Uključeno" : "Isključeno"
                      }
                      onClick={() => {
                        let newData = { ...selectedCity };
                        if (selectedCity.use_cp === "1") {
                          newData.use_cp = "0";
                        } else {
                          newData.use_cp = "1";
                        }
                        setSelectedCity(newData);
                      }}
                    >
                      {selectedCity.use_cp === "1" ? (
                        <i className="bi bi-toggle-on"></i>
                      ) : (
                        <i className="bi bi-toggle-off"></i>
                      )}
                    </button>
                  </Form.Label>
                  <Form.Label className="mb-1 standard-size">
                    Naziv grada
                  </Form.Label>
                  <div className="row">
                    <div className="col-1 pt-1">SR</div>
                    <div className="col-11">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue={selectedCity.name_sr}
                          onChange={(cur) => {
                            let newData = { ...selectedCity };
                            newData.name_sr = cur.target.value;
                            setSelectedCity(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  {selectedCity.use_cp === "1" && (
                    <div className="row">
                      <div className="col-1 pt-1">CP</div>
                      <div className="col-11">
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            defaultValue={selectedCity.name_cp}
                            onChange={(cur) => {
                              let newData = { ...selectedCity };
                              newData.name_cp = cur.target.value;
                              setSelectedCity(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-1 pt-1">EN</div>
                    <div className="col-11">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue={selectedCity.name_en}
                          onChange={(cur) => {
                            let newData = { ...selectedCity };
                            newData.name_en = cur.target.value;
                            setSelectedCity(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <Form.Label className="mb-1 standard-size">
                    Naziv aplikacije
                  </Form.Label>
                  <div className="row">
                    <div className="col-1 pt-1">SR</div>
                    <div className="col-11">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue={selectedCity.app_name_sr}
                          onChange={(cur) => {
                            let newData = { ...selectedCity };
                            newData.app_name_sr = cur.target.value;
                            setSelectedCity(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  {selectedCity.use_cp === "1" && (
                    <div className="row">
                      <div className="col-1 pt-1">CP</div>
                      <div className="col-11">
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            defaultValue={selectedCity.app_name_cp}
                            onChange={(cur) => {
                              let newData = { ...selectedCity };
                              newData.app_name_cp = cur.target.value;
                              setSelectedCity(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-1 pt-1">EN</div>
                    <div className="col-11">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue={selectedCity.app_name_en}
                          onChange={(cur) => {
                            let newData = { ...selectedCity };
                            newData.app_name_en = cur.target.value;
                            setSelectedCity(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <Form.Label className="mb-1 standard-size">
                    Opis aplikacije
                  </Form.Label>
                  <div className="row">
                    <div className="col-1 pt-1">SR</div>
                    <div className="col-11">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue={selectedCity.description_sr}
                          onChange={(cur) => {
                            let newData = { ...selectedCity };
                            newData.description_sr = cur.target.value;
                            setSelectedCity(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  {selectedCity.use_cp === "1" && (
                    <div className="row">
                      <div className="col-1 pt-1">CP</div>
                      <div className="col-11">
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            defaultValue={selectedCity.description_cp}
                            onChange={(cur) => {
                              let newData = { ...selectedCity };
                              newData.description_cp = cur.target.value;
                              setSelectedCity(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-1 pt-1">EN</div>
                    <div className="col-11">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue={selectedCity.description_en}
                          onChange={(cur) => {
                            let newData = { ...selectedCity };
                            newData.description_en = cur.target.value;
                            setSelectedCity(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <Form.Label className="mb-1 standard-size">
                    Izdavač aplikacije
                  </Form.Label>
                  <div className="row">
                    <div className="col-1 pt-1">SR</div>
                    <div className="col-11">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue={selectedCity.publisher_sr}
                          onChange={(cur) => {
                            let newData = { ...selectedCity };
                            newData.publisher_sr = cur.target.value;
                            setSelectedCity(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  {selectedCity.use_cp === "1" && (
                    <div className="row">
                      <div className="col-1 pt-1">CP</div>
                      <div className="col-11">
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            defaultValue={selectedCity.publisher_cp}
                            onChange={(cur) => {
                              let newData = { ...selectedCity };
                              newData.publisher_cp = cur.target.value;
                              setSelectedCity(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-1 pt-1">EN</div>
                    <div className="col-11">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue={selectedCity.publisher_en}
                          onChange={(cur) => {
                            let newData = { ...selectedCity };
                            newData.publisher_en = cur.target.value;
                            setSelectedCity(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <Form.Label className="mb-0 standard-size">
                        Geografska širina
                      </Form.Label>
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue={selectedCity.lat}
                          onChange={(cur) => {
                            let newData = { ...selectedCity };
                            newData.lat = cur.target.value;
                            setSelectedCity(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                    <div className="col-6">
                      <Form.Label className="mb-0 standard-size">
                        Geografska dužina
                      </Form.Label>
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue={selectedCity.lng}
                          onChange={(cur) => {
                            let newData = { ...selectedCity };
                            newData.lng = cur.target.value;
                            setSelectedCity(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <Form.Label className="mb-0 standard-size">Url</Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      className="form-control form-control-sm standard-size"
                      type="text"
                      defaultValue={selectedCity.url}
                      onChange={(cur) => {
                        let newData = { ...selectedCity };
                        newData.url = cur.target.value;
                        setSelectedCity(newData);
                      }}
                    />
                  </InputGroup>
                  <Form.Label className="mb-0 standard-size">
                    Putanja na serveru
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      className="form-control form-control-sm standard-size"
                      type="text"
                      defaultValue={selectedCity.path}
                      onChange={(cur) => {
                        let newData = { ...selectedCity };
                        newData.path = cur.target.value;
                        setSelectedCity(newData);
                      }}
                    />
                  </InputGroup>
                  <Form.Label className="mb-0 standard-size">
                    Android app link
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      className="form-control form-control-sm standard-size"
                      type="text"
                      defaultValue={selectedCity.android_app}
                      onChange={(cur) => {
                        let newData = { ...selectedCity };
                        newData.android_app = cur.target.value;
                        setSelectedCity(newData);
                      }}
                    />
                  </InputGroup>
                  <Form.Label className="mb-0 standard-size">
                    iOS app link
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      className="form-control form-control-sm standard-size"
                      type="text"
                      defaultValue={selectedCity.ios_app}
                      onChange={(cur) => {
                        let newData = { ...selectedCity };
                        newData.ios_app = cur.target.value;
                        setSelectedCity(newData);
                      }}
                    />
                  </InputGroup>
                  <Form.Label className="mb-0 standard-size">
                    Facebook link
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      className="form-control form-control-sm standard-size"
                      type="text"
                      defaultValue={selectedCity.facebook_page}
                      onChange={(cur) => {
                        let newData = { ...selectedCity };
                        newData.facebook_page = cur.target.value;
                        setSelectedCity(newData);
                      }}
                    />
                  </InputGroup>
                  <Form.Label className="mb-0 standard-size">
                    Kratki link
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      className="form-control form-control-sm standard-size"
                      type="text"
                      defaultValue={selectedCity.shortlink}
                      onChange={(cur) => {
                        let newData = { ...selectedCity };
                        newData.shortlink = cur.target.value;
                        setSelectedCity(newData);
                      }}
                    />
                  </InputGroup>
                  <Form.Label className="mb-0 standard-size">Alias</Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      className="form-control form-control-sm standard-size"
                      type="text"
                      defaultValue={selectedCity.alias}
                      onChange={(cur) => {
                        let newData = { ...selectedCity };
                        newData.alias = cur.target.value;
                        setSelectedCity(newData);
                      }}
                    />
                  </InputGroup>
                </div>
                <div className="col-6">
                  <div className="row">
                    <Form.Label className="mb-0 standard-size">
                      Dodaj telefon
                    </Form.Label>
                    <div className="col-9">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          ref={phoneField}
                          onChange={(cur) => {
                            setMetaValue(cur.target.value);
                          }}
                        />
                      </InputGroup>
                    </div>
                    <div className="col-3">
                      <button
                        className="icon-24"
                        title="Dodaj telefon"
                        onClick={() => {
                          saveMeta("phone");
                          phoneField.current.value = "";
                        }}
                      >
                        <i className="bi bi-plus-circle-fill"></i>
                      </button>
                    </div>
                    <div className="col-12">
                      <Table>
                        <tbody>
                          {authorized &&
                            !loading &&
                            phone &&
                            phone.map((phoneItem, i) => (
                              <tr
                                className="table-hover"
                                key={phoneItem.meta_id}
                              >
                                <td
                                  valign="middle"
                                  className="standard-size cursor-arrow"
                                >
                                  {phoneItem.meta_value}
                                </td>
                                <td valign="middle" align={"right"} width="15%">
                                  <button
                                    className="icon-16 grey-red ms-2"
                                    title="Obriši broj telefona"
                                    onClick={() => {
                                      deleteMeta(phoneItem.meta_id, "phone");
                                    }}
                                  >
                                    <i className="bi bi-trash3-fill"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="row">
                    <Form.Label className="mb-0 standard-size">
                      Dodaj E-mail
                    </Form.Label>
                    <div className="col-9">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          ref={emailField}
                          onChange={(cur) => {
                            setMetaValue(cur.target.value);
                          }}
                        />
                      </InputGroup>
                    </div>
                    <div className="col-3">
                      <button
                        className="icon-24"
                        title="Dodaj E-mail"
                        onClick={() => {
                          saveMeta("email");
                          emailField.current.value = "";
                        }}
                      >
                        <i className="bi bi-plus-circle-fill"></i>
                      </button>
                    </div>
                    <div className="col-12">
                      <Table>
                        <tbody>
                          {authorized &&
                            !loading &&
                            email &&
                            email.map((emailItem, i) => (
                              <tr
                                className="table-hover"
                                key={emailItem.meta_id}
                              >
                                <td
                                  valign="middle"
                                  className="standard-size cursor-arrow"
                                >
                                  {emailItem.meta_value}
                                </td>
                                <td valign="middle" align={"right"} width="15%">
                                  <button
                                    className="icon-16 grey-red ms-2"
                                    title="Obriši E-mail"
                                    onClick={() => {
                                      deleteMeta(emailItem.meta_id, "email");
                                    }}
                                  >
                                    <i className="bi bi-trash3-fill"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="row">
                    <Form.Label className="mb-0 standard-size">
                      Dodaj Web
                    </Form.Label>
                    <div className="col-9">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          ref={webField}
                          onChange={(cur) => {
                            setMetaValue(cur.target.value);
                          }}
                        />
                      </InputGroup>
                    </div>
                    <div className="col-3">
                      <button
                        className="icon-24"
                        title="Dodaj Web"
                        onClick={() => {
                          saveMeta("web");
                          webField.current.value = "";
                        }}
                      >
                        <i className="bi bi-plus-circle-fill"></i>
                      </button>
                    </div>
                    <div className="col-12">
                      <Table>
                        <tbody>
                          {authorized &&
                            !loading &&
                            web &&
                            web.map((webItem, i) => (
                              <tr className="table-hover" key={webItem.meta_id}>
                                <td
                                  valign="middle"
                                  className="standard-size cursor-arrow"
                                >
                                  {webItem.meta_value}
                                </td>
                                <td valign="middle" align={"right"} width="15%">
                                  <button
                                    className="icon-16 grey-red ms-2"
                                    title="Obriši Web"
                                    onClick={() => {
                                      deleteMeta(webItem.meta_id, "web");
                                    }}
                                  >
                                    <i className="bi bi-trash3-fill"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setSelectedCity([]);
                  setEditCity(false);
                }}
              >
                Zatvori
              </Button>
              <Button variant="primary" onClick={handleSaveCity}>
                Sačuvaj
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={addModule}
            onHide={() => {
              setSelectedModule([]);
              setAddModule(false);
            }}
            backdrop="static"
            keyboard={false}
            centered
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>Dodaj modul</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Label className="mb-0 standard-size">
                Naziv modula
              </Form.Label>
              <InputGroup className="mb-2">
                <Form.Control
                  className="form-control form-control-sm standard-size"
                  type="text"
                  defaultValue=""
                  onChange={(cur) => {
                    let newData = { ...selectedModule };
                    newData.module_id = "0";
                    newData.module_name = cur.target.value;
                    setSelectedModule(newData);
                  }}
                />
              </InputGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setSelectedModule([]);
                  setAddModule(false);
                }}
              >
                Zatvori
              </Button>
              <Button variant="primary" onClick={handleSaveModule}>
                Sačuvaj
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={addCity}
            onHide={() => {
              setSelectedCity([]);
              setAddCity(false);
            }}
            backdrop="static"
            keyboard={false}
            centered
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>Dodaj grad</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-12">
                  <Form.Label className="mb-1 standard-size flex">
                    <span className="margin-2">Koristi ćirilično pismo</span>
                    <button
                      className={
                        selectedCity.use_cp === "1"
                          ? "icon-16 green ms-2"
                          : "icon-16 red ms-2"
                      }
                      title={
                        selectedCity.use_cp === "1" ? "Uključeno" : "Isključeno"
                      }
                      onClick={() => {
                        let newData = { ...selectedCity };
                        if (selectedCity.use_cp === "1") {
                          newData.use_cp = "0";
                        } else {
                          newData.use_cp = "1";
                        }
                        newData.city_id = "0";
                        setSelectedCity(newData);
                      }}
                    >
                      {selectedCity.use_cp === "1" ? (
                        <i className="bi bi-toggle-on"></i>
                      ) : (
                        <i className="bi bi-toggle-off"></i>
                      )}
                    </button>
                  </Form.Label>
                  <Form.Label className="mb-1 standard-size">
                    Naziv grada
                  </Form.Label>
                  <div className="row">
                    <div className="col-1 pt-1">SR</div>
                    <div className="col-11">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue=""
                          onChange={(cur) => {
                            let newData = { ...selectedCity };
                            newData.name_sr = cur.target.value;
                            newData.city_id = "0";
                            setSelectedCity(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  {selectedCity.use_cp === "1" && (
                    <div className="row">
                      <div className="col-1 pt-1">CP</div>
                      <div className="col-11">
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            defaultValue=""
                            onChange={(cur) => {
                              let newData = { ...selectedCity };
                              newData.name_cp = cur.target.value;
                              newData.city_id = "0";
                              setSelectedCity(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-1 pt-1">EN</div>
                    <div className="col-11">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue=""
                          onChange={(cur) => {
                            let newData = { ...selectedCity };
                            newData.name_en = cur.target.value;
                            newData.city_id = "0";
                            setSelectedCity(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <Form.Label className="mb-1 standard-size">
                    Naziv aplikacije
                  </Form.Label>
                  <div className="row">
                    <div className="col-1 pt-1">SR</div>
                    <div className="col-11">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue=""
                          onChange={(cur) => {
                            let newData = { ...selectedCity };
                            newData.app_name_sr = cur.target.value;
                            newData.city_id = "0";
                            setSelectedCity(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  {selectedCity.use_cp === "1" && (
                    <div className="row">
                      <div className="col-1 pt-1">CP</div>
                      <div className="col-11">
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            defaultValue=""
                            onChange={(cur) => {
                              let newData = { ...selectedCity };
                              newData.app_name_cp = cur.target.value;
                              newData.city_id = "0";
                              setSelectedCity(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-1 pt-1">EN</div>
                    <div className="col-11">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue=""
                          onChange={(cur) => {
                            let newData = { ...selectedCity };
                            newData.app_name_en = cur.target.value;
                            newData.city_id = "0";
                            setSelectedCity(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <Form.Label className="mb-1 standard-size">
                    Opis aplikacije
                  </Form.Label>
                  <div className="row">
                    <div className="col-1 pt-1">SR</div>
                    <div className="col-11">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue=""
                          onChange={(cur) => {
                            let newData = { ...selectedCity };
                            newData.description_sr = cur.target.value;
                            newData.city_id = "0";
                            setSelectedCity(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  {selectedCity.use_cp === "1" && (
                    <div className="row">
                      <div className="col-1 pt-1">CP</div>
                      <div className="col-11">
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            defaultValue=""
                            onChange={(cur) => {
                              let newData = { ...selectedCity };
                              newData.description_cp = cur.target.value;
                              newData.city_id = "0";
                              setSelectedCity(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-1 pt-1">EN</div>
                    <div className="col-11">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue=""
                          onChange={(cur) => {
                            let newData = { ...selectedCity };
                            newData.description_en = cur.target.value;
                            newData.city_id = "0";
                            setSelectedCity(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <Form.Label className="mb-1 standard-size">
                    Izdavač aplikacije
                  </Form.Label>
                  <div className="row">
                    <div className="col-1 pt-1">SR</div>
                    <div className="col-11">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue=""
                          onChange={(cur) => {
                            let newData = { ...selectedCity };
                            newData.publisher_sr = cur.target.value;
                            newData.city_id = "0";
                            setSelectedCity(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  {selectedCity.use_cp === "1" && (
                    <div className="row">
                      <div className="col-1 pt-1">CP</div>
                      <div className="col-11">
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            defaultValue=""
                            onChange={(cur) => {
                              let newData = { ...selectedCity };
                              newData.publisher_cp = cur.target.value;
                              newData.city_id = "0";
                              setSelectedCity(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-1 pt-1">EN</div>
                    <div className="col-11">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue=""
                          onChange={(cur) => {
                            let newData = { ...selectedCity };
                            newData.publisher_en = cur.target.value;
                            newData.city_id = "0";
                            setSelectedCity(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <Form.Label className="mb-0 standard-size">
                        Geografska širina
                      </Form.Label>
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue=""
                          onChange={(cur) => {
                            let newData = { ...selectedCity };
                            newData.lat = cur.target.value;
                            newData.city_id = "0";
                            setSelectedCity(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                    <div className="col-6">
                      <Form.Label className="mb-0 standard-size">
                        Geografska dužina
                      </Form.Label>
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue=""
                          onChange={(cur) => {
                            let newData = { ...selectedCity };
                            newData.lng = cur.target.value;
                            newData.city_id = "0";
                            setSelectedCity(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <Form.Label className="mb-0 standard-size">Url</Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      className="form-control form-control-sm standard-size"
                      type="text"
                      defaultValue=""
                      onChange={(cur) => {
                        let newData = { ...selectedCity };
                        newData.url = cur.target.value;
                        newData.city_id = "0";
                        setSelectedCity(newData);
                      }}
                    />
                  </InputGroup>
                  <Form.Label className="mb-0 standard-size">
                    Putanja na serveru
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      className="form-control form-control-sm standard-size"
                      type="text"
                      defaultValue=""
                      onChange={(cur) => {
                        let newData = { ...selectedCity };
                        newData.path = cur.target.value;
                        newData.city_id = "0";
                        setSelectedCity(newData);
                      }}
                    />
                  </InputGroup>
                  <Form.Label className="mb-0 standard-size">
                    Android app link
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      className="form-control form-control-sm standard-size"
                      type="text"
                      defaultValue=""
                      onChange={(cur) => {
                        let newData = { ...selectedCity };
                        newData.android_app = cur.target.value;
                        newData.city_id = "0";
                        setSelectedCity(newData);
                      }}
                    />
                  </InputGroup>
                  <Form.Label className="mb-0 standard-size">
                    iOS app link
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      className="form-control form-control-sm standard-size"
                      type="text"
                      defaultValue=""
                      onChange={(cur) => {
                        let newData = { ...selectedCity };
                        newData.ios_app = cur.target.value;
                        newData.city_id = "0";
                        setSelectedCity(newData);
                      }}
                    />
                  </InputGroup>
                  <Form.Label className="mb-0 standard-size">
                    Facebook link
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      className="form-control form-control-sm standard-size"
                      type="text"
                      defaultValue=""
                      onChange={(cur) => {
                        let newData = { ...selectedCity };
                        newData.facebook_page = cur.target.value;
                        newData.city_id = "0";
                        setSelectedCity(newData);
                      }}
                    />
                  </InputGroup>
                  <Form.Label className="mb-0 standard-size">
                    Kratki link
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      className="form-control form-control-sm standard-size"
                      type="text"
                      defaultValue=""
                      onChange={(cur) => {
                        let newData = { ...selectedCity };
                        newData.shortlink = cur.target.value;
                        newData.city_id = "0";
                        setSelectedCity(newData);
                      }}
                    />
                  </InputGroup>
                  <Form.Label className="mb-0 standard-size">Alias</Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      className="form-control form-control-sm standard-size"
                      type="text"
                      defaultValue=""
                      onChange={(cur) => {
                        let newData = { ...selectedCity };
                        newData.alias = cur.target.value;
                        newData.city_id = "0";
                        setSelectedCity(newData);
                      }}
                    />
                  </InputGroup>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setSelectedCity([]);
                  setAddCity(false);
                }}
              >
                Zatvori
              </Button>
              <Button variant="primary" onClick={handleSaveCity}>
                Sačuvaj
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      <ErrorModal show={error} message={errorMessage}></ErrorModal>
    </>
  );
}
