import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

export default function DeleteModal(props) {
  const { show, title, message, name, action, close } = props;

  return (
    <>
      <Modal
        show={show}
        onHide={close}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row-md">
            <div className="col-md mb-2">
              {message} <strong>{name}?</strong>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Zatvori
          </Button>
          <Button variant="danger" onClick={action}>
            Obriši
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
