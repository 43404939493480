import React, { useEffect, useState } from "react";
import { Container, Table, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ErrorModal from "../components/errorModal";
import Loader from "../components/loader";

export default function Comments() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteComment, setDeleteComment] = useState("");
  const [deleteCommentTitle, setDeleteCommentTitle] = useState("");
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const moduleId = "61751849c3c200015af75fb612fd7f51";
  const [init, setInit] = useState(true);

  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
        //   offset: offset,
        //   count: count,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_comments.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(receivedData);
          setTimeout(() => {
            setLoading(false);
            setAuthorized(true);
            setInit(false);
          }, 200);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  }, []);

  useEffect(() => {
    if (!init) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          city_id: global.getCity(),
          session_id: localStorage.getItem("authToken"),
          module: moduleId,
          //   offset: offset,
          //   count: count,
        }),
      };
      fetch(
        process.env.REACT_APP_BACKEND_ROOT + "_private/_get_comments.php",
        requestOptions
      )
        .then((response) => response.json())
        .then((receivedData) => {
          if (!receivedData.response) {
            setData(receivedData);
          } else {
            setErrorMessage(receivedData.response);
            setError(true);
          }
        });
    }
  }, [global.pendingComments()]);

  const convertDate = (date) => {
    let options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    let dateTime = new Date(date * 1000);
    return dateTime.toLocaleDateString("sr", options);
  };

  const aproveComment = (id, i) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: id,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_update_comment.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(receivedData);
          global.triggerVerify();
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const handleDeleteComment = (id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: id,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_delete_comment.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          let combined = Array();
          let events = data.events.filter((item) => item.id !== id);
          let location = data.location.filter((item) => item.id !== id);
          let news = data.news.filter((item) => item.id !== id);
          combined.events = events;
          combined.location = location;
          combined.news = news;
          setData(combined);
          setDeleteComment("");
          setDeleteCommentTitle("");
          setDeleteModal(false);
          global.triggerVerify();
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  return (
    <>
      <Container>
        <div className="row">
          <div className="col-md mt-4 mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                <div className="row">
                  <div className="col-md-12 m-0">
                    <h3 className="mb-0">Komentari</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body p-4">
                <h5 className="mb-3">Lokacije</h5>
                <Table>
                  <thead>
                    <tr>
                      <th>Komentar</th>
                      <th className="text-right">Funkcije</th>
                    </tr>
                  </thead>
                  <tbody>
                    {authorized &&
                      !loading &&
                      data &&
                      data.location.map((comment, i) => (
                        <tr className="table-hover" key={comment.id}>
                          <td
                            valign="middle"
                            className="standard-size cursor-arrow"
                          >
                            <div>{comment.name_sr}</div>

                            <div className="comment-text">
                              <span>{comment.comment_name}</span>
                              <span className="ms-2 small-size">
                                <i className="bi bi-calendar-date brand"></i>{" "}
                                {convertDate(comment.comment_date)}
                              </span>
                              <div className="pt-1">{comment.comment_text}</div>
                            </div>
                          </td>
                          <td
                            valign="middle"
                            align={"right"}
                            width="15%"
                            style={{
                              background:
                                comment.admin_aproved === "0"
                                  ? "rgb(247 228 228)"
                                  : null,
                            }}
                          >
                            {comment.admin_aproved === "1" ? (
                              ""
                            ) : (
                              <button
                                className="icon-16 grey-blue ms-2"
                                title="Odobri komentar"
                                onClick={() => {
                                  aproveComment(comment.id, i);
                                }}
                              >
                                <i className="bi bi-check-circle-fill"></i>
                              </button>
                            )}

                            <button
                              className="icon-16 grey-red ms-2"
                              title="Obriši komentar"
                              onClick={() => {
                                setDeleteComment(comment.id);
                                setDeleteCommentTitle(comment.comment_name);
                                setDeleteModal(true);
                              }}
                            >
                              <i className="bi bi-trash3-fill"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {!loading && data.location?.length === 0 && (
                  <div className="no-results">
                    Kategorija ne sadrži ni jedan komentar.
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body p-4">
                <h5 className="mb-3">Vesti</h5>
                <Table>
                  <thead>
                    <tr>
                      <th>Komentar</th>
                      <th className="text-right">Funkcije</th>
                    </tr>
                  </thead>
                  <tbody>
                    {authorized &&
                      !loading &&
                      data &&
                      data.news.map((comment, i) => (
                        <tr className="table-hover" key={comment.id}>
                          <td
                            valign="middle"
                            className="standard-size cursor-arrow"
                          >
                            <div>{comment.title_sr}</div>

                            <div className="comment-text">
                              <span>{comment.comment_name}</span>
                              <span className="ms-2 small-size">
                                <i className="bi bi-calendar-date brand"></i>{" "}
                                {convertDate(comment.comment_date)}
                              </span>
                              <div className="pt-1">{comment.comment_text}</div>
                            </div>
                          </td>
                          <td
                            valign="middle"
                            align={"right"}
                            width="15%"
                            style={{
                              background:
                                comment.admin_aproved === "0"
                                  ? "rgb(247 228 228)"
                                  : null,
                            }}
                          >
                            {comment.admin_aproved === "1" ? (
                              ""
                            ) : (
                              <button
                                className="icon-16 grey-blue ms-2"
                                title="Odobri komentar"
                                onClick={() => {
                                  aproveComment(comment.id, i);
                                }}
                              >
                                <i className="bi bi-check-circle-fill"></i>
                              </button>
                            )}

                            <button
                              className="icon-16 grey-red ms-2"
                              title="Obriši komentar"
                              onClick={() => {
                                setDeleteComment(comment.id);
                                setDeleteCommentTitle(comment.comment_name);
                                setDeleteModal(true);
                              }}
                            >
                              <i className="bi bi-trash3-fill"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {!loading && data.news?.length === 0 && (
                  <div className="no-results">
                    Kategorija ne sadrži ni jedan komentar.
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body p-4">
                <h5 className="mb-3">Najave</h5>
                <Table>
                  <thead>
                    <tr>
                      <th>Komentar</th>
                      <th className="text-right">Funkcije</th>
                    </tr>
                  </thead>
                  <tbody>
                    {authorized &&
                      !loading &&
                      data &&
                      data.events.map((comment, i) => (
                        <tr className="table-hover" key={comment.id}>
                          <td
                            valign="middle"
                            className="standard-size cursor-arrow"
                          >
                            <div>{comment.title_sr}</div>

                            <div className="comment-text">
                              <span>{comment.comment_name}</span>
                              <span className="ms-2 small-size">
                                <i className="bi bi-calendar-date brand"></i>{" "}
                                {convertDate(comment.comment_date)}
                              </span>
                              <div className="pt-1">{comment.comment_text}</div>
                            </div>
                          </td>
                          <td
                            valign="middle"
                            align={"right"}
                            width="15%"
                            style={{
                              background:
                                comment.admin_aproved === "0"
                                  ? "rgb(247 228 228)"
                                  : null,
                            }}
                          >
                            {comment.admin_aproved === "1" ? (
                              ""
                            ) : (
                              <button
                                className="icon-16 grey-blue ms-2"
                                title="Odobri komentar"
                                onClick={() => {
                                  aproveComment(comment.id, i);
                                }}
                              >
                                <i className="bi bi-check-circle-fill"></i>
                              </button>
                            )}

                            <button
                              className="icon-16 grey-red ms-2"
                              title="Obriši komentar"
                              onClick={() => {
                                setDeleteComment(comment.id);
                                setDeleteCommentTitle(comment.comment_name);
                                setDeleteModal(true);
                              }}
                            >
                              <i className="bi bi-trash3-fill"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {!loading && data.event?.length === 0 && (
                  <div className="no-results">
                    Kategorija ne sadrži ni jedan komentar.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Loader loading={loading} />

      {!loading && (
        <Modal
          show={deleteModal}
          onHide={() => {
            setDeleteModal(false);
            setDeleteComment("");
            setDeleteCommentTitle("");
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="md"
        >
          <Modal.Header closeButton>
            <Modal.Title>Brisanje komentara</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row-md">
              <div className="col-md mb-2">
                Obrisati komentar korisnika{" "}
                <strong>{deleteCommentTitle}?</strong>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setDeleteModal(false);
                setDeleteComment("");
                setDeleteCommentTitle("");
              }}
            >
              Zatvori
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                handleDeleteComment(deleteComment);
              }}
            >
              Obriši
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <ErrorModal show={error} message={errorMessage}></ErrorModal>
    </>
  );
}
