import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Form, InputGroup, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import md5 from "md5";

export default function Verify() {
  const { verify_id } = useParams();
  const [needPassword, setNeedPassword] = useState(false);
  const [userId, setUserId] = useState("");
  const [errorText, setErrorText] = useState("");
  const [response, setResponse] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordVerified, setPasswordVerified] = useState(false);
  const passwordRef = useRef();
  const repeatPasswordRef = useRef();
  const [passwordError, setPasswordError] = useState("");
  const [savingData, setSavingData] = useState(false);

  useEffect(() => {
    global.logout();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        verify_id: verify_id,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_verify_email.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (receivedData.response === "success") {
          if (receivedData.need_password === true) {
            setNeedPassword(true);
            setResponse(receivedData.response);
            setUserId(receivedData.user_id);
          } else {
            setResponse(receivedData.response);
          }
        }
        if (receivedData.response === "error") {
          setResponse(receivedData.response);
          setErrorText(
            receivedData.response_text + " Kontaktirajte administratora."
          );
        }
      });
  }, []);

  const verifyPassword = () => {
    setPasswordError("");
    setPasswordVerified(true);
    if (passwordRef.current.value !== repeatPasswordRef.current.value) {
      setPasswordError("Lozinke moraju biti identične!");
      setPasswordVerified(false);
    }
    if (
      passwordRef.current.value.length < 8 ||
      repeatPasswordRef.current.value.length < 8
    ) {
      setPasswordError("Lozinke moraju sadržati najmanje 8 znakova!");
      setPasswordVerified(false);
    }
  };

  const handleSavePassword = () => {
    setSavingData(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        user_id: userId,
        password: md5(password),
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_set_user_password.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        setTimeout(() => {
          if (responseData.response === "success") {
            setNeedPassword(false);
            setResponse(responseData.response);
          } else {
            setResponse(responseData.response);
            setErrorText(responseData.response_text);
          }
          setSavingData(false);
        }, 500);
      });
  };

  return (
    <div className="login-window">
      <div className="card card-small p-5">
        <div className="row pb-5">
          <div className="col-md">
            <img
              style={{
                transition: "all 500ms",
                margin: "auto",
                display: "block",
              }}
              width="30%"
              src={process.env.REACT_APP_RESOURCES + "belgrade/static/logo.png"}
              alt="Inndex Dashboard"
            />
          </div>
        </div>
        {response === "success" && (
          <div className="row">
            <div className="col-md standard-size text-center">
              <div>Vaša e-mail adresa je uspešno verifikovana.</div>
              {needPassword ? (
                <>
                  <div>Podesite lozinku za pristup vašem nalogu.</div>
                  <div className="mt-2">
                    <i>
                      <strong>NAPOMENA: </strong>Ukoliko preskočite ovaj korak,
                      nećete biti u mogućnosti da ga izvršite naknadno.
                    </i>
                  </div>
                </>
              ) : null}
              {needPassword ? (
                <>
                  <div className="row mt-3">
                    <div className="col-md">
                      <Form.Label className="mb-0 standard-size">
                        Lozinka
                      </Form.Label>
                      <InputGroup className="mb-2">
                        <Form.Control
                          ref={passwordRef}
                          className="form-control form-control-sm standard-size text-center"
                          type="password"
                          onChange={(cur) => {
                            setPassword(cur.target.value);
                            verifyPassword();
                          }}
                        />
                      </InputGroup>
                      <Form.Label className="mb-0 standard-size">
                        Ponovi lozinku
                      </Form.Label>
                      <InputGroup className="mb-2">
                        <Form.Control
                          ref={repeatPasswordRef}
                          className="form-control form-control-sm standard-size text-center"
                          type="password"
                          onChange={(cur) => {
                            setRepeatPassword(cur.target.value);
                            verifyPassword();
                          }}
                        />
                      </InputGroup>
                      <div className="red text-center">{passwordError}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md mt-2">
                      <Button
                        disabled={!passwordVerified}
                        className="btn-primary"
                        onClick={() => {
                          handleSavePassword();
                        }}
                      >
                        Sačuvaj
                        {savingData ? (
                          <i className="ms-1 fa fa-spinner fa-spin"></i>
                        ) : null}
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="row mt-4">
                  <div className="col-md mt-2">
                    <Link to={"/"}>
                      <Button className="btn-primary">Naslovna strana</Button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {response === "error" && (
          <div className="row">
            <div className="col-md standard-size text-center red">
              <div>{errorText}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
