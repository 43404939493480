import React, { useEffect, useState } from "react";
import { Container, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import PreviewModal from "../components/newsAndEvents/previewModal";
import ErrorModal from "../components/errorModal";
import Loader from "../components/loader";

export default function CollectGarbage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newsModal, setNewsModal] = useState(false);
  const [loadingNews, setLoadingNews] = useState(true);
  const [preview, setPreview] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteComment, setDeleteComment] = useState("");
  const [deleteCommentTitle, setDeleteCommentTitle] = useState("");
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const moduleId = "e4b58a0c4c350ec8bb52751a4b0fcb20";
  const [averageViews, setAverageViews] = useState(0);
  const [averageComments, setAverageComments] = useState(0);
  const [averageLikes, setAverageLikes] = useState(0);
  const [init, setInit] = useState(true);

  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
        //   offset: offset,
        //   count: count,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_collect_garbage.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(receivedData);
          setTimeout(() => {
            setLoading(false);
            setAuthorized(true);
            setInit(false);
          }, 200);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  }, []);

  const handleDeleteAll = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
        list: data,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_delete_garbage.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(receivedData.data);
          setDeleteModal(false);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  return (
    <>
      <Container>
        <div className="row">
          <div className="col-md mt-4 mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                <div className="row">
                  <div className="col-md-8 m-0">
                    <h3 className="mb-0">Otpad</h3>
                  </div>
                  <div className="col-md-4 m-0 text-right">
                    <button
                      disabled={
                        data.comments?.length < 1 &&
                        data.contacts?.length < 1 &&
                        data.likes?.length < 1 &&
                        data.photos?.length < 1 &&
                        data.rating?.length < 1
                          ? true
                          : false
                      }
                      className="icon-24 grey-red ms-2"
                      title="Isprazni otpad"
                      onClick={() => {
                        setDeleteModal(true);
                      }}
                    >
                      <i className="bi bi-trash3-fill"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body p-4">
                <h5 className="mb-3">Fotografije</h5>
                <div className="row">
                  {authorized &&
                    !loading &&
                    data &&
                    data.photos.map((item, i) => (
                      <div className="col-md-2 mb-4">
                        <div className="photo-frame">
                          <img
                            width="100%"
                            src={
                              process.env.REACT_APP_RESOURCES +
                              item.path +
                              "small/" +
                              item.filename
                            }
                            alt={item.text_sr}
                          />
                        </div>
                      </div>
                    ))}
                </div>
                {!loading && data.photos?.length === 0 && (
                  <div className="no-results">Nema otpada u kategoriji.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Loader loading={loading} />

      {!loading && (
        <Modal
          show={deleteModal}
          onHide={() => {
            setDeleteModal(false);
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="md"
        >
          <Modal.Header closeButton>
            <Modal.Title>Isprazni otpad</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row-md">
              <div className="col-md mb-2">
                Da li ste sigurni da želite da ispraznite otpad?
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              Zatvori
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                handleDeleteAll();
              }}
            >
              Isprazni
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <ErrorModal show={error} message={errorMessage}></ErrorModal>
    </>
  );
}
