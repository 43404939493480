import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

export default function ErrorModal(props) {
  const { show, message } = props;

  return (
    <>
      <Modal show={show} backdrop="static" keyboard={false} centered size="md">
        <Modal.Header>
          <Modal.Title>Greška!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row-md">
            <div className="col-md mb-2">{message}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link to={"/"}>
            <Button variant="primary">Naslovna strana</Button>
          </Link>
          <Button
            onClick={() => {
              global.logout();
            }}
            variant="danger"
          >
            Odjava
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
