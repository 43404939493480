import React, { useEffect, useState } from "react";
import { Container, Table, Button, Form, InputGroup } from "react-bootstrap";
import { Line, Pie } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import ErrorModal from "../components/errorModal";
import Loader from "../components/loader";

export default function Active(props) {
  const { moduleId } = props;
  const [locationsData, setLocationsData] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_active_data.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setLocationsData({
            labels: receivedData.locations.map((item) => item.label),
            datasets: [
              {
                label: "Lokacije",
                data: receivedData.locations.map((item) => item.value),
              },
            ],
          });
          setNewsData({
            labels: receivedData.news.map((item) => item.label),
            datasets: [
              {
                label: "Vesti",
                data: receivedData.news.map((item) => item.value),
              },
            ],
          });
          setEventsData({
            labels: receivedData.events.map((item) => item.label),
            datasets: [
              {
                label: "Najave",
                data: receivedData.events.map((item) => item.value),
              },
            ],
          });
          setTimeout(() => {
            setLoading(false);
          }, 200);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  }, [global.getCity(), global.getCount()]);

  return (
    <>
      {!loading && (
        <div className="row">
          <div className="col-md-4">
            {!loading && locationsData && <Pie data={locationsData} />}
            <h6 className="text-center mt-3">Lokacije</h6>
          </div>
          <div className="col-md-4">
            {!loading && newsData && <Pie data={newsData} />}
            <h6 className="text-center mt-3">Vesti</h6>
          </div>
          <div className="col-md-4">
            {!loading && eventsData && <Pie data={eventsData} />}
            <h6 className="text-center mt-3">Najave</h6>
          </div>
        </div>
      )}

      <Loader loading={loading} />
      <ErrorModal show={error} message={errorMessage}></ErrorModal>
    </>
  );
}
