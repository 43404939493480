import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  TelegramIcon,
  TwitterIcon,
  ViberIcon,
  WhatsappIcon,
} from "react-share";

function ShareModal(props) {
  const { show, onCloseShare, body, subject, media, url } = props;
  const [copied, setCopied] = useState(false);

  // useEffect(() => {
  //   console.log(subject);
  //   console.log(body);
  //   console.log(media);
  //   console.log(url);
  // }, [show]);

  return (
    <Modal
      show={show}
      onHide={onCloseShare}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Podeli</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="share-link-wrap mb-3">
          <a href={url} target="_blank" rel="noreferrer" title={url}>
            {url}
          </a>

          <div>
            <CopyToClipboard
              text={url}
              onCopy={() => {
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 2000);
              }}
            >
              <button className="icon-16 grey-blue ms-2" title="Kopiraj vezu">
                {!copied ? (
                  <i className="bi bi-copy"></i>
                ) : (
                  <i className="bi bi-check2"></i>
                )}
              </button>
            </CopyToClipboard>
          </div>
        </div>
        <div className="share-modal-body">
          <FacebookShareButton quote={body} url={url}>
            <FacebookIcon size={50} borderRadius={10} />
          </FacebookShareButton>
          <TwitterShareButton url={url} title={subject}>
            <TwitterIcon size={50} borderRadius={10} />
          </TwitterShareButton>
          <ViberShareButton url={url} title={subject}>
            <ViberIcon size={50} borderRadius={10} />
          </ViberShareButton>
          <WhatsappShareButton url={url} title={subject}>
            <WhatsappIcon size={50} borderRadius={10} />
          </WhatsappShareButton>
          <LinkedinShareButton url={url} title={subject} summary={body}>
            <LinkedinIcon size={50} borderRadius={10} />
          </LinkedinShareButton>
          <PinterestShareButton url={url} media={media} description={body}>
            <PinterestIcon size={50} borderRadius={10} />
          </PinterestShareButton>
          <TelegramShareButton url={url} title={subject}>
            <TelegramIcon size={50} borderRadius={10} />
          </TelegramShareButton>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCloseShare}>
          Zatvori
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ShareModal;
