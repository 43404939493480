import React, { useEffect, useState } from "react";
import { Container, Table, Button, Form, InputGroup } from "react-bootstrap";
import ErrorModal from "../components/errorModal";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import sr from "date-fns/locale/sr-Latn";
import { QRCodeCanvas } from "qrcode.react";
import Loader from "../components/loader";
registerLocale("sr", sr);

export default function QRCode() {
  const moduleId = "85b991ae924d726b0a109024f364c06e";
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_general_info.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(receivedData);
          setTimeout(() => {
            setLoading(false);
          }, 200);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  }, [global.getCity()]);

  function download(id) {
    var canvas = document.getElementById(id);
    var url = canvas.toDataURL("image/png");
    var link = document.createElement("a");
    link.download = id + "-2048X2048.png";
    link.href = url;
    link.click();
  }

  return (
    <>
      <Container>
        <div className="row">
          {!loading && data.shortlink !== "" && (
            <div className="col-md-6 mt-4">
              <div className="h-100 card card-small">
                <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                  <h5 className="mb-3">Multilink QR kod</h5>
                  <div className="row border-bottom pb-2 mb-1">
                    <div
                      className="col-md-8"
                      style={{
                        alignContent: "center",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      Skeniranjem ovog koda, korisnici Android mobilnih uređaja
                      biće preusmereni na stranicu za preuzimanje Android
                      aplikacije u okviru Google Play prodavnice, korisnici
                      iPhone i iPad uređaja biće preusmereni na stranicu za
                      preuzimanje vaše iOS aplikacije u okviru App Store
                      prodavnice, a korisnici ostalih uređaja biće preusmereni
                      na adresu vašeg web portala.
                    </div>
                    <div className="col-md-4">
                      <QRCodeCanvas
                        id="multilink"
                        value={"https://qr.inndex.rs/" + data.shortlink}
                        size="2048"
                        bgColor="transparent"
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md text-right">
                      <Button
                        className="mt-2"
                        variant="primary"
                        onClick={() => {
                          download("multilink");
                        }}
                      >
                        Preuzmi
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!loading && data.url !== "" && (
            <div className="col-md-6 mt-4">
              <div className="h-100 card card-small">
                <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                  <h5 className="mb-3">Website QR kod</h5>
                  <div className="row border-bottom pb-2 mb-1">
                    <div
                      className="col-md-8"
                      style={{
                        alignContent: "center",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      Skeniranjem ovog koda, korisnici će biti preusmereni na
                      adresu vašeg web portala.
                    </div>
                    <div className="col-md-4">
                      <QRCodeCanvas
                        id="website"
                        value={data.url}
                        size="2048"
                        bgColor="transparent"
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md text-right">
                      <Button
                        className="mt-2"
                        variant="primary"
                        onClick={() => {
                          download("website");
                        }}
                      >
                        Preuzmi
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!loading && data.android_app !== "" && (
            <div className="col-md-6 mt-4">
              <div className="h-100 card card-small">
                <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                  <h5 className="mb-3">Android app QR kod</h5>
                  <div className="row border-bottom pb-2 mb-1">
                    <div
                      className="col-md-8"
                      style={{
                        alignContent: "center",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      Skeniranjem ovog koda, korisnici će biti preusmereni na
                      stranicu za preuzimanje vaše Android aplikacije u okviru
                      Google Play prodavnice.
                    </div>
                    <div className="col-md-4">
                      <QRCodeCanvas
                        id="android"
                        value={data.android_app}
                        size="2048"
                        bgColor="transparent"
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md text-right">
                      <Button
                        className="mt-2"
                        variant="primary"
                        onClick={() => {
                          download("android");
                        }}
                      >
                        Preuzmi
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!loading && data.ios_app !== "" && (
            <div className="col-md-6 mt-4">
              <div className="h-100 card card-small">
                <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                  <h5 className="mb-3">iOS app QR kod</h5>
                  <div className="row border-bottom pb-2 mb-1">
                    <div
                      className="col-md-8"
                      style={{
                        alignContent: "center",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      Skeniranjem ovog koda, korisnici će biti preusmereni na
                      stranicu za preuzimanje vaše iOS aplikacije u okviru App
                      Store prodavnice.
                    </div>
                    <div className="col-md-4">
                      <QRCodeCanvas
                        id="ios"
                        value={data.ios_app}
                        size="2048"
                        bgColor="transparent"
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md text-right">
                      <Button
                        className="mt-2"
                        variant="primary"
                        onClick={() => {
                          download("ios");
                        }}
                      >
                        Preuzmi
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
      <Loader loading={loading} />
      <ErrorModal show={error} message={errorMessage}></ErrorModal>
    </>
  );
}
