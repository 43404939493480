import React, { useEffect, useState } from "react";
import { Container, Button, Form, InputGroup } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ErrorModal from "../components/errorModal";
import InfoModal from "../components/infoModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Loader from "../components/loader";

export default function GroupsAndSubgroups() {
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteType, setDeleteType] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const moduleId = "947018640bf36a2bb609d3557a285329";
  const [groups, setGroups] = useState([]);
  const [subgroups, setSubgroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();
  const [infoModal, setInfoModal] = useState(false);
  const [addGroupModal, setAddGroupModal] = useState(false);
  const [editedGroup, setEditedGroup] = useState([]);
  const [ui, setUi] = useState([]);
  const [editGroupModal, setEditGroupModal] = useState(false);
  const [addSubgroupModal, setAddSubgroupModal] = useState(false);
  const [editedSubgroup, setEditedSubgroup] = useState([]);
  const [editSubgroupModal, setEditSubgroupModal] = useState(false);
  const [groupSortingChanged, setGroupSortingChanged] = useState(false);
  const [savingGroupSorting, setSavingGroupSorting] = useState(false);
  const [subgroupSortingChanged, setSubgroupSortingChanged] = useState(false);
  const [savingSubgroupSorting, setSavingSubgroupSorting] = useState(false);

  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
        //   offset: offset,
        //   count: count,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_groups_subgroups.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setGroups(receivedData.groups);
          setSubgroups(receivedData.subgroups);
          receivedData.groups &&
            setSelectedGroup(receivedData.groups[0].group_id);
          setTimeout(() => {
            setLoading(false);
            setAuthorized(true);
          }, 200);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_ui.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setUi(receivedData);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  }, [global.getCity()]);

  const handleDragEnd = ({ destination, source }) => {
    if (!destination) return;
    let newGroups = reorder(groups, source.index, destination.index);
    newGroups.map((item, index) => {
      item.sort_order = (index + 1).toString();
    });
    setGroups(newGroups);
    setGroupSortingChanged(true);
  };

  const handleDragEndSg = ({ destination, source }) => {
    if (!destination) return;
    let newSubgroups = reorder(subgroups, source.index, destination.index);
    newSubgroups.map((item, index) => {
      item.sort_order = (index + 1).toString();
    });
    setSubgroups(newSubgroups);
    setSubgroupSortingChanged(true);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDelete = (deleteType, deleteId) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        delete_type: deleteType,
        delete_id: deleteId,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT +
        "_private/_delete_group_subgroup.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          if (deleteType === "group") {
            setGroups(groups.filter((group) => group.group_id !== deleteId));
          } else {
            setSubgroups(
              subgroups.filter((subgroup) => subgroup.subgroup_id !== deleteId)
            );
          }
          setDeleteId("");
          setDeleteName("");
          setDeleteType("");
          setDeleteModal(false);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const subgroupEmpty = (subgroupId, subgroupName) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        subgroup_id: subgroupId,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_subgroup_empty.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          if (receivedData.items === "0") {
            setDeleteModal(true);
            setDeleteType("subgroup");
            setDeleteName(subgroupName);
            setDeleteId(subgroupId);
          } else {
            setInfoModal(true);
          }
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const closeInfoModal = () => {
    setInfoModal(false);
  };

  const handleSaveGroup = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        group_id: editedGroup.group_id,
        name_sr: editedGroup.name_sr,
        name_cp: editedGroup.name_cp,
        name_en: editedGroup.name_en,
        text_sr: editedGroup.text_sr,
        text_cp: editedGroup.text_cp,
        text_en: editedGroup.text_en,
        filename: editedGroup.filename,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_edit_single_group.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setGroups(receivedData);
          setEditedGroup([]);
          setAddGroupModal(false);
          setEditGroupModal(false);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const handleActiveGroup = (index, group_id, active) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        group_id: group_id,
        active: active,
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_toggle_group_state.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.response) {
          let newData = groups.map((group, i) => {
            if (i === index) {
              group.active = responseData.active;
              return group;
            } else {
              return group;
            }
          });
          setGroups(newData);
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  const handleSaveSubgroup = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        subgroup_id: editedSubgroup.subgroup_id,
        name_sr: editedSubgroup.name_sr,
        name_cp: editedSubgroup.name_cp,
        name_en: editedSubgroup.name_en,
        group_id: editedSubgroup.group_id,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_edit_single_subgroup.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setSubgroups(receivedData);
          setEditedSubgroup([]);
          setAddSubgroupModal(false);
          setEditSubgroupModal(false);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const handleActiveSubgroup = (index, subgroup_id, active) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        subgroup_id: subgroup_id,
        active: active,
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT +
        "_private/_toggle_subgroup_state.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.response) {
          let newData = subgroups.map((subgroup, i) => {
            if (i === index) {
              subgroup.active = responseData.active;
              return subgroup;
            } else {
              return subgroup;
            }
          });
          setSubgroups(newData);
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  const saveGroupSorting = () => {
    setSavingGroupSorting(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        groups: groups,
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_edit_group_sorting.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.response) {
          setGroups(responseData);
          setTimeout(() => {
            setSavingGroupSorting(false);
          }, 500);
          setTimeout(() => {
            setGroupSortingChanged(false);
          }, 600);
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  const saveSubgroupSorting = () => {
    setSavingSubgroupSorting(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        subgroups: subgroups,
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT +
        "_private/_edit_subgroup_sorting.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.response) {
          setSubgroups(responseData);
          setTimeout(() => {
            setSavingSubgroupSorting(false);
          }, 500);
          setTimeout(() => {
            setSubgroupSortingChanged(false);
          }, 600);
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  return (
    <>
      <Container>
        <div className="row">
          <div className="col-md mt-4 mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                <div className="row">
                  <div className="col-md m-0">
                    <h3 className="mb-0">Grupe i podgrupe</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body p-4">
                <div className="row mb-3">
                  <div className="col-md-8">
                    <h5 className="mb-0">Grupe</h5>
                  </div>
                  <div className="col-md-4 text-right">
                    <button
                      className="icon-16 grey-blue ms-2"
                      title="Dodaj grupu"
                      onClick={() => {
                        setAddGroupModal(true);
                      }}
                    >
                      <i className="bi bi-plus-circle-fill"></i>
                    </button>
                  </div>
                </div>
                {authorized && !loading && groups && (
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {groups &&
                            groups.map((item, index) => (
                              <Draggable
                                key={item.group_id}
                                index={index}
                                draggableId={item.group_id}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    key={item.group_id}
                                    className="item-container"
                                    style={{
                                      ...provided.draggableProps.style,
                                      background: snapshot.isDragging
                                        ? "#dddddd"
                                        : "",
                                    }}
                                  >
                                    <div>{item.name_sr}</div>
                                    <div>
                                      <button
                                        className="icon-16 grey-blue ms-2"
                                        title="Uredi grupu"
                                        onClick={() => {
                                          let newData = [];
                                          newData.group_id = item.group_id;
                                          newData.name_sr = item.name_sr;
                                          newData.name_cp = item.name_cp;
                                          newData.name_en = item.name_en;
                                          newData.text_sr = item.text_sr;
                                          newData.text_cp = item.text_cp;
                                          newData.text_en = item.text_en;
                                          newData.filename = item.filename;
                                          setEditedGroup(newData);
                                          setEditGroupModal(true);
                                        }}
                                      >
                                        <i className="bi bi-pencil-fill"></i>
                                      </button>

                                      <button
                                        className={
                                          item.active === "1"
                                            ? "icon-16 green ms-2"
                                            : "icon-16 red ms-2"
                                        }
                                        title={
                                          item.active === "1"
                                            ? "Aktivna"
                                            : "Nije aktivna"
                                        }
                                        onClick={() =>
                                          handleActiveGroup(
                                            index,
                                            item.group_id,
                                            item.active
                                          )
                                        }
                                      >
                                        {item.active === "1" ? (
                                          <i className="bi bi-toggle-on"></i>
                                        ) : (
                                          <i className="bi bi-toggle-off"></i>
                                        )}
                                      </button>
                                      {subgroups &&
                                      subgroups.some(
                                        (subgroup) =>
                                          subgroup.group_id === item.group_id
                                      ) ? null : (
                                        <button
                                          className="icon-16 grey-red ms-2"
                                          title="Obriši grupu"
                                          onClick={() => {
                                            setDeleteModal(true);
                                            setDeleteType("group");
                                            setDeleteName(item.name_sr);
                                            setDeleteId(item.group_id);
                                          }}
                                        >
                                          <i className="bi bi-trash3-fill"></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
                {authorized && !loading && groupSortingChanged && (
                  <div className="row mt-3">
                    <div className="col-md text-center">
                      <Button
                        title="Sačuvaj promenu redosleda"
                        variant="primary"
                        onClick={saveGroupSorting}
                      >
                        Sačuvaj
                        {savingGroupSorting ? (
                          <i className="ms-1 fa fa-spinner fa-spin"></i>
                        ) : null}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body p-4">
                <div className="row mb-3">
                  <div className="col-md-8">
                    <h5 className="mb-0">Podgrupe</h5>
                  </div>
                  <div className="col-md-4 text-right">
                    <button
                      className="icon-16 grey-blue ms-2"
                      title="Dodaj podgrupu"
                      onClick={() => {
                        setAddSubgroupModal(true);
                      }}
                    >
                      <i className="bi bi-plus-circle-fill"></i>
                    </button>
                  </div>
                </div>
                {authorized && !loading && subgroups && (
                  <>
                    <div className="row mb-3">
                      <div className="col-md">
                        <Form.Label className="mb-0 standard-size">
                          Grupa
                        </Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Select
                            size="sm"
                            className="form-control form-control-sm standard-size"
                            onChange={(cur) => {
                              setSelectedGroup(cur.target.value);
                            }}
                          >
                            {groups &&
                              groups.map((item) => (
                                <option
                                  key={"SELECT" + item.group_id}
                                  value={item.group_id}
                                >
                                  {item.name_sr}
                                </option>
                              ))}
                          </Form.Select>
                        </InputGroup>
                      </div>
                    </div>
                    <DragDropContext onDragEnd={handleDragEndSg}>
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {subgroups &&
                              subgroups.map(
                                (item, index) =>
                                  item.group_id === selectedGroup && (
                                    <Draggable
                                      key={item.subgroup_id}
                                      index={index}
                                      draggableId={item.subgroup_id}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          key={item.subgroup_id}
                                          className="item-container"
                                          style={{
                                            ...provided.draggableProps.style,
                                            background: snapshot.isDragging
                                              ? "#dddddd"
                                              : "",
                                          }}
                                        >
                                          <div>{item.name_sr}</div>
                                          <div>
                                            <button
                                              className="icon-16 grey-blue ms-2"
                                              title="Uredi podgrupu"
                                              onClick={() => {
                                                let newData = [];
                                                newData.group_id =
                                                  item.group_id;
                                                newData.name_sr = item.name_sr;
                                                newData.name_cp = item.name_cp;
                                                newData.name_en = item.name_en;
                                                newData.subgroup_id =
                                                  item.subgroup_id;
                                                setEditedSubgroup(newData);
                                                setEditSubgroupModal(true);
                                              }}
                                            >
                                              <i className="bi bi-pencil-fill"></i>
                                            </button>

                                            <button
                                              className={
                                                item.active === "1"
                                                  ? "icon-16 green ms-2"
                                                  : "icon-16 red ms-2"
                                              }
                                              title={
                                                item.active === "1"
                                                  ? "Aktivna"
                                                  : "Nije aktivna"
                                              }
                                              onClick={() =>
                                                handleActiveSubgroup(
                                                  index,
                                                  item.subgroup_id,
                                                  item.active
                                                )
                                              }
                                            >
                                              {item.active === "1" ? (
                                                <i className="bi bi-toggle-on"></i>
                                              ) : (
                                                <i className="bi bi-toggle-off"></i>
                                              )}
                                            </button>
                                            <button
                                              className="icon-16 grey-red ms-2"
                                              title="Obriši podgrupu"
                                              onClick={() => {
                                                subgroupEmpty(
                                                  item.subgroup_id,
                                                  item.name_sr
                                                );
                                              }}
                                            >
                                              <i className="bi bi-trash3-fill"></i>
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                              )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    {authorized && !loading && subgroupSortingChanged && (
                      <div className="row mt-3">
                        <div className="col-md text-center">
                          <Button
                            title="Sačuvaj promenu redosleda"
                            variant="primary"
                            onClick={saveSubgroupSorting}
                          >
                            Sačuvaj
                            {savingSubgroupSorting ? (
                              <i className="ms-1 fa fa-spinner fa-spin"></i>
                            ) : null}
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Loader loading={loading} />

      {!loading && (
        <>
          <Modal
            show={deleteModal}
            onHide={() => {
              setDeleteModal(false);
            }}
            backdrop="static"
            keyboard={false}
            centered
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {deleteType === "group"
                  ? "Brisanje grupe"
                  : "Brisanje podgrupe"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row-md">
                <div className="col-md mb-2">
                  {deleteType === "group"
                    ? "Obrisati grupu " + deleteName + "?"
                    : "Obrisati podgrupu " + deleteName + "?"}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setDeleteModal(false);
                }}
              >
                Zatvori
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  handleDelete(deleteType, deleteId);
                }}
              >
                Obriši
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={addGroupModal}
            onHide={() => {
              setEditedGroup([]);
              setAddGroupModal(false);
            }}
            backdrop="static"
            keyboard={false}
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Dodaj grupu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row mb-3">
                <div className="col-md-6">
                  <Form.Label className="mb-1 standard-size">
                    Naziv grupe
                  </Form.Label>
                  <div className="row">
                    <div
                      className={
                        editedGroup.name_sr === "" ||
                        editedGroup.name_sr === undefined
                          ? "pt-1 col-2 red"
                          : "pt-1 col-2"
                      }
                    >
                      Latinica
                    </div>
                    <div className="col-10">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue=""
                          onChange={(cur) => {
                            let newData = { ...editedGroup };
                            newData.name_sr = cur.target.value;
                            newData.group_id = "0";
                            setEditedGroup(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  {global.getUseCP() && (
                    <div className="row">
                      <div
                        className={
                          editedGroup.name_cp === "" ||
                          editedGroup.name_cp === undefined
                            ? "pt-1 col-2 red"
                            : "pt-1 col-2"
                        }
                      >
                        Ćirilica
                      </div>
                      <div className="col-10">
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            defaultValue=""
                            onChange={(cur) => {
                              let newData = { ...editedGroup };
                              newData.name_cp = cur.target.value;
                              newData.group_id = "0";
                              setEditedGroup(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div
                      className={
                        editedGroup.name_en === "" ||
                        editedGroup.name_en === undefined
                          ? "pt-1 col-2 red"
                          : "pt-1 col-2"
                      }
                    >
                      Engleski
                    </div>
                    <div className="col-10">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue=""
                          onChange={(cur) => {
                            let newData = { ...editedGroup };
                            newData.name_en = cur.target.value;
                            newData.group_id = "0";
                            setEditedGroup(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>

                  <Form.Label className="mb-1 mt-2 standard-size">
                    Kratak opis
                  </Form.Label>
                  <div className="row">
                    <div
                      className={
                        editedGroup.text_sr === "" ||
                        editedGroup.text_sr === undefined
                          ? "pt-1 col-2 red"
                          : "pt-1 col-2"
                      }
                    >
                      Latinica
                    </div>
                    <div className="col-10">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue=""
                          onChange={(cur) => {
                            let newData = { ...editedGroup };
                            newData.text_sr = cur.target.value;
                            newData.group_id = "0";
                            setEditedGroup(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  {global.getUseCP() && (
                    <div className="row">
                      <div
                        className={
                          editedGroup.text_cp === "" ||
                          editedGroup.text_cp === undefined
                            ? "pt-1 col-2 red"
                            : "pt-1 col-2"
                        }
                      >
                        Ćirilica
                      </div>
                      <div className="col-10">
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            defaultValue=""
                            onChange={(cur) => {
                              let newData = { ...editedGroup };
                              newData.text_cp = cur.target.value;
                              newData.group_id = "0";
                              setEditedGroup(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div
                      className={
                        editedGroup.text_en === "" ||
                        editedGroup.text_en === undefined
                          ? "pt-1 col-2 red"
                          : "pt-1 col-2"
                      }
                    >
                      Engleski
                    </div>
                    <div className="col-10">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue=""
                          onChange={(cur) => {
                            let newData = { ...editedGroup };
                            newData.text_en = cur.target.value;
                            newData.group_id = "0";
                            setEditedGroup(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 text-center">
                  <div
                    className={
                      editedGroup.filename
                        ? "mb-0 standard-size text-center"
                        : "mb-0 standard-size text-center red"
                    }
                  >
                    Izabrana ikonica
                  </div>
                  {editedGroup.filename && (
                    <img
                      width="60%"
                      src={
                        process.env.REACT_APP_RESOURCES +
                        "ui/" +
                        editedGroup.filename
                      }
                      alt={editedGroup.filename}
                    />
                  )}
                </div>
              </div>
              <div className="row scroll">
                {ui.map((item) => (
                  <div className="col-md-2 p-2" key={item.ui_id}>
                    <button
                      className="icon-button p-2"
                      title={item.ui_name}
                      onClick={() => {
                        let newData = { ...editedGroup };
                        newData.filename = item.ui_filename;
                        setEditedGroup(newData);
                      }}
                    >
                      <img
                        width="100%"
                        src={
                          process.env.REACT_APP_RESOURCES +
                          "ui/" +
                          item.ui_filename
                        }
                        alt={item.ui_name}
                      />
                    </button>
                  </div>
                ))}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setEditedGroup([]);
                  setAddGroupModal(false);
                }}
              >
                Zatvori
              </Button>
              <Button
                variant="primary"
                disabled={
                  editedGroup.name_sr === undefined ||
                  editedGroup.name_en === undefined ||
                  editedGroup.text_sr === undefined ||
                  editedGroup.text_en === undefined ||
                  editedGroup.filename === undefined ||
                  editedGroup.name_sr === "" ||
                  editedGroup.name_en === "" ||
                  editedGroup.text_sr === "" ||
                  editedGroup.text_en === "" ||
                  editedGroup.filename === "" ||
                  (global.getUseCP() && editedGroup.name_cp === undefined) ||
                  (global.getUseCP() && editedGroup.text_cp === undefined) ||
                  (global.getUseCP() && editedGroup.name_cp === "") ||
                  (global.getUseCP() && editedGroup.text_cp === "")
                    ? true
                    : false
                }
                onClick={handleSaveGroup}
              >
                Sačuvaj
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={editGroupModal}
            onHide={() => {
              setEditedGroup([]);
              setEditGroupModal(false);
            }}
            backdrop="static"
            keyboard={false}
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Uredi grupu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row mb-3">
                <div className="col-md-6">
                  <Form.Label className="mb-1 standard-size">
                    Naziv grupe
                  </Form.Label>
                  <div className="row">
                    <div
                      className={
                        editedGroup.name_sr === "" ||
                        editedGroup.name_sr === undefined
                          ? "pt-1 col-2 red"
                          : "pt-1 col-2"
                      }
                    >
                      Latinica
                    </div>
                    <div className="col-10">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue={editedGroup.name_sr}
                          onChange={(cur) => {
                            let newData = { ...editedGroup };
                            newData.name_sr = cur.target.value;
                            setEditedGroup(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  {global.getUseCP() && (
                    <div className="row">
                      <div
                        className={
                          editedGroup.name_cp === "" ||
                          editedGroup.name_cp === undefined
                            ? "pt-1 col-2 red"
                            : "pt-1 col-2"
                        }
                      >
                        Ćirilica
                      </div>
                      <div className="col-10">
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            defaultValue={editedGroup.name_cp}
                            onChange={(cur) => {
                              let newData = { ...editedGroup };
                              newData.name_cp = cur.target.value;
                              setEditedGroup(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div
                      className={
                        editedGroup.name_en === "" ||
                        editedGroup.name_en === undefined
                          ? "pt-1 col-2 red"
                          : "pt-1 col-2"
                      }
                    >
                      Engleski
                    </div>
                    <div className="col-10">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue={editedGroup.name_en}
                          onChange={(cur) => {
                            let newData = { ...editedGroup };
                            newData.name_en = cur.target.value;
                            setEditedGroup(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>

                  <Form.Label className="mb-1 mt-2 standard-size">
                    Kratak opis
                  </Form.Label>
                  <div className="row">
                    <div
                      className={
                        editedGroup.text_sr === "" ||
                        editedGroup.text_sr === undefined
                          ? "pt-1 col-2 red"
                          : "pt-1 col-2"
                      }
                    >
                      Latinica
                    </div>
                    <div className="col-10">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue={editedGroup.text_sr}
                          onChange={(cur) => {
                            let newData = { ...editedGroup };
                            newData.text_sr = cur.target.value;
                            setEditedGroup(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  {global.getUseCP() && (
                    <div className="row">
                      <div
                        className={
                          editedGroup.text_cp === "" ||
                          editedGroup.text_cp === undefined
                            ? "pt-1 col-2 red"
                            : "pt-1 col-2"
                        }
                      >
                        Ćirilica
                      </div>
                      <div className="col-10">
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            defaultValue={editedGroup.text_cp}
                            onChange={(cur) => {
                              let newData = { ...editedGroup };
                              newData.text_cp = cur.target.value;
                              setEditedGroup(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div
                      className={
                        editedGroup.text_en === "" ||
                        editedGroup.text_en === undefined
                          ? "pt-1 col-2 red"
                          : "pt-1 col-2"
                      }
                    >
                      Engleski
                    </div>
                    <div className="col-10">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue={editedGroup.text_en}
                          onChange={(cur) => {
                            let newData = { ...editedGroup };
                            newData.text_en = cur.target.value;
                            setEditedGroup(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 text-center">
                  <div className="mb-0 standard-size text-center">
                    Izabrana ikonica
                  </div>
                  {editedGroup.filename && (
                    <img
                      width="60%"
                      src={
                        process.env.REACT_APP_RESOURCES +
                        "ui/" +
                        editedGroup.filename
                      }
                      alt={editedGroup.filename}
                    />
                  )}
                </div>
              </div>
              <div className="row scroll">
                {ui.map((item) => (
                  <div className="col-md-2 p-2" key={item.ui_id}>
                    <button
                      className="icon-button p-2"
                      title={item.ui_name}
                      onClick={() => {
                        let newData = { ...editedGroup };
                        newData.filename = item.ui_filename;
                        setEditedGroup(newData);
                      }}
                    >
                      <img
                        width="100%"
                        src={
                          process.env.REACT_APP_RESOURCES +
                          "ui/" +
                          item.ui_filename
                        }
                        alt={item.ui_name}
                      />
                    </button>
                  </div>
                ))}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setEditedGroup([]);
                  setEditGroupModal(false);
                }}
              >
                Zatvori
              </Button>
              <Button
                variant="primary"
                disabled={
                  editedGroup.name_sr === undefined ||
                  editedGroup.name_en === undefined ||
                  editedGroup.text_sr === undefined ||
                  editedGroup.text_en === undefined ||
                  editedGroup.filename === undefined ||
                  editedGroup.name_sr === "" ||
                  editedGroup.name_en === "" ||
                  editedGroup.text_sr === "" ||
                  editedGroup.text_en === "" ||
                  editedGroup.filename === "" ||
                  (global.getUseCP() && editedGroup.name_cp === undefined) ||
                  (global.getUseCP() && editedGroup.text_cp === undefined) ||
                  (global.getUseCP() && editedGroup.name_cp === "") ||
                  (global.getUseCP() && editedGroup.text_cp === "")
                    ? true
                    : false
                }
                onClick={handleSaveGroup}
              >
                Sačuvaj
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={addSubgroupModal}
            onHide={() => {
              setEditedSubgroup([]);
              setAddSubgroupModal(false);
            }}
            backdrop="static"
            keyboard={false}
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Dodaj podgrupu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md">
                  <Form.Label className="mb-0 standard-size">Grupa</Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Select
                      size="sm"
                      className="form-control form-control-sm standard-size"
                      onChange={(cur) => {
                        let newData = { ...editedSubgroup };
                        newData.group_id = cur.target.value;
                        setEditedSubgroup(newData);
                      }}
                    >
                      <option value="">Izaberi grupu</option>
                      {groups &&
                        groups.map((item) => (
                          <option
                            key={"SELECT2" + item.group_id}
                            value={item.group_id}
                          >
                            {item.name_sr}
                          </option>
                        ))}
                    </Form.Select>
                  </InputGroup>
                  <Form.Label className="mb-1 standard-size">
                    Naziv podgrupe
                  </Form.Label>
                  <div className="row">
                    <div
                      className={
                        editedSubgroup.name_sr === "" ||
                        editedSubgroup.name_sr === undefined
                          ? "col-2 pt-1 red"
                          : "col-2 pt-1"
                      }
                    >
                      Latinica
                    </div>
                    <div className="col-10">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue=""
                          onChange={(cur) => {
                            let newData = { ...editedSubgroup };
                            newData.name_sr = cur.target.value;
                            newData.subgroup_id = "0";
                            setEditedSubgroup(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  {global.getUseCP() && (
                    <div className="row">
                      <div
                        className={
                          editedSubgroup.name_cp === "" ||
                          editedSubgroup.name_cp === undefined
                            ? "col-2 pt-1 red"
                            : "col-2 pt-1"
                        }
                      >
                        Ćirilica
                      </div>
                      <div className="col-10">
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            defaultValue=""
                            onChange={(cur) => {
                              let newData = { ...editedSubgroup };
                              newData.name_cp = cur.target.value;
                              newData.subgroup_id = "0";
                              setEditedSubgroup(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div
                      className={
                        editedSubgroup.name_en === "" ||
                        editedSubgroup.name_en === undefined
                          ? "col-2 pt-1 red"
                          : "col-2 pt-1"
                      }
                    >
                      Engleski
                    </div>
                    <div className="col-10">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue=""
                          onChange={(cur) => {
                            let newData = { ...editedSubgroup };
                            newData.name_en = cur.target.value;
                            newData.subgroup_id = "0";
                            setEditedSubgroup(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setEditedSubgroup([]);
                  setAddSubgroupModal(false);
                }}
              >
                Zatvori
              </Button>
              <Button
                variant="primary"
                disabled={
                  editedSubgroup.name_sr === undefined ||
                  editedSubgroup.name_en === undefined ||
                  editedSubgroup.group_id === undefined ||
                  editedSubgroup.name_sr === "" ||
                  editedSubgroup.name_en === "" ||
                  editedSubgroup.group_id === "" ||
                  (global.getUseCP() && editedSubgroup.name_cp === undefined) ||
                  (global.getUseCP() && editedSubgroup.name_cp === "")
                    ? true
                    : false
                }
                onClick={handleSaveSubgroup}
              >
                Sačuvaj
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={editSubgroupModal}
            onHide={() => {
              setEditedSubgroup([]);
              setEditSubgroupModal(false);
            }}
            backdrop="static"
            keyboard={false}
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Uredi podgrupu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md">
                  <Form.Label className="mb-0 standard-size">Grupa</Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Select
                      size="sm"
                      className="form-control form-control-sm standard-size"
                      onChange={(cur) => {
                        let newData = { ...editedSubgroup };
                        newData.group_id = cur.target.value;
                        setEditedSubgroup(newData);
                      }}
                    >
                      <option value="">Izaberi grupu</option>
                      {groups &&
                        groups.map((item) => (
                          <option
                            key={"SELECT2" + item.group_id}
                            value={item.group_id}
                            selected={
                              editedSubgroup.group_id === item.group_id
                                ? true
                                : false
                            }
                          >
                            {item.name_sr}
                          </option>
                        ))}
                    </Form.Select>
                  </InputGroup>
                  <Form.Label className="mb-1 standard-size">
                    Naziv podgrupe
                  </Form.Label>
                  <div className="row">
                    <div
                      className={
                        editedSubgroup.name_sr === undefined ||
                        editedSubgroup.name_sr === ""
                          ? "col-2 pt-1 red"
                          : "col-2 pt-1"
                      }
                    >
                      Latinica
                    </div>
                    <div className="col-10">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue={editedSubgroup.name_sr}
                          onChange={(cur) => {
                            let newData = { ...editedSubgroup };
                            newData.name_sr = cur.target.value;
                            setEditedSubgroup(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  {global.getUseCP() && (
                    <div className="row">
                      <div
                        className={
                          editedSubgroup.name_cp === undefined ||
                          editedSubgroup.name_cp === ""
                            ? "col-2 pt-1 red"
                            : "col-2 pt-1"
                        }
                      >
                        Ćirilica
                      </div>
                      <div className="col-10">
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            defaultValue={editedSubgroup.name_cp}
                            onChange={(cur) => {
                              let newData = { ...editedSubgroup };
                              newData.name_cp = cur.target.value;
                              setEditedSubgroup(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div
                      className={
                        editedSubgroup.name_en === undefined ||
                        editedSubgroup.name_en === ""
                          ? "col-2 pt-1 red"
                          : "col-2 pt-1"
                      }
                    >
                      Engleski
                    </div>
                    <div className="col-10">
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          defaultValue={editedSubgroup.name_en}
                          onChange={(cur) => {
                            let newData = { ...editedSubgroup };
                            newData.name_en = cur.target.value;
                            setEditedSubgroup(newData);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setEditedSubgroup([]);
                  setEditSubgroupModal(false);
                }}
              >
                Zatvori
              </Button>
              <Button
                variant="primary"
                disabled={
                  editedSubgroup.name_sr === undefined ||
                  editedSubgroup.name_en === undefined ||
                  editedSubgroup.group_id === undefined ||
                  editedSubgroup.name_sr === "" ||
                  editedSubgroup.name_en === "" ||
                  editedSubgroup.group_id === "" ||
                  (global.getUseCP() && editedSubgroup.name_cp === undefined) ||
                  (global.getUseCP() && editedSubgroup.name_cp === "")
                    ? true
                    : false
                }
                onClick={handleSaveSubgroup}
              >
                Sačuvaj
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}

      <ErrorModal show={error} message={errorMessage}></ErrorModal>
      <InfoModal
        show={infoModal}
        title="Obaveštenje"
        message="Izabrana podgrupa sadrži lokacije i ne može biti obrisana."
        close={closeInfoModal}
      ></InfoModal>
    </>
  );
}
