import React, { useEffect, useState } from "react";
import { InputGroup, ToggleButton, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import sr from "date-fns/locale/sr-Latn";
import PreviewModal from "../components/newsAndEvents/previewModal";
import ErrorModal from "../components/errorModal";
import Loader from "../components/loader";
import InfoModal from "../components/infoModal";
import ShareModal from "../components/shareModal";
import { lat2cir, cir2lat } from "../helpers/transcribe";
registerLocale("sr", sr);

export default function NewsAdd() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [photoLoading, setPhotoLoading] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [publishers, setPublishers] = useState([]);
  const [savingData, setSavingData] = useState(false);
  const [photoTextSr, setPhotoTextSr] = useState("");
  const [photoTextEn, setPhotoTextEn] = useState("");
  const [photoTextCp, setPhotoTextCp] = useState("");
  const [photoSource, setPhotoSource] = useState("");
  const [newsModal, setNewsModal] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const moduleId = "082a8bbf2c357c09f26675f9cf5bcba3";
  const [errorToggle, setErrorToggle] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [shareBody, setShareBody] = useState("");
  const [shareSubject, setShareSubject] = useState("");
  const [shareMedia, setShareMedia] = useState("");
  const [shareUrl, setShareUrl] = useState("");

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_dummy_news.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(receivedData);
          setTimeout(() => {
            setLoading(false);
            setAuthorized(true);
          }, 200);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  }, []);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_publishers_list.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setPublishers(receivedData);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  }, []);

  const handleSave = () => {
    setSavingData(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        news_id: data.news_id,
        published: data.published,
        source: data.source,
        published_by: data.published_by,
        show_publisher: data.show_publisher,
        title_sr: data.title_sr,
        text_sr: data.text_sr,
        title_cp: data.title_cp,
        text_cp: data.text_cp,
        title_en: data.title_en,
        text_en: data.text_en,
        seo_title_sr: data.seo.seo_title_sr,
        seo_description_sr: data.seo.seo_description_sr,
        seo_title_cp: data.seo.seo_title_cp,
        seo_description_cp: data.seo.seo_description_cp,
        seo_title_en: data.seo.seo_title_en,
        seo_description_en: data.seo.seo_description_en,
        dummy: data.dummy,
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_edit_single_news.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.response) {
          setData(responseData);
          setTimeout(() => {
            setSavingData(false);
          }, 500);
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  const upload_image = (file) => {
    setPhotoLoading(true);
    const form_data = new FormData();

    form_data.append("old_photo_id", data.photos.photo_id);
    form_data.append("text_sr", photoTextSr);
    form_data.append("text_cp", photoTextCp);
    form_data.append("text_en", photoTextEn);
    form_data.append("credit", photoSource);
    form_data.append("old_filename", data.photos.filename);
    form_data.append("photo", file[0]);
    form_data.append("parent_id", data.news_id);
    form_data.append("city_id", global.getCity());
    form_data.append("session_id", localStorage.getItem("authToken"));
    form_data.append("module", moduleId);

    fetch(process.env.REACT_APP_BACKEND_ROOT + "_private/_upload_single.php", {
      method: "POST",
      body: form_data,
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (responseData) {
        if (!responseData.response) {
          let newData = { ...data };
          let newResponseData = { ...responseData };
          newResponseData.filename =
            newResponseData.filename + "?" + Date.now();
          newData.photos = newResponseData;
          setData(newData);
          setPhotoLoading(false);
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  const editImageMeta = () => {
    setPhotoLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        photo_id: data.photos.photo_id,
        text_sr: photoTextSr,
        text_cp: photoTextCp,
        text_en: photoTextEn,
        credit: photoSource,
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_edit_image_meta.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.response) {
          let newData = { ...data };
          newData.photos = responseData;
          setData(newData);
          setTimeout(() => {
            setPhotoLoading(false);
          }, "500");
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  const handleActive = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        news_id: data.news_id,
        active: data.active,
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_toggle_news_state.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.response) {
          let newData = { ...data };
          newData.active = responseData.active;
          setData(newData);
          if (responseData.error) {
            setErrorToggle(true);
          }
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  const closeModal = () => {
    setPhotoTextSr("");
    setPhotoTextCp("");
    setPhotoTextEn("");
    setPhotoSource("");
    setImageModal(false);
  };

  const closeNewsModal = () => {
    setNewsModal(false);
  };

  const generateSeo = (language) => {
    let page = "";
    if (language === "sr") {
      page = "Vesti";
    }
    if (language === "cp") {
      page = "Вести";
    }
    if (language === "en") {
      page = "News";
    }
    let newData = { ...data };
    newData.seo["seo_title_" + language] =
      data["title_" + language] +
      " - " +
      page +
      " | " +
      global.appName(language);
    newData.seo["seo_description_" + language] =
      data["text_" + language]
        .replace(/(<([^>]+)>)/gi, "")
        .replace(/(\r\n|\n|\r)/gm, "")
        .split(" ")
        .slice(0, 20)
        .join(" ") + "...";
    setData(newData);
  };

  return (
    <>
      <div className="container-lg pb-5">
        <div className="row">
          <div className="col-md mt-4 mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                <h3 className="mb-0">Vesti / Nova objava</h3>
              </div>
            </div>
          </div>
        </div>
        {authorized && !loading && (
          <>
            <div className="row">
              <div className="col-md-4 mb-4">
                <div className="h-100 card card-small">
                  <div className="d-flex card-body p-4">
                    <h5 className="mb-3">Osnovni podaci</h5>
                    <Form.Label className="mb-0 standard-size">
                      Datum objave
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <DatePicker
                        locale="sr"
                        showTimeSelect
                        timeIntervals={15}
                        dateFormat="dd.MM.yyyy. HH:mm"
                        className="form-control form-control-sm standard-size"
                        selected={new Date(parseInt(data.published + "000"))}
                        onChange={(date) => {
                          let newData = { ...data };
                          newData.published = Math.floor(
                            new Date(date).getTime() / 1000
                          );
                          setData(newData);
                        }}
                        popperPlacement="bottom"
                        popperModifiers={[
                          {
                            name: "offset",
                            options: {
                              offset: [10, 0],
                            },
                          },
                        ]}
                      />
                    </InputGroup>
                    <Form.Label className="mb-0 standard-size">
                      Izvor objave
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        className="form-control form-control-sm standard-size"
                        type="text"
                        value={data.source}
                        onChange={(cur) => {
                          let newData = { ...data };
                          newData.source = cur.target.value;
                          setData(newData);
                        }}
                      />
                    </InputGroup>
                    <Form.Check
                      size="sm"
                      className="standard-size mb-1"
                      checked={data.show_publisher === "1" ? true : false}
                      onChange={(checked) => {
                        let newData = { ...data };
                        if (checked.target.checked === true) {
                          newData.show_publisher = "1";
                        } else {
                          newData.show_publisher = "0";
                          newData.published_by = "";
                        }
                        setData(newData);
                      }}
                      type="checkbox"
                      label="Prikaži objavljivača"
                    />
                    {data.show_publisher === "1" && (
                      <>
                        <Form.Label
                          className={
                            data.published_by === "" &&
                            data.show_publisher === "1"
                              ? "mb-0 standard-size red"
                              : "mb-0 standard-size"
                          }
                        >
                          Objavljuje
                        </Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Select
                            size="sm"
                            className="form-control form-control-sm standard-size"
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.published_by = cur.target.value;
                              setData(newData);
                            }}
                            defaultValue={data.published_by}
                          >
                            <option key="category_0" value="">
                              Izaberi lokaciju
                            </option>
                            {publishers.map((publisher) => (
                              <option
                                key={publisher.location_id}
                                value={publisher.location_id}
                              >
                                {publisher.name_sr}
                              </option>
                            ))}
                          </Form.Select>
                        </InputGroup>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-8 mb-4">
                <div className="h-100 card card-small">
                  <div className="d-flex card-body p-4">
                    <div className="row">
                      <div className="col-md-8 m-0">
                        <h5
                          className={
                            data.photos.filename === "" ? "mb-3 red" : "mb-3"
                          }
                        >
                          Prateća fotografija
                        </h5>
                      </div>
                      <div className="col-md-4 m-0 text-right">
                        <button
                          className="icon-16 grey-blue ms-2"
                          title="Dodaj / uredi fotografiju"
                          onClick={() => {
                            setPhotoTextSr(data.photos.text_sr);
                            setPhotoTextEn(data.photos.text_en);
                            setPhotoTextCp(data.photos.text_cp);
                            setPhotoSource(data.photos.credit);
                            setImageModal(true);
                          }}
                        >
                          <i className="bi bi-pencil-fill"></i>
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        {data.photos.filename !== "" ? (
                          <img
                            style={{
                              transition: "all 500ms",
                            }}
                            width="100%"
                            src={
                              process.env.REACT_APP_RESOURCES +
                              data.photos.path +
                              "medium/" +
                              data.photos.filename +
                              "?" +
                              Date.now()
                            }
                            alt={data.photos.text_sr}
                          />
                        ) : (
                          <img
                            style={{
                              transition: "all 500ms",
                            }}
                            width="100%"
                            src={"https://cdn.inndex.rs/assets/dummy.jpg"}
                            alt={data.photos.text_sr}
                          />
                        )}
                      </div>
                      <div className="col-md-6">
                        {data.photos.text_sr !== "" && (
                          <>
                            <div>
                              <strong className="standard-size">
                                {global.getUseCP()
                                  ? "Opis na srpskom jeziku (lat)"
                                  : "Opis na srpskom jeziku"}
                              </strong>
                            </div>
                            <div className="mb-3 standard-size">
                              {data.photos.text_sr}
                            </div>
                          </>
                        )}
                        {global.getUseCP() && data.photos.text_cp !== "" && (
                          <>
                            <div>
                              <strong className="standard-size">
                                Opis na srpskom jeziku (ćir)
                              </strong>
                            </div>
                            <div className="mb-3 standard-size">
                              {data.photos.text_cp}
                            </div>
                          </>
                        )}
                        {data.photos.text_en !== "" && (
                          <>
                            <div>
                              <strong className="standard-size">
                                Opis na engleskom jeziku
                              </strong>
                            </div>
                            <div className="mb-3 standard-size">
                              {data.photos.text_en}
                            </div>
                          </>
                        )}
                        {data.photos.credit !== "" && (
                          <>
                            <div>
                              <strong className="standard-size">
                                Izvor fotografije
                              </strong>
                            </div>
                            <div className="mb-3 standard-size">
                              {data.photos.credit}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mb-4">
                <div className="h-100 card card-small">
                  <div className="d-flex card-body p-4">
                    <div className="row">
                      <div className="col-7">
                        <h5 className="mb-3">
                          {global.getUseCP()
                            ? "Sadržaj na srpskom jeziku (lat)"
                            : "Sadržaj na srpskom jeziku"}
                        </h5>
                        <Form.Label
                          className={
                            data.title_sr === ""
                              ? "mb-0 standard-size red"
                              : "mb-0 standard-size"
                          }
                        >
                          Naslov
                        </Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            value={data.title_sr}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.title_sr = cur.target.value;
                              setData(newData);
                            }}
                          />
                        </InputGroup>
                        <Form.Label
                          className={
                            data.text_sr === ""
                              ? "mb-0 standard-size red"
                              : "mb-0 standard-size"
                          }
                        >
                          Tekst
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            rows={15}
                            as="textarea"
                            value={data.text_sr}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.text_sr = cur.target.value;
                              setData(newData);
                            }}
                          />
                        </InputGroup>
                        {global.getUseCP() && (
                          <div className="col mt-3">
                            <Button
                              className="btn-primary"
                              size="sm"
                              title="Preslovi u ćirilicu"
                              disabled={
                                data.title_sr === "" || data.text_sr === ""
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                let newData = { ...data };
                                newData.text_cp = lat2cir(data.text_sr);
                                newData.title_cp = lat2cir(data.title_sr);
                                setData(newData);
                              }}
                            >
                              {"Preslovi u ćirilicu"}
                            </Button>
                          </div>
                        )}
                      </div>
                      <div className="col-5">
                        <h5 className="mb-3">
                          {global.getUseCP()
                            ? "SEO na srpskom jeziku (lat)"
                            : "SEO na srpskom jeziku"}
                        </h5>
                        <Form.Label
                          className={
                            data.seo.seo_title_sr === ""
                              ? "mb-0 standard-size red"
                              : "mb-0 standard-size"
                          }
                        >
                          SEO naslov
                        </Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            value={data.seo.seo_title_sr}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.seo.seo_title_sr = cur.target.value;
                              setData(newData);
                            }}
                          />
                        </InputGroup>
                        <Form.Label
                          className={
                            data.seo.seo_description_sr === ""
                              ? "mb-0 standard-size red"
                              : "mb-0 standard-size"
                          }
                        >
                          SEO opis
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            rows={15}
                            as="textarea"
                            value={data.seo.seo_description_sr}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.seo.seo_description_sr = cur.target.value;
                              setData(newData);
                            }}
                          />
                        </InputGroup>
                        <div className="col mt-3">
                          <Button
                            className="btn-primary"
                            size="sm"
                            title="Generiši SEO automatski"
                            disabled={
                              data.title_sr === "" || data.text_sr === ""
                                ? true
                                : false
                            }
                            onClick={() => {
                              generateSeo("sr");
                            }}
                          >
                            {"Generiši SEO automatski"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {global.getUseCP() && (
              <div className="row">
                <div className="col-md-12 mb-4">
                  <div className="h-100 card card-small">
                    <div className="d-flex card-body p-4">
                      <div className="row">
                        <div className="col-7">
                          <h5 className="mb-3">
                            Sadržaj na srpskom jeziku (ćir)
                          </h5>
                          <Form.Label
                            className={
                              data.title_cp === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            Naslov
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data.title_cp}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.title_cp = cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <Form.Label
                            className={
                              data.text_cp === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            Tekst
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={15}
                              as="textarea"
                              value={data.text_cp}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.text_cp = cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <div className="col mt-3">
                            <Button
                              className="btn-primary"
                              size="sm"
                              title="Preslovi u latinicu"
                              disabled={
                                data.title_cp === "" || data.text_cp === ""
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                let newData = { ...data };
                                newData.text_sr = cir2lat(data.text_cp);
                                newData.title_sr = cir2lat(data.title_cp);
                                setData(newData);
                              }}
                            >
                              {"Preslovi u latinicu"}
                            </Button>
                          </div>
                        </div>
                        <div className="col-5">
                          <h5 className="mb-3">SEO na srpskom jeziku (ćir)</h5>
                          <Form.Label
                            className={
                              data.seo.seo_title_cp === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO naslov
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data.seo.seo_title_cp}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.seo.seo_title_cp = cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <Form.Label
                            className={
                              data.seo.seo_description_cp === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO opis
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={15}
                              as="textarea"
                              value={data.seo.seo_description_cp}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.seo.seo_description_cp =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <div className="col mt-3">
                            <Button
                              className="btn-primary"
                              size="sm"
                              title="Generiši SEO automatski"
                              disabled={
                                data.title_cp === "" || data.text_cp === ""
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                generateSeo("cp");
                              }}
                            >
                              {"Generiši SEO automatski"}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12 mb-4">
                <div className="h-100 card card-small">
                  <div className="d-flex card-body p-4">
                    <div className="row">
                      <div className="col-7">
                        <h5 className="mb-3">Sadržaj na engleskom jeziku</h5>
                        <Form.Label
                          className={
                            data.title_en === ""
                              ? "mb-0 standard-size red"
                              : "mb-0 standard-size"
                          }
                        >
                          Naslov
                        </Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            value={data.title_en}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.title_en = cur.target.value;
                              setData(newData);
                            }}
                          />
                        </InputGroup>
                        <Form.Label
                          className={
                            data.text_en === ""
                              ? "mb-0 standard-size red"
                              : "mb-0 standard-size"
                          }
                        >
                          Tekst
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            rows={15}
                            as="textarea"
                            value={data.text_en}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.text_en = cur.target.value;
                              setData(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                      <div className="col-5">
                        <h5 className="mb-3">SEO na engleskom jeziku</h5>
                        <Form.Label
                          className={
                            data.seo.seo_title_en === ""
                              ? "mb-0 standard-size red"
                              : "mb-0 standard-size"
                          }
                        >
                          SEO naslov
                        </Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            value={data.seo.seo_title_en}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.seo.seo_title_en = cur.target.value;
                              setData(newData);
                            }}
                          />
                        </InputGroup>
                        <Form.Label
                          className={
                            data.seo.seo_description_en === ""
                              ? "mb-0 standard-size red"
                              : "mb-0 standard-size"
                          }
                        >
                          SEO opis
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            rows={15}
                            as="textarea"
                            value={data.seo.seo_description_en}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.seo.seo_description_en = cur.target.value;
                              setData(newData);
                            }}
                          />
                        </InputGroup>
                        <div className="col mt-3">
                          <Button
                            className="btn-primary"
                            size="sm"
                            title="Generiši SEO automatski"
                            disabled={
                              data.title_en === "" || data.text_en === ""
                                ? true
                                : false
                            }
                            onClick={() => {
                              generateSeo("en");
                            }}
                          >
                            {"Generiši SEO automatski"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <Loader loading={loading} />

      <div className="card card-small footer-overlay">
        <div className="card-body">
          <ToggleButton
            className="ms-2"
            size="sm"
            id="toggle-check"
            type="checkbox"
            disabled={data?.dummy === "1" ? true : false}
            variant={
              data?.active === "1" ? "outline-success" : "outline-danger"
            }
            checked={data?.active === "1" ? true : false}
            value="1"
            onChange={handleActive}
            title={data?.active === "1" ? "Objavljeno" : "Nije objavljeno"}
          >
            {data?.active === "1" ? (
              <i className="bi bi-toggle-on"></i>
            ) : (
              <i className="bi bi-toggle-off"></i>
            )}
          </ToggleButton>

          <Button
            className="ms-2 btn-primary"
            size="sm"
            title="Podeli"
            disabled={data.active === "1" ? false : true}
            onClick={() => {
              setShareBody(data.text_sr);
              setShareSubject(data.title_sr);
              setShareMedia(
                process.env.REACT_APP_RESOURCES +
                  data.photos.path +
                  "large/" +
                  data.photos.filename
              );
              setShareUrl(global.getCityUrl() + "vesti/" + data.stub);
              setShowShare(true);
            }}
          >
            <i className="bi bi-share-fill"></i>
          </Button>
          <Button
            className="ms-2 btn-primary"
            size="sm"
            title="Pregled objave"
            onClick={() => setNewsModal(true)}
            disabled={
              data.photos?.filename === "" ||
              data?.title_sr === "" ||
              data?.title_en === "" ||
              data?.text_sr === "" ||
              data?.text_en === "" ||
              (global.getUseCP() && data?.title_cp === "") ||
              (global.getUseCP() && data?.text_cp === "")
                ? true
                : false
            }
          >
            <i className="bi bi-eye-fill"></i>
          </Button>
          <Button
            className="ms-2 btn-primary"
            size="sm"
            onClick={handleSave}
            disabled={
              data.category_id === "" ||
              (data.show_publisher === "1" && data.published_by === "") ||
              data.photos?.filename === "" ||
              data?.title_sr === "" ||
              data?.title_en === "" ||
              data?.text_sr === "" ||
              data?.text_en === "" ||
              data?.seo?.seo_title_sr === "" ||
              data?.seo?.seo_title_en === "" ||
              data?.seo?.seo_description_sr === "" ||
              data?.seo?.seo_description_en === "" ||
              (global.getUseCP() && data?.title_cp === "") ||
              (global.getUseCP() && data?.text_cp === "") ||
              (global.getUseCP() && data?.seo?.seo_title_cp === "") ||
              (global.getUseCP() && data?.seo?.seo_description_cp === "")
                ? true
                : false
            }
          >
            Sačuvaj
            {savingData ? <i className="ms-1 fa fa-spinner fa-spin"></i> : null}
          </Button>
        </div>
      </div>
      {!loading && (
        <Modal
          show={imageModal}
          onHide={closeModal}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Dodaj / uredi fotografiju</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                {data.photos.filename !== "" ? (
                  <img
                    className="mb-2"
                    style={{
                      transition: "all 500ms",
                      filter: photoLoading ? "blur(3px)" : "none",
                    }}
                    width="100%"
                    src={
                      process.env.REACT_APP_RESOURCES +
                      data.photos.path +
                      "medium/" +
                      data.photos.filename +
                      "?" +
                      Date.now()
                    }
                    alt={data.photos.text_sr}
                  />
                ) : (
                  <img
                    className="mb-2"
                    style={{
                      transition: "all 500ms",
                      filter: photoLoading ? "blur(3px)" : "none",
                    }}
                    width="100%"
                    src={"https://cdn.inndex.rs/assets/dummy.jpg"}
                    alt={data.photos.text_sr}
                  />
                )}

                <input
                  disabled={
                    photoTextSr === "" ||
                    photoTextEn === "" ||
                    (global.getUseCP() && photoTextCp === "")
                      ? true
                      : false
                  }
                  id="upload_image"
                  className="position-absolute invisible"
                  type="file"
                  name="sample_image"
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => {
                    upload_image(e.target.files);
                  }}
                />
                <label
                  className={
                    photoTextSr === "" ||
                    photoTextEn === "" ||
                    (global.getUseCP() && photoTextCp === "")
                      ? "btn btn-primary btn-sm mb-3 disabled"
                      : "btn btn-primary btn-sm mb-3"
                  }
                  htmlFor="upload_image"
                >
                  Izaberi fotografiju
                  {!loading && data.photos.filename === "" ? (
                    <i className="bi bi-exclamation-triangle-fill red ms-2"></i>
                  ) : null}
                </label>
              </div>
              <div className="col-md-12">
                <Form.Label
                  className={
                    photoTextSr === ""
                      ? "mb-0 standard-size red"
                      : "mb-0 standard-size"
                  }
                >
                  {global.getUseCP()
                    ? "Opis na srpskom jeziku (lat)"
                    : "Opis na srpskom jeziku"}
                </Form.Label>
                <InputGroup className="mb-2">
                  <Form.Control
                    className="form-control form-control-sm standard-size"
                    type="text"
                    defaultValue={data.photos.text_sr}
                    onChange={(cur) => {
                      setPhotoTextSr(cur.target.value);
                    }}
                  />
                </InputGroup>
                {global.getUseCP() && (
                  <>
                    <Form.Label
                      className={
                        photoTextCp === ""
                          ? "mb-0 standard-size red"
                          : "mb-0 standard-size"
                      }
                    >
                      Opis na srpskom jeziku (ćir)
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        className="form-control form-control-sm standard-size"
                        type="text"
                        defaultValue={data.photos.text_cp}
                        onChange={(cur) => {
                          setPhotoTextCp(cur.target.value);
                        }}
                      />
                    </InputGroup>
                  </>
                )}
                <Form.Label
                  className={
                    photoTextEn === ""
                      ? "mb-0 standard-size red"
                      : "mb-0 standard-size"
                  }
                >
                  Opis na engleskom jeziku
                </Form.Label>
                <InputGroup className="mb-2">
                  <Form.Control
                    className="form-control form-control-sm standard-size"
                    type="text"
                    defaultValue={data.photos.text_en}
                    onChange={(cur) => {
                      setPhotoTextEn(cur.target.value);
                    }}
                  />
                </InputGroup>
                <Form.Label className="mb-0 standard-size">
                  Izvor fotografije
                </Form.Label>
                <InputGroup className="mb-2">
                  <Form.Control
                    className="form-control form-control-sm standard-size"
                    type="text"
                    defaultValue={data.photos.credit}
                    onChange={(cur) => {
                      setPhotoSource(cur.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Zatvori
            </Button>
            <Button
              disabled={
                (!loading && data.photos.filename === "") ||
                photoTextSr === "" ||
                photoTextEn === "" ||
                (global.getUseCP() && photoTextCp === "")
                  ? true
                  : false
              }
              variant="primary"
              onClick={editImageMeta}
            >
              Sačuvaj
              {photoLoading ? (
                <i className="ms-1 fa fa-spinner fa-spin"></i>
              ) : null}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {!loading && (
        <PreviewModal
          show={newsModal}
          onClosePreview={closeNewsModal}
          data={data}
          loading={loading}
          postType="news"
        ></PreviewModal>
      )}

      <ErrorModal show={error} message={errorMessage}></ErrorModal>
      <InfoModal
        show={errorToggle}
        title="Greška"
        message="Objava ne može biti aktivirana jer ne ispunjava sve tehničke zahteve."
        close={() => {
          setErrorToggle(false);
        }}
      ></InfoModal>
      <ShareModal
        show={showShare}
        onCloseShare={() => {
          setShowShare(false);
        }}
        body={shareBody}
        subject={shareSubject}
        media={shareMedia}
        url={shareUrl}
      />
    </>
  );
}
