import React, { useEffect, useState, useRef } from "react";
import { InputGroup, ToggleButton, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import sr from "date-fns/locale/sr-Latn";
import ErrorModal from "../components/errorModal";
import Loader from "../components/loader";
registerLocale("sr", sr);

export default function UsersAdd() {
  const [data, setData] = useState([]);
  const [modulesList, setModulesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [modulesApproved, setModulesApproved] = useState([]);
  const [citiesApproved, setCitiesApproved] = useState([]);
  const [loading, setLoading] = useState(true);
  const [savingData, setSavingData] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const moduleId = "91576cbf171986154e523305a69c79d3";
  const [validEmail, setValidEmail] = useState(true);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_dummy_user.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(receivedData.user_data);
          setModulesApproved(receivedData.modules_approved);
          setCitiesApproved(receivedData.cities_approved);
          setModulesList(receivedData.modules_list);
          setCitiesList(receivedData.cities_list);
          setTimeout(() => {
            setLoading(false);
            setAuthorized(true);
          }, 200);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  }, []);

  const handleSave = () => {
    setSavingData(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
        user_id: data.user_id,
        user_name: data.user_name,
        user_email: data.user_email,
        cities_approved: citiesApproved,
        modules_approved: modulesApproved,
        dummy: data.dummy,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_edit_single_user.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.response) {
          setTimeout(() => {
            setSavingData(false);
            setData(responseData.user_data);
            setModulesApproved(responseData.modules_approved);
            setCitiesApproved(responseData.cities_approved);
          }, 500);
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  const handleActive = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        user_id: data.user_id,
        active: data.active,
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_toggle_user_state.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.response) {
          let newData = { ...data };
          newData.active = responseData.active;
          setData(newData);
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  function validateEmail(email) {
    if (/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  }

  return (
    <>
      <div className="container-lg pb-5">
        <div className="row">
          <div className="col-md mt-4 mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                <h3 className="mb-0">Korisnici / Novi korisnik</h3>
              </div>
            </div>
          </div>
        </div>
        {authorized && !loading && (
          <>
            <div className="row">
              <div className="col-md-4 mb-4">
                <div className="h-100 card card-small">
                  <div className="d-flex card-body p-4">
                    <h5 className="mb-3">Korisnički podaci</h5>
                    <Form.Label className="mb-0 standard-size">
                      Ime i prezime
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        className="form-control form-control-sm standard-size"
                        type="text"
                        defaultValue={data.user_name}
                        onChange={(cur) => {
                          let newData = { ...data };
                          newData.user_name = cur.target.value;
                          setData(newData);
                        }}
                      />
                    </InputGroup>
                    <Form.Label className="mb-0 standard-size">
                      E-mail adresa
                      {data.dummy === "0" &&
                        (data.user_email_confirmed === "0" ? (
                          <i
                            title="E-mail adresa nije verifikovana. Korisnik neće moći da pristupi sistemu."
                            className="bi bi-patch-check ms-1 red"
                          ></i>
                        ) : (
                          <i
                            title="E-mail adresa je verifikovana"
                            className="bi bi-patch-check-fill ms-1 brand"
                          ></i>
                        ))}
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        className="form-control form-control-sm standard-size"
                        type="email"
                        defaultValue={data.user_email}
                        onChange={(cur) => {
                          validateEmail(cur.target.value);
                          let newData = { ...data };
                          newData.user_email = cur.target.value;
                          setData(newData);
                        }}
                      />
                    </InputGroup>
                    {!validEmail ? (
                      <div className="red">E-mail adresa nije validna.</div>
                    ) : null}
                    <div className="mt-4">
                      <strong>Napomena:</strong>
                      <br />
                      <br />
                      E-mail adresa mora biti verifikovana od strane korisnika.
                      U suprotnom, korisnik neće moći da pristupi sistemu. Svaka
                      naknadna promena e-mail adrese zahteva ponovnu
                      verifikaciju.
                      <br />
                      <br />
                      Nakon unosa nove e-mail adrese ili promene e-mail adrese
                      korisnik će dobiti poruku sa verifikacionim linkom na novu
                      e-mail adresu. Verifikacioni link važi 3 dana.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="h-100 card card-small">
                  <div className="d-flex card-body p-4">
                    <h5 className="mb-3">Odobreni gradovi</h5>
                    {citiesList.map((city) => (
                      <Form.Check
                        key={city.city_id}
                        size="sm"
                        className="standard-size"
                        checked={
                          citiesApproved.indexOf(city.city_id) > -1
                            ? true
                            : false
                        }
                        onChange={(clicked) => {
                          if (clicked.target.checked === true) {
                            setCitiesApproved((current) => [
                              ...current,
                              city.city_id,
                            ]);
                          } else {
                            setCitiesApproved(
                              citiesApproved.filter(
                                (current) => current !== city.city_id
                              )
                            );
                          }
                        }}
                        type="checkbox"
                        label={city.name_sr}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="h-100 card card-small">
                  <div className="d-flex card-body p-4">
                    <h5 className="mb-3">Odobreni moduli</h5>
                    {modulesList.map((module) => (
                      <Form.Check
                        key={module.module_id}
                        size="sm"
                        className="standard-size"
                        checked={
                          modulesApproved.indexOf(module.module_id) > -1
                            ? true
                            : false
                        }
                        onChange={(clicked) => {
                          if (clicked.target.checked === true) {
                            setModulesApproved((current) => [
                              ...current,
                              module.module_id,
                            ]);
                          } else {
                            setModulesApproved(
                              modulesApproved.filter(
                                (current) => current !== module.module_id
                              )
                            );
                          }
                        }}
                        type="checkbox"
                        label={module.module_name}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <Loader loading={loading} />

      <div className="card card-small footer-overlay">
        <div className="card-body">
          <ToggleButton
            className="ms-2"
            size="sm"
            id="toggle-check"
            type="checkbox"
            disabled={data?.dummy === "1" ? true : false}
            variant={
              data?.active === "1" ? "outline-success" : "outline-danger"
            }
            checked={data?.active === "1" ? true : false}
            value="1"
            onChange={handleActive}
            title={data?.active === "1" ? "Aktivan" : "Nije aktivan"}
          >
            {data?.active === "1" ? (
              <i className="bi bi-toggle-on"></i>
            ) : (
              <i className="bi bi-toggle-off"></i>
            )}
          </ToggleButton>
          <Button
            className="ms-2 btn-primary"
            size="sm"
            onClick={handleSave}
            disabled={
              validEmail === false ||
              data?.user_name === "" ||
              data?.user_email === ""
                ? true
                : false
            }
          >
            Sačuvaj
            {savingData ? <i className="ms-1 fa fa-spinner fa-spin"></i> : null}
          </Button>
        </div>
      </div>

      <ErrorModal show={error} message={errorMessage}></ErrorModal>
    </>
  );
}
