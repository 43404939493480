import React, { useEffect, useState, useRef } from "react";
import { InputGroup, ToggleButton, Button } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ErrorModal from "../components/errorModal";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  useGoogleMap,
} from "@react-google-maps/api";
import Sortable from "sortablejs";
import DeleteModal from "../components/deleteModal";
import PreviewModal from "../components/locations/previewModal";
import Loader from "../components/loader";
import InfoModal from "../components/infoModal";
import ShareModal from "../components/shareModal";
import { lat2cir, cir2lat } from "../helpers/transcribe";

export default function LocationsAdd() {
  const [data, setData] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [contactTypes, setContactTypes] = useState([]);
  const [contactTypesById, setContactTypesById] = useState([]);
  const [groups, setGroups] = useState([]);
  const [subgroups, setSubgroups] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [photoLoading, setPhotoLoading] = useState(false);
  const [addImageModal, setAddImageModal] = useState(false);
  const [savingData, setSavingData] = useState(false);
  const [locationId, setLocationId] = useState("0");
  const [photoTextSr, setPhotoTextSr] = useState("");
  const [photoTextCp, setPhotoTextCp] = useState("");
  const [photoTextEn, setPhotoTextEn] = useState("");
  const [photoSource, setPhotoSource] = useState("");
  const [photoId, setPhotoId] = useState("");
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const moduleId = "41e01664ee9c9583e5b0d968b533effe";
  const [center, setCenter] = useState(null);
  const draggableMarker = useRef();
  const refLat = useRef();
  const refLng = useRef();
  const gridRef = useRef(null);
  const sortableJsRef = useRef(null);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedSubgroup, setSelectedSubgroup] = useState("0");
  const [editImageMetaModal, setEditImageMetaModal] = useState(false);
  const [deletePhotoModal, setDeletePhotoModal] = useState(false);
  const [deletePhotoTitle, setDeletePhotoTitle] = useState("");
  const [deletePhoto, setDeletePhoto] = useState("");
  const filesRef = useRef();
  const [filesLength, setFilesLength] = useState(0);
  const [progress, setProgress] = useState(0);
  const [editContactModal, setEditContactModal] = useState(false);
  const [addContactModal, setAddContactModal] = useState(false);
  const [contactTextSr, setContactTextSr] = useState("");
  const [contactTextCp, setContactTextCp] = useState("");
  const [contactTextEn, setContactTextEn] = useState("");
  const [contactType, setContactType] = useState("");
  const [contactId, setContactId] = useState("");
  const [contactContact, setContactContact] = useState("");
  const [contactContactOk, setContactContactOk] = useState(false);
  const contactRef = useRef();
  const [contactLoading, setContactLoading] = useState(false);
  const [deleteContactModal, setDeleteContactModal] = useState(false);
  const [deleteContactTitle, setDeleteContactTitle] = useState("");
  const [deleteContact, setDeleteContact] = useState("");
  const [locationModal, setLocationModal] = useState(false);
  const [saved, setSaved] = useState(false);
  const [errorToggle, setErrorToggle] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [shareBody, setShareBody] = useState("");
  const [shareSubject, setShareSubject] = useState("");
  const [shareMedia, setShareMedia] = useState("");
  const [shareUrl, setShareUrl] = useState("");

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        location_id: locationId,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_single_location.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(receivedData.data);
          setLocationId(receivedData.data.location_id);
          setContacts(receivedData.contacts);
          setContactTypes(receivedData.contact_types);
          setContactTypesById(receivedData.contact_types_id);
          setGroups(receivedData.groups);
          setSubgroups(receivedData.subgroups);
          setSelectedGroup(receivedData.groups[0].group_id);
          setPhotos(receivedData.photos);
          sessionStorage.setItem("photos", JSON.stringify(receivedData.photos));
          setTimeout(() => {
            setLoading(false);
            setAuthorized(true);
            setCenter({
              lat: parseFloat(receivedData.data.lat),
              lng: parseFloat(receivedData.data.lng),
            });
            receivedData.subgroups.map((subgroup) => {
              if (subgroup.subgroup_id === receivedData.data.subgroup_id) {
                setSelectedGroup(subgroup.group_id);
              }
            });
          }, 200);
          setTimeout(() => {
            sortableJsRef.current = new Sortable(gridRef.current, {
              animation: 150,
              onEnd: handleDragEnd,
            });
          }, 500);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  }, []);

  const handleDragEnd = () => {
    setPhotoLoading(true);
    let currentPhotos = JSON.parse(sessionStorage.getItem("photos"));
    let newOrder = [...gridRef.current.children].map((i) => i.dataset.id);
    let newData = Array();
    let order = 1;
    newOrder.map((i) => {
      currentPhotos.map((item) => {
        if (item.photo_id === i) {
          item.sort_order = order.toString();
          if (order === 1) {
            item.featured = "1";
          } else {
            item.featured = "0";
          }
          newData.push(item);
        }
      });
      order = order + 1;
    });
    sessionStorage.setItem("photos", JSON.stringify(newData));
    setPhotos(newData);
    saveSort(newData);
  };

  const saveSort = (newData) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
        photos: newData,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_sort_photos.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setTimeout(() => {
            setPhotoLoading(false);
          }, 200);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const handleSave = () => {
    setSavingData(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
        location: data,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_edit_single_location.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(receivedData.data);
          setTimeout(() => {
            setSavingData(false);
            setSaved(true);
          }, 200);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const uploadMultiple = (files) => {
    setProgress(0);
    setPhotoLoading(true);
    uploadImage(files, 0);
  };

  const uploadImage = (files, index) => {
    if (files[index]) {
      let totalPhotos;
      if (photos) {
        totalPhotos = photos.length;
      } else {
        totalPhotos = 0;
      }
      const form_data = new FormData();

      form_data.append("parent_id", data?.location_id);
      form_data.append("city_id", global.getCity());
      form_data.append("session_id", localStorage.getItem("authToken"));
      form_data.append("module", moduleId);
      form_data.append("text_sr", photoTextSr);
      form_data.append("text_cp", photoTextCp);
      form_data.append("text_en", photoTextEn);
      form_data.append("credit", photoSource);
      form_data.append("sort_order", totalPhotos + index + 1);

      if (totalPhotos + index + 1 === 1) {
        form_data.append("featured", "1");
      }
      form_data.append("photo", files[index]);

      fetch(
        process.env.REACT_APP_BACKEND_ROOT + "_private/_upload_multiple.php",
        {
          method: "POST",
          body: form_data,
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (responseData) {
          if (!responseData.response) {
            setProgress(100 * ((index + 1) / filesLength));
            uploadImage(files, index + 1);
          } else {
            setErrorMessage(responseData.response);
            setError(true);
          }
        });
    } else {
      finishUpload(index);
    }
  };

  const finishUpload = (index) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        location_id: locationId,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_photos.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setProgress(100 * ((index + 1) / filesLength));
          setTimeout(() => {
            setPhotos(receivedData);
            sessionStorage.setItem("photos", JSON.stringify(receivedData));
            setAddImageModal(false);
            setPhotoLoading(false);
            setPhotoTextSr("");
            setPhotoTextCp("");
            setPhotoTextEn("");
            setPhotoSource("");
            setFilesLength(0);
          }, 500);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const editImageMeta = (photo_id) => {
    setPhotoLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        photo_id: photo_id,
        text_sr: photoTextSr,
        text_cp: photoTextCp,
        text_en: photoTextEn,
        credit: photoSource,
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_edit_image_meta.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.response) {
          let newPhotos = Array();
          photos.map((item) => {
            if (item.photo_id === responseData.photo_id) {
              newPhotos.push(responseData);
            } else {
              newPhotos.push(item);
            }
          });
          setPhotos(newPhotos);
          sessionStorage.setItem("photos", JSON.stringify(newPhotos));
          setTimeout(() => {
            setPhotoLoading(false);
          }, 500);
          setTimeout(() => {
            setEditImageMetaModal(false);
          }, 600);
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  const handleActive = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        location_id: data?.location_id,
        active: data?.active,
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT +
        "_private/_toggle_location_state.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.response) {
          let newData = { ...data };
          newData.active = responseData.active;
          setData(newData);
          if (responseData.error) {
            setErrorToggle(true);
          }
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  const handleDeletePhoto = (photo_id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        photo_id: photo_id,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_delete_photo.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          let newPhotos = photos.filter((photo) => photo.photo_id !== photo_id);
          let newPhotosSorted = Array();
          newPhotos.map((item, i) => {
            if (i === 0) {
              item.featured = "1";
            } else {
              item.featured = "0";
            }
            item.sort_order = (i + 1).toString();
            newPhotosSorted.push(item);
          });

          setPhotos(newPhotosSorted);
          sessionStorage.setItem("photos", JSON.stringify(newPhotosSorted));
          saveSort(newPhotosSorted);
          setDeletePhoto("");
          setDeletePhotoTitle("");
          setDeletePhotoModal(false);
          if (newPhotosSorted.length < 1 && data?.active === "1") {
            handleActive();
          }
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const handleDeleteContact = (contact_id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        contact_id: contact_id,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_delete_contact.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          let newContacts = contacts.filter(
            (contact) => contact.contact_id !== contact_id
          );
          setContacts(newContacts);
          setDeleteContact("");
          setDeleteContactTitle("");
          setDeleteContactModal(false);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const saveContact = () => {
    setContactLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        location_id: data?.location_id,
        contact_id: contactId,
        contact_type: contactType,
        text_sr: contactTextSr,
        text_cp: contactTextCp,
        text_en: contactTextEn,
        contact: contactContact,
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_edit_contact.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.response) {
          setContacts(responseData);
          setContactLoading(false);
          setEditContactModal(false);
          setAddContactModal(false);
          setContactType("");
          setContactTextSr("");
          setContactTextCp("");
          setContactTextEn("");
          setContactContact("");
          setContactId("");
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  const checkContact = (contactType, contact, init) => {
    setContactContactOk(true);
    let type = contactTypesById[contactType].type;
    if (type == 2 || type == 3 || type == 9) {
      let newContact = contact.replace(/[^0-9+ -]/g, "");
      if (newContact.length < 12) setContactContactOk(false);
      setContactContact(newContact);
      if (!init) contactRef.current.value = newContact;
    } else if (type == 4) {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(contact)) {
        setContactContact(contact);
      } else {
        setContactContact(contact);
        setContactContactOk(false);
      }
    } else if (type == 5 || type == 6 || type == 7) {
      let newContact = contact.replace("http://", "");
      newContact = newContact.replace("https://", "");
      let re = new RegExp(
        "^(https?:\\/\\/)?" + // validate protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // validate fragment locator
      if (re.test(newContact)) {
        setContactContact(newContact);
        if (!init) contactRef.current.value = newContact;
      } else {
        setContactContact(newContact);
        if (!init) contactRef.current.value = newContact;
        setContactContactOk(false);
      }
    } else {
      if (contact.length < 2) setContactContactOk(false);
      else {
        setContactContact(contact);
      }
    }
  };

  const generateSeo = (language) => {
    let page = "";
    if (language === "sr") {
      page = "Lokacije";
    }
    if (language === "cp") {
      page = "Локације";
    }
    if (language === "en") {
      page = "Locations";
    }
    let newData = { ...data };
    newData.seo["seo_title_" + language] =
      data["name_" + language] +
      " - " +
      page +
      " | " +
      global.appName(language);
    newData.seo["seo_description_" + language] =
      data["text_" + language]
        .replace(/(<([^>]+)>)/gi, "")
        .replace(/(\r\n|\n|\r)/gm, "")
        .split(" ")
        .slice(0, 20)
        .join(" ") + "...";
    setData(newData);
  };

  return (
    <>
      <div className="container-lg pb-5">
        <div className="row">
          <div className="col-md mt-4 mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                <h3 className="mb-0">Lokacije / Nova lokacija</h3>
              </div>
            </div>
          </div>
        </div>
        {authorized && !loading && (
          <>
            <div className="row">
              <div className="col-md-5 mb-4">
                <div className="h-100 card card-small">
                  <div className="d-flex card-body p-4">
                    <h5 className="mb-3">Osnovni podaci</h5>
                    <div className="row">
                      <div className="col-6">
                        <Form.Label className="mb-0 standard-size">
                          Grupa
                        </Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Select
                            size="sm"
                            className="form-control form-control-sm standard-size"
                            defaultValue={selectedGroup}
                            onChange={(cur) => {
                              setSelectedGroup(cur.target.value);
                              setSelectedSubgroup("0");
                            }}
                          >
                            {groups.map((group) => (
                              <option
                                key={group.group_id}
                                value={group.group_id}
                              >
                                {group.name_sr}
                              </option>
                            ))}
                          </Form.Select>
                        </InputGroup>
                      </div>
                      <div className="col-6">
                        <Form.Label
                          className={
                            selectedSubgroup !== "0"
                              ? "mb-0 standard-size"
                              : "mb-0 standard-size red"
                          }
                        >
                          Podgrupa
                        </Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Select
                            size="sm"
                            className="form-control form-control-sm standard-size"
                            defaultValue={data?.subgroup_id}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.subgroup_id = cur.target.value;
                              setData(newData);
                              setSelectedSubgroup(cur.target.value);
                            }}
                          >
                            <option value="0">Izaberi podgrupu</option>
                            {subgroups.map(
                              (subgroup) =>
                                subgroup.group_id === selectedGroup && (
                                  <option
                                    key={subgroup.subgroup_id}
                                    value={subgroup.subgroup_id}
                                  >
                                    {subgroup.name_sr}
                                  </option>
                                )
                            )}
                          </Form.Select>
                        </InputGroup>
                      </div>
                    </div>

                    <Form.Label className="mb-1 standard-size">
                      Naziv lokacije
                    </Form.Label>
                    <div className="row">
                      <div
                        className={
                          data?.name_sr === "" ? "col-2 pt-1 red" : "pt-1 col-2"
                        }
                      >
                        Latinica
                      </div>
                      <div className="col-10">
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            value={data?.name_sr}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.name_sr = cur.target.value;
                              setData(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>
                    {global.getUseCP() && (
                      <div className="row">
                        <div
                          className={
                            data?.name_cp === ""
                              ? "col-2 pt-1 red"
                              : "pt-1 col-2"
                          }
                        >
                          Ćirilica
                        </div>
                        <div className="col-10">
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data?.name_cp}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.name_cp = cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div
                        className={
                          data?.name_en === "" ? "col-2 pt-1 red" : "pt-1 col-2"
                        }
                      >
                        Engleski
                      </div>
                      <div className="col-10">
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            value={data?.name_en}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.name_en = cur.target.value;
                              setData(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>

                    <Form.Label className="mb-1 standard-size">
                      Grad / adresa
                    </Form.Label>
                    <div className="row">
                      <div
                        className={
                          data?.city_sr === "" || data?.address_sr === ""
                            ? "col-2 pt-1 red"
                            : "col-2 pt-1"
                        }
                      >
                        Latinica
                      </div>
                      <div className="col-5">
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            value={data?.city_sr}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.city_sr = cur.target.value;
                              setData(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                      <div className="col-5">
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            value={data?.address_sr}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.address_sr = cur.target.value;
                              setData(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>
                    {global.getUseCP() && (
                      <div className="row">
                        <div
                          className={
                            data?.city_cp === "" || data?.address_cp === ""
                              ? "col-2 pt-1 red"
                              : "col-2 pt-1"
                          }
                        >
                          Ćirilica
                        </div>
                        <div className="col-5">
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data?.city_cp}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.city_cp = cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                        </div>
                        <div className="col-5">
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data?.address_cp}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.address_cp = cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div
                        className={
                          data?.city_en === "" || data?.address_en === ""
                            ? "col-2 pt-1 red"
                            : "col-2 pt-1"
                        }
                      >
                        Engleski
                      </div>
                      <div className="col-5">
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            value={data?.city_en}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.city_en = cur.target.value;
                              setData(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                      <div className="col-5">
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            value={data?.address_en}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.address_en = cur.target.value;
                              setData(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <Form.Label
                          className={
                            data?.lat !== ""
                              ? "mb-0 standard-size"
                              : "mb-0 standard-size red"
                          }
                        >
                          Geografska širina
                        </Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Control
                            ref={refLat}
                            className="form-control form-control-sm standard-size"
                            type="text"
                            defaultValue={data?.lat}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.lat = cur.target.value;
                              setData(newData);
                              if (
                                refLat.current.value !== "" &&
                                refLng.current.value !== ""
                              ) {
                                let newCenter = {
                                  lat: parseFloat(newData.lat),
                                  lng: parseFloat(newData.lng),
                                };
                                setCenter(newCenter);
                                map.panTo(newCenter);
                              }
                            }}
                          />
                        </InputGroup>
                      </div>
                      <div className="col-6">
                        <Form.Label
                          className={
                            data?.lng !== ""
                              ? "mb-0 standard-size"
                              : "mb-0 standard-size red"
                          }
                        >
                          Geografska dužina
                        </Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Control
                            ref={refLng}
                            className="form-control form-control-sm standard-size"
                            type="text"
                            defaultValue={data?.lng}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.lng = cur.target.value;
                              setData(newData);
                              if (
                                refLat.current.value !== "" &&
                                refLng.current.value !== ""
                              ) {
                                let newCenter = {
                                  lat: parseFloat(newData.lat),
                                  lng: parseFloat(newData.lng),
                                };
                                setCenter(newCenter);
                                map.panTo(newCenter);
                              }
                            }}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7 mb-4">
                <div className="h-100 card card-small">
                  <div className="d-flex card-body p-4">
                    <h5 className="mb-3">Lokacija na mapi</h5>
                    {data && isLoaded && (
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={15}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        options={{
                          //zoomControl: false,
                          streetViewControl: false,
                          mapTypeControl: true,
                          fullscreenControl: false,
                        }}
                      >
                        <Marker
                          ref={draggableMarker}
                          position={center}
                          animation={window.google.maps.Animation.DROP}
                          draggable={true}
                          onDragEnd={() => {
                            let position =
                              draggableMarker.current.marker.getPosition();
                            let newData = { ...data };
                            newData.lat = position.lat();
                            refLat.current.value = position.lat();
                            newData.lng = position.lng();
                            refLng.current.value = position.lng();
                            setData(newData);
                            let newCenter = {
                              lat: parseFloat(position.lat()),
                              lng: parseFloat(position.lng()),
                            };
                            setCenter(newCenter);
                            // map.panTo(newCenter);
                          }}
                        />
                      </GoogleMap>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mb-4">
                <div className="h-100 card card-small">
                  <div className="d-flex card-body p-4">
                    <div className="row">
                      <div className="col-7">
                        <h5 className="mb-3">
                          {global.getUseCP()
                            ? "Prateći tekst na srpskom jeziku (lat)"
                            : "Prateći tekst na srpskom jeziku"}
                        </h5>
                        <Form.Label
                          className={
                            data?.text_sr === ""
                              ? "mb-0 standard-size red"
                              : "mb-0 standard-size"
                          }
                        >
                          Tekst
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            rows={15}
                            as="textarea"
                            value={data?.text_sr}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.text_sr = cur.target.value;
                              setData(newData);
                            }}
                          />
                        </InputGroup>
                        {global.getUseCP() && (
                          <div className="col mt-3">
                            <Button
                              className="btn-primary"
                              size="sm"
                              title="Preslovi u ćirilicu"
                              disabled={data?.text_sr === "" ? true : false}
                              onClick={() => {
                                let newData = { ...data };
                                newData.text_cp = lat2cir(data?.text_sr);
                                setData(newData);
                              }}
                            >
                              {"Preslovi u ćirilicu"}
                            </Button>
                          </div>
                        )}
                      </div>
                      <div className="col-5">
                        <h5 className="mb-3">
                          {global.getUseCP()
                            ? "SEO na srpskom jeziku (lat)"
                            : "SEO na srpskom jeziku"}
                        </h5>
                        <Form.Label
                          className={
                            data?.seo?.seo_title_sr === ""
                              ? "mb-0 standard-size red"
                              : "mb-0 standard-size"
                          }
                        >
                          SEO naslov
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            value={data?.seo?.seo_title_sr}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.seo.seo_title_sr = cur.target.value;
                              setData(newData);
                            }}
                          />
                        </InputGroup>
                        <Form.Label
                          className={
                            data?.seo?.seo_description_sr === ""
                              ? "mb-0 standard-size red"
                              : "mb-0 standard-size"
                          }
                        >
                          SEO opis
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            rows={12}
                            as="textarea"
                            value={data?.seo?.seo_description_sr}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.seo.seo_description_sr = cur.target.value;
                              setData(newData);
                            }}
                          />
                        </InputGroup>
                        <div className="col mt-3">
                          <Button
                            className="btn-primary"
                            size="sm"
                            title="Generiši SEO automatski"
                            disabled={
                              data?.name_sr === "" || data?.text_sr === ""
                                ? true
                                : false
                            }
                            onClick={() => {
                              generateSeo("sr");
                            }}
                          >
                            {"Generiši SEO automatski"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {global.getUseCP() && (
              <div className="row">
                <div className="col-md-12 mb-4">
                  <div className="h-100 card card-small">
                    <div className="d-flex card-body p-4">
                      <div className="row">
                        <div className="col-7">
                          <h5 className="mb-3">
                            Prateći tekst na srpskom jeziku (ćir)
                          </h5>
                          <Form.Label
                            className={
                              data?.text_cp === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            Tekst
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={15}
                              as="textarea"
                              value={data?.text_cp}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.text_cp = cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <div className="col mt-3">
                            <Button
                              className="btn-primary"
                              size="sm"
                              title="Preslovi u latinicu"
                              disabled={data?.text_cp === "" ? true : false}
                              onClick={() => {
                                let newData = { ...data };
                                newData.text_sr = cir2lat(data?.text_cp);
                                setData(newData);
                              }}
                            >
                              {"Preslovi u latinicu"}
                            </Button>
                          </div>
                        </div>
                        <div className="col-5">
                          <h5 className="mb-3">SEO na srpskom jeziku (ćir)</h5>
                          <Form.Label
                            className={
                              data?.seo?.seo_title_cp === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO naslov
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data?.seo?.seo_title_cp}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.seo.seo_title_cp = cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <Form.Label
                            className={
                              data?.seo?.seo_description_cp === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO opis
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={12}
                              as="textarea"
                              value={data?.seo?.seo_description_cp}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.seo.seo_description_cp =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <div className="col mt-3">
                            <Button
                              className="btn-primary"
                              size="sm"
                              title="Generiši SEO automatski"
                              disabled={
                                data?.name_cp === "" || data?.text_cp === ""
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                generateSeo("cp");
                              }}
                            >
                              {"Generiši SEO automatski"}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-md-12 mb-4">
                <div className="h-100 card card-small">
                  <div className="d-flex card-body p-4">
                    <div className="row">
                      <div className="col-7">
                        <h5 className="mb-3">
                          Prateći tekst na engleskom jeziku
                        </h5>
                        <Form.Label
                          className={
                            data?.text_en === ""
                              ? "mb-0 standard-size red"
                              : "mb-0 standard-size"
                          }
                        >
                          Tekst
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            rows={15}
                            as="textarea"
                            value={data?.text_en}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.text_en = cur.target.value;
                              setData(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                      <div className="col-5">
                        <h5 className="mb-3">SEO na engleskom jeziku</h5>
                        <Form.Label
                          className={
                            data?.seo?.seo_title_en === ""
                              ? "mb-0 standard-size red"
                              : "mb-0 standard-size"
                          }
                        >
                          SEO naslov
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            value={data?.seo?.seo_title_en}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.seo.seo_title_en = cur.target.value;
                              setData(newData);
                            }}
                          />
                        </InputGroup>
                        <Form.Label
                          className={
                            data?.seo?.seo_description_en === ""
                              ? "mb-0 standard-size red"
                              : "mb-0 standard-size"
                          }
                        >
                          SEO opis
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            rows={12}
                            as="textarea"
                            value={data?.seo?.seo_description_en}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.seo.seo_description_en = cur.target.value;
                              setData(newData);
                            }}
                          />
                        </InputGroup>
                        <div className="col mt-3">
                          <Button
                            className="btn-primary"
                            size="sm"
                            title="Generiši SEO automatski"
                            disabled={
                              data?.name_en === "" || data?.text_en === ""
                                ? true
                                : false
                            }
                            onClick={() => {
                              generateSeo("en");
                            }}
                          >
                            {"Generiši SEO automatski"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md mb-4">
                <div className="h-100 card card-small">
                  <div className="d-flex card-body p-4">
                    <div className="row mb-3">
                      <div className="col-md-8">
                        <h5
                          className={
                            (photos && photos.length === 0) || !photos
                              ? "mb-0 red"
                              : "mb-0"
                          }
                        >
                          Fotografije
                          {photoLoading ? (
                            <i className="ms-1 fa fa-spinner fa-spin"></i>
                          ) : null}
                        </h5>
                      </div>
                      <div className="col-md-4 text-right">
                        <button
                          className="icon-16 grey-blue ms-2"
                          title="Dodaj fotografiju"
                          onClick={() => {
                            setAddImageModal(true);
                            setPhotoTextSr(data?.name_sr);
                            setPhotoTextCp(data?.name_cp);
                            setPhotoTextEn(data?.name_en);
                          }}
                        >
                          <i className="bi bi-plus-circle-fill"></i>
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div ref={gridRef} id="gridDemo">
                        {photos &&
                          photos.map((content, i) => (
                            <div
                              key={content.photo_id}
                              data-id={content.photo_id}
                              className="grid-square"
                            >
                              <img
                                width="100%"
                                src={
                                  process.env.REACT_APP_RESOURCES +
                                  content.path +
                                  "small/" +
                                  content.filename +
                                  "?" +
                                  Date.now()
                                }
                                alt={content.text_sr}
                              />
                              <div className="overlay-icons">
                                <button
                                  className="icon-16 grey-blue ms-2"
                                  title="Uredi fotografiju"
                                  onClick={() => {
                                    setEditImageMetaModal(true);
                                    setPhotoId(i);
                                    setPhotoTextEn(content.text_en);
                                    setPhotoTextSr(content.text_sr);
                                    setPhotoTextCp(content.text_cp);
                                    setPhotoSource(content.credit);
                                  }}
                                >
                                  <i className="bi bi-pencil-fill"></i>
                                </button>
                                <button
                                  className="icon-16 grey-red ms-2"
                                  title="Obriši fotografiju"
                                  onClick={() => {
                                    setDeletePhoto(content.photo_id);
                                    setDeletePhotoTitle(content.text_sr);
                                    setDeletePhotoModal(true);
                                  }}
                                >
                                  <i className="bi bi-trash3-fill"></i>
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md mb-4">
                <div className="h-100 card card-small">
                  <div className="d-flex card-body p-4">
                    <div className="row mb-3">
                      <div className="col-md-8">
                        <h5 className="mb-0">Kontakt informacije</h5>
                      </div>
                      <div className="col-md-4 text-right">
                        <button
                          className="icon-16 grey-blue ms-2"
                          title="Dodaj kontakt"
                          onClick={() => {
                            setEditContactModal(true);
                            setContactId("0");
                            setContactTextSr("");
                            setContactTextCp("");
                            setContactTextEn("");
                            setContactType(contactTypes[0].contact_type_id);
                            checkContact(
                              contactTypes[0].contact_type_id,
                              "",
                              true
                            );
                            setContactContact("");
                          }}
                        >
                          <i className="bi bi-plus-circle-fill"></i>
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      {contacts &&
                        contacts.map((contact) => (
                          <div
                            className="col-md-4 mb-4"
                            key={contact.contact_id}
                          >
                            <div className="row">
                              <div className="col-md">
                                <div className="group-item">
                                  <div className="row ms-0 me-0 w-100">
                                    <div className="col ps-0 pe-0">
                                      <div className="group-item-inner">
                                        <div className="group-item-inner-text">
                                          <div className="group-item-inner-text-title">
                                            {contact.text_sr}
                                          </div>
                                          <div className="group-item-inner-text-text">
                                            {contact.contact}
                                          </div>
                                        </div>
                                        <div className="group-item-inner-image">
                                          <img
                                            src={
                                              process.env.REACT_APP_RESOURCES +
                                              "ui/" +
                                              contactTypesById[
                                                contact.contact_type_id
                                              ].filename
                                            }
                                            alt={contact.text_sr}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md overlay-icons text-center">
                                <button
                                  className="icon-16 grey-blue ms-2"
                                  title="Uredi kontakt"
                                  onClick={() => {
                                    setEditContactModal(true);
                                    setContactId(contact.contact_id);
                                    setContactTextSr(contact.text_sr);
                                    setContactTextCp(contact.text_cp);
                                    setContactTextEn(contact.text_en);
                                    setContactType(contact.contact_type_id);
                                    checkContact(
                                      contact.contact_type_id,
                                      contact.contact,
                                      true
                                    );
                                    setContactContact(contact.contact);
                                  }}
                                >
                                  <i className="bi bi-pencil-fill"></i>
                                </button>
                                <button
                                  className="icon-16 grey-red ms-2"
                                  title="Obriši fotografiju"
                                  onClick={() => {
                                    setDeleteContact(contact.contact_id);
                                    setDeleteContactTitle(contact.contact);
                                    setDeleteContactModal(true);
                                  }}
                                >
                                  <i className="bi bi-trash3-fill"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <Loader loading={loading} />

      <div className="card card-small footer-overlay">
        <div className="card-body">
          <ToggleButton
            className="ms-2"
            size="sm"
            id="toggle-check"
            type="checkbox"
            disabled={!saved}
            variant={
              data?.active === "1" ? "outline-success" : "outline-danger"
            }
            checked={data?.active === "1" ? true : false}
            value="1"
            onChange={handleActive}
            title={data?.active === "1" ? "Objavljeno" : "Nije objavljeno"}
          >
            {data?.active === "1" ? (
              <i className="bi bi-toggle-on"></i>
            ) : (
              <i className="bi bi-toggle-off"></i>
            )}
          </ToggleButton>
          <Button
            className="ms-2 btn-primary"
            size="sm"
            title="Podeli"
            disabled={data?.active === "1" ? false : true}
            onClick={() => {
              let subgroupStub;
              let groupStub;
              let groupId;
              subgroups.map((subgroup) => {
                if (subgroup.subgroup_id === data?.subgroup_id) {
                  subgroupStub = subgroup.stub;
                  groupId = subgroup.group_id;
                }
              });
              groups.map((group) => {
                if (group.group_id === groupId) {
                  groupStub = group.stub;
                }
              });
              setShareBody(data?.text_sr);
              setShareSubject(data?.name_sr);
              setShareMedia(
                process.env.REACT_APP_RESOURCES +
                  photos[0].path +
                  "large/" +
                  photos[0].filename
              );
              setShareUrl(
                global.getCityUrl() +
                  groupStub +
                  "/" +
                  subgroupStub +
                  "/" +
                  data?.stub
              );
              setShowShare(true);
            }}
          >
            <i className="bi bi-share-fill"></i>
          </Button>
          <Button
            className="ms-2 btn-primary"
            size="sm"
            title="Pregled lokacije"
            disabled={photos?.length === 0 || !photos || !saved}
            onClick={() => setLocationModal(true)}
          >
            <i className="bi bi-eye-fill"></i>
          </Button>
          <Button
            className="ms-2 btn-primary"
            size="sm"
            onClick={handleSave}
            disabled={
              selectedGroup === "" ||
              selectedSubgroup === "0" ||
              data?.name_sr === "" ||
              data?.name_en === "" ||
              data?.city_sr === "" ||
              data?.city_en === "" ||
              data?.address_sr === "" ||
              data?.address_en === "" ||
              data?.lat === "" ||
              data?.lng === "" ||
              data?.text_sr === "" ||
              data?.text_en === "" ||
              photos?.length === 0 ||
              !photos ||
              data?.seo?.seo_title_sr === "" ||
              data?.seo?.seo_title_en === "" ||
              data?.seo?.seo_description_sr === "" ||
              data?.seo?.seo_description_en === "" ||
              (global.getUseCP() && data?.name_cp === "") ||
              (global.getUseCP() && data?.text_cp === "") ||
              (global.getUseCP() && data?.city_cp === "") ||
              (global.getUseCP() && data?.address_cp === "") ||
              (global.getUseCP() && data?.seo?.seo_title_cp === "") ||
              (global.getUseCP() && data?.seo?.seo_description_cp === "")
                ? true
                : false
            }
          >
            Sačuvaj
            {savingData ? <i className="ms-1 fa fa-spinner fa-spin"></i> : null}
          </Button>
        </div>
      </div>

      {!loading && (
        <>
          <Modal
            show={addImageModal}
            onHide={() => {
              setAddImageModal(false);
              setPhotoTextSr("");
              setPhotoTextCp("");
              setPhotoTextEn("");
              setPhotoSource("");
              setFilesLength(0);
            }}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Dodaj fotografiju</Modal.Title>
            </Modal.Header>
            {photoLoading && (
              <Modal.Body>
                <ProgressBar animated now={progress} />
              </Modal.Body>
            )}
            {!photoLoading && (
              <>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group className="mb-3">
                        <Form.Control
                          ref={filesRef}
                          type="file"
                          id="upload_image"
                          name="sample_image"
                          accept=".jpg, .jpeg, .png"
                          multiple
                          onChange={(e) => {
                            setFilesLength(e.target.files.length);
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <Form.Label
                        className={
                          photoTextSr === ""
                            ? "mb-0 standard-size red"
                            : "mb-0 standard-size"
                        }
                      >
                        {global.getUseCP()
                          ? "Opis na srpskom jeziku (lat)"
                          : "Opis na srpskom jeziku"}
                      </Form.Label>
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          value={photoTextSr}
                          onChange={(cur) => {
                            setPhotoTextSr(cur.target.value);
                          }}
                        />
                      </InputGroup>

                      {global.getUseCP() && (
                        <>
                          <Form.Label
                            className={
                              photoTextCp === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            {global.getUseCP()
                              ? "Opis na srpskom jeziku (ćir)"
                              : "Opis na srpskom jeziku"}
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={photoTextCp}
                              onChange={(cur) => {
                                setPhotoTextCp(cur.target.value);
                              }}
                            />
                          </InputGroup>
                        </>
                      )}

                      <Form.Label
                        className={
                          photoTextEn === ""
                            ? "mb-0 standard-size red"
                            : "mb-0 standard-size"
                        }
                      >
                        Opis na engleskom jeziku
                      </Form.Label>
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          value={photoTextEn}
                          onChange={(cur) => {
                            setPhotoTextEn(cur.target.value);
                          }}
                        />
                      </InputGroup>
                      <Form.Label className="mb-0 standard-size">
                        Izvor fotografije
                      </Form.Label>
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          onChange={(cur) => {
                            setPhotoSource(cur.target.value);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setAddImageModal(false);
                      setPhotoTextSr("");
                      setPhotoTextCp("");
                      setPhotoTextEn("");
                      setPhotoSource("");
                      setFilesLength(0);
                    }}
                  >
                    Zatvori
                  </Button>
                  <Button
                    disabled={
                      photoTextSr === "" ||
                      photoTextEn === "" ||
                      filesLength === 0 ||
                      (global.getUseCP() && photoTextCp === "")
                        ? true
                        : false
                    }
                    variant="primary"
                    onClick={() => {
                      uploadMultiple(filesRef.current.files);
                    }}
                  >
                    Sačuvaj
                  </Button>
                </Modal.Footer>
              </>
            )}
          </Modal>
          {photoId !== "" && (
            <Modal
              show={editImageMetaModal}
              onHide={() => {
                setEditImageMetaModal(false);
                setPhotoId("");
                setPhotoSource("");
                setPhotoTextEn("");
                setPhotoTextSr("");
                setPhotoTextCp("");
              }}
              backdrop="static"
              keyboard={false}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Uredi fotografiju</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">
                    <img
                      className="mb-2"
                      width="100%"
                      src={
                        process.env.REACT_APP_RESOURCES +
                        photos[photoId].path +
                        "medium/" +
                        photos[photoId].filename +
                        "?" +
                        Date.now()
                      }
                      alt={photos[photoId].text_sr}
                    />
                  </div>
                  <div className="col-md-12">
                    <Form.Label
                      className={
                        photoTextSr === ""
                          ? "mb-0 standard-size red"
                          : "mb-0 standard-size"
                      }
                    >
                      {global.getUseCP()
                        ? "Opis na srpskom jeziku (lat)"
                        : "Opis na srpskom jeziku"}
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        className="form-control form-control-sm standard-size"
                        type="text"
                        defaultValue={photos[photoId].text_sr}
                        onChange={(cur) => {
                          setPhotoTextSr(cur.target.value);
                        }}
                      />
                    </InputGroup>

                    {global.getUseCP() && (
                      <>
                        <Form.Label
                          className={
                            photoTextCp === ""
                              ? "mb-0 standard-size red"
                              : "mb-0 standard-size"
                          }
                        >
                          {global.getUseCP()
                            ? "Opis na srpskom jeziku (ćir)"
                            : "Opis na srpskom jeziku"}
                        </Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            defaultValue={photos[photoId].text_cp}
                            onChange={(cur) => {
                              setPhotoTextCp(cur.target.value);
                            }}
                          />
                        </InputGroup>
                      </>
                    )}

                    <Form.Label
                      className={
                        photoTextEn === ""
                          ? "mb-0 standard-size red"
                          : "mb-0 standard-size"
                      }
                    >
                      Opis na engleskom jeziku
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        className="form-control form-control-sm standard-size"
                        type="text"
                        defaultValue={photos[photoId].text_en}
                        onChange={(cur) => {
                          setPhotoTextEn(cur.target.value);
                        }}
                      />
                    </InputGroup>
                    <Form.Label className="mb-0 standard-size">
                      Izvor fotografije
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        className="form-control form-control-sm standard-size"
                        type="text"
                        defaultValue={photos[photoId].credit}
                        onChange={(cur) => {
                          setPhotoSource(cur.target.value);
                        }}
                      />
                    </InputGroup>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setEditImageMetaModal(false);
                    setPhotoId("");
                    setPhotoSource("");
                    setPhotoTextEn("");
                    setPhotoTextSr("");
                    setPhotoTextCp("");
                  }}
                >
                  Zatvori
                </Button>
                <Button
                  disabled={
                    photoTextSr === "" ||
                    photoTextEn === "" ||
                    (global.getUseCP() && photoTextCp === "")
                      ? true
                      : false
                  }
                  variant="primary"
                  onClick={() => {
                    editImageMeta(photos[photoId].photo_id);
                  }}
                >
                  Sačuvaj
                  {photoLoading ? (
                    <i className="ms-1 fa fa-spinner fa-spin"></i>
                  ) : null}
                </Button>
              </Modal.Footer>
            </Modal>
          )}
          <DeleteModal
            show={deletePhotoModal}
            close={() => {
              setDeletePhotoModal(false);
            }}
            title="Brisanje fotografije"
            message="Obrisati fotografiju"
            name={deletePhotoTitle}
            action={() => {
              handleDeletePhoto(deletePhoto);
            }}
          ></DeleteModal>
          <DeleteModal
            show={deleteContactModal}
            close={() => {
              setDeleteContactModal(false);
            }}
            title="Brisanje kontakta"
            message="Obrisati kontakt"
            name={deleteContactTitle}
            action={() => {
              handleDeleteContact(deleteContact);
            }}
          ></DeleteModal>
          <Modal
            show={editContactModal}
            onHide={() => {
              setEditContactModal(false);
              setContactType("");
              setContactTextSr("");
              setContactTextCp("");
              setContactTextEn("");
              setContactContact("");
              setContactId("");
            }}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Uredi kontakt</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <Form.Label className="mb-0 standard-size">
                    Vrsta kontakta
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Select
                      size="sm"
                      className="form-control form-control-sm standard-size"
                      defaultValue={contactType}
                      onChange={(cur) => {
                        setContactType(cur.target.value);
                        checkContact(cur.target.value, contactContact, false);
                      }}
                    >
                      {contactTypes.map((contactType) => (
                        <option
                          key={contactType.contact_type_id}
                          value={contactType.contact_type_id}
                        >
                          {contactType.name_sr}
                        </option>
                      ))}
                    </Form.Select>
                  </InputGroup>
                  <Form.Label
                    className={
                      contactTextSr === ""
                        ? "mb-0 standard-size red"
                        : "mb-0 standard-size"
                    }
                  >
                    {global.getUseCP()
                      ? "Naziv na srpskom jeziku (lat)"
                      : "Naziv na srpskom jeziku"}
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      className="form-control form-control-sm standard-size"
                      type="text"
                      value={contactTextSr}
                      onChange={(cur) => {
                        setContactTextSr(cur.target.value);
                      }}
                    />
                  </InputGroup>

                  {global.getUseCP() && (
                    <>
                      <Form.Label
                        className={
                          contactTextCp === ""
                            ? "mb-0 standard-size red"
                            : "mb-0 standard-size"
                        }
                      >
                        {global.getUseCP()
                          ? "Naziv na srpskom jeziku (ćir)"
                          : "Naziv na srpskom jeziku"}
                      </Form.Label>
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          value={contactTextCp}
                          onChange={(cur) => {
                            setContactTextCp(cur.target.value);
                          }}
                        />
                      </InputGroup>
                    </>
                  )}

                  <Form.Label
                    className={
                      contactTextEn === ""
                        ? "mb-0 standard-size red"
                        : "mb-0 standard-size"
                    }
                  >
                    Naziv na engleskom jeziku
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      className="form-control form-control-sm standard-size"
                      type="text"
                      value={contactTextEn}
                      onChange={(cur) => {
                        setContactTextEn(cur.target.value);
                      }}
                    />
                  </InputGroup>
                  <Form.Label
                    className={
                      !contactContactOk
                        ? "mb-0 standard-size red"
                        : "mb-0 standard-size"
                    }
                  >
                    Kontakt
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      className="form-control form-control-sm standard-size"
                      type="text"
                      defaultValue={contactContact}
                      ref={contactRef}
                      onKeyUp={(cur) => {
                        checkContact(contactType, cur.target.value, false);
                      }}
                    />
                  </InputGroup>
                  {contactType !== "" &&
                    contactTypesById[contactType].description && (
                      <div>
                        <i className="bi bi-exclamation-triangle-fill red"></i>{" "}
                        {contactTypesById[contactType].description}
                      </div>
                    )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setEditContactModal(false);
                  setContactType("");
                  setContactTextSr("");
                  setContactTextCp("");
                  setContactTextEn("");
                  setContactContact("");
                  setContactId("");
                }}
              >
                Zatvori
              </Button>
              <Button
                disabled={
                  contactTextSr === "" ||
                  contactTextEn === "" ||
                  !contactContactOk ||
                  (global.getUseCP() && contactTextCp === "")
                    ? true
                    : false
                }
                variant="primary"
                onClick={() => {
                  saveContact();
                }}
              >
                Sačuvaj
                {contactLoading ? (
                  <i className="ms-1 fa fa-spinner fa-spin"></i>
                ) : null}
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={addContactModal}
            onHide={() => {
              setAddContactModal(false);
              setContactType("");
              setContactTextSr("");
              setContactTextCp("");
              setContactTextEn("");
              setContactContact("");
              setContactId("");
            }}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Dodaj kontakt</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <Form.Label className="mb-0 standard-size">
                    Vrsta kontakta
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Select
                      size="sm"
                      className="form-control form-control-sm standard-size"
                      defaultValue={contactType}
                      onChange={(cur) => {
                        setContactType(cur.target.value);
                        checkContact(cur.target.value, contactContact, false);
                      }}
                    >
                      {contactTypes.map((contactType) => (
                        <option
                          key={contactType.contact_type_id}
                          value={contactType.contact_type_id}
                        >
                          {contactType.name_sr}
                        </option>
                      ))}
                    </Form.Select>
                  </InputGroup>
                  <Form.Label
                    className={
                      contactTextSr === ""
                        ? "mb-0 standard-size red"
                        : "mb-0 standard-size"
                    }
                  >
                    {global.getUseCP()
                      ? "Naziv na srpskom jeziku (lat)"
                      : "Naziv na srpskom jeziku"}
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      className="form-control form-control-sm standard-size"
                      type="text"
                      value={contactTextSr}
                      onChange={(cur) => {
                        setContactTextSr(cur.target.value);
                      }}
                    />
                  </InputGroup>

                  {global.getUseCP() && (
                    <>
                      <Form.Label
                        className={
                          contactTextCp === ""
                            ? "mb-0 standard-size red"
                            : "mb-0 standard-size"
                        }
                      >
                        {global.getUseCP()
                          ? "Naziv na srpskom jeziku (ćir)"
                          : "Naziv na srpskom jeziku"}
                      </Form.Label>
                      <InputGroup className="mb-2">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="text"
                          value={contactTextCp}
                          onChange={(cur) => {
                            setContactTextCp(cur.target.value);
                          }}
                        />
                      </InputGroup>
                    </>
                  )}

                  <Form.Label
                    className={
                      contactTextEn === ""
                        ? "mb-0 standard-size red"
                        : "mb-0 standard-size"
                    }
                  >
                    Naziv na engleskom jeziku
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      className="form-control form-control-sm standard-size"
                      type="text"
                      value={contactTextEn}
                      onChange={(cur) => {
                        setContactTextEn(cur.target.value);
                      }}
                    />
                  </InputGroup>
                  <Form.Label
                    className={
                      !contactContactOk
                        ? "mb-0 standard-size red"
                        : "mb-0 standard-size"
                    }
                  >
                    Kontakt
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      className="form-control form-control-sm standard-size"
                      type="text"
                      defaultValue={contactContact}
                      ref={contactRef}
                      onKeyUp={(cur) => {
                        checkContact(contactType, cur.target.value, false);
                      }}
                    />
                  </InputGroup>
                  {contactType !== "" &&
                    contactTypesById[contactType].description && (
                      <div>
                        <i className="bi bi-exclamation-triangle-fill red"></i>{" "}
                        {contactTypesById[contactType].description}
                      </div>
                    )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setAddContactModal(false);
                  setContactType("");
                  setContactTextSr("");
                  setContactTextCp("");
                  setContactTextEn("");
                  setContactContact("");
                  setContactId("");
                }}
              >
                Zatvori
              </Button>
              <Button
                disabled={
                  contactTextSr === "" ||
                  contactTextEn === "" ||
                  !contactContactOk ||
                  (global.getUseCP() && contactTextCp === "")
                    ? true
                    : false
                }
                variant="primary"
                onClick={() => {
                  saveContact();
                }}
              >
                Sačuvaj
                {contactLoading ? (
                  <i className="ms-1 fa fa-spinner fa-spin"></i>
                ) : null}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      {!loading && (
        <PreviewModal
          show={locationModal}
          onClosePreview={() => {
            setLocationModal(false);
          }}
          data={data}
          loading={loading}
          photos={photos}
          contacts={contacts}
          contactTypesById={contactTypesById}
        ></PreviewModal>
      )}
      <ErrorModal show={error} message={errorMessage}></ErrorModal>
      <InfoModal
        show={errorToggle}
        title="Greška"
        message="Lokacija ne može biti aktivirana jer ne ispunjava sve tehničke zahteve."
        close={() => {
          setErrorToggle(false);
        }}
      ></InfoModal>
      <ShareModal
        show={showShare}
        onCloseShare={() => {
          setShowShare(false);
        }}
        body={shareBody}
        subject={shareSubject}
        media={shareMedia}
        url={shareUrl}
      />
    </>
  );
}
