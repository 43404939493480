import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

export default function PreviewModal(props) {
  const [lang, setLang] = useState("sr");
  const [internalData, setInternalData] = useState([]);
  const {
    show,
    onClosePreview,
    loading,
    data,
    photos,
    contacts,
    contactTypesById,
  } = props;

  // useEffect(() => {
  //   if (data && photos) {
  //     let newData = { ...data };
  //     newData.filename = photos[0].filename;
  //     newData.path = photos[0].path;
  //     setInternalData(newData);
  //   }
  // }, [data, photos]);

  const closePreview = () => {
    onClosePreview();
    setLang("sr");
  };

  return (
    <>
      {!loading && show && (
        <Modal
          show={show}
          onHide={closePreview}
          onShow={() => {
            let newData = { ...data };
            if (photos) {
              newData.filename = photos[0].filename;
              newData.path = photos[0].path;
            }
            setInternalData(newData);
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Pregled lokacije</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row-md">
              <div className="col-md mb-2">
                <Button
                  variant={
                    lang === "sr" ? "primary me-2" : "outline-primary me-2"
                  }
                  size="sm"
                  onClick={() => {
                    setLang("sr");
                  }}
                >
                  {global.getUseCP() ? "Srpski (lat)" : "Srpski"}
                </Button>
                {global.getUseCP() && (
                  <Button
                    variant={
                      lang === "cp" ? "primary me-2" : "outline-primary me-2"
                    }
                    size="sm"
                    onClick={() => {
                      setLang("cp");
                    }}
                  >
                    Srpski (ćir)
                  </Button>
                )}
                <Button
                  variant={lang === "en" ? "primary" : "outline-primary"}
                  size="sm"
                  onClick={() => {
                    setLang("en");
                  }}
                >
                  Engleski
                </Button>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md">
                <div className="single-object-header">
                  {internalData.path && internalData.filename && (
                    <img
                      src={
                        process.env.REACT_APP_RESOURCES +
                        internalData.path +
                        "large/" +
                        internalData.filename +
                        "?" +
                        Date.now()
                      }
                      alt={internalData["name_" + lang]}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row g-3 mb-3 h-scroll scroll-snap-parent">
              {!loading &&
                photos &&
                photos.length > 1 &&
                photos.map((photo) => (
                  <div key={photo.photo_id} className="col-3 scroll-snap-child">
                    <div
                      onClick={() => {
                        let location = { ...internalData };
                        location.filename = photo.filename;
                        location.path = photo.path;
                        setInternalData(location);
                      }}
                      className="gallery-photo"
                    >
                      <img
                        src={
                          process.env.REACT_APP_RESOURCES +
                          photo.path +
                          "small/" +
                          photo.filename +
                          "?" +
                          Date.now()
                        }
                        alt={photo["name_" + lang]}
                      />
                    </div>
                  </div>
                ))}
            </div>
            <div className="row g-3 mb-3">
              <div className="col-md">
                <div className="text">{internalData["text_" + lang]}</div>
              </div>
            </div>
            <div className="row g-0 subheader-wrap mb-3">
              <div className="col-md">
                <div className="header-inner">
                  <div className="header-inner-left">
                    <h4 className="subheader-title mb-0 p-2">
                      {lang === "sr"
                        ? "Kontakt informacije"
                        : lang === "cp"
                        ? "Контакт информације"
                        : "Contact information"}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-4" key="adresa-1">
                <div className="row">
                  <div className="col-md">
                    <div className="group-item">
                      <div className="row ms-0 me-0 w-100">
                        <div className="col ps-0 pe-0">
                          <div className="group-item-inner">
                            <div className="group-item-inner-text">
                              <div className="group-item-inner-text-title">
                                {internalData["city_" + lang]}
                              </div>
                              <div className="group-item-inner-text-text">
                                {internalData["address_" + lang]}
                              </div>
                            </div>
                            <div className="group-item-inner-image">
                              <img
                                src={
                                  process.env.REACT_APP_RESOURCES +
                                  "ui/address.svg"
                                }
                                alt={internalData["address_" + lang]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {contacts &&
                contacts.map((contact) => (
                  <div className="col-md-6 mb-4" key={contact.contact_id}>
                    <div className="row">
                      <div className="col-md">
                        <div className="group-item">
                          <div className="row ms-0 me-0 w-100">
                            <div className="col ps-0 pe-0">
                              <div className="group-item-inner">
                                <div className="group-item-inner-text">
                                  <div className="group-item-inner-text-title">
                                    {contact["text_" + lang]}
                                  </div>
                                  <div className="group-item-inner-text-text">
                                    {contact.contact}
                                  </div>
                                </div>
                                <div className="group-item-inner-image">
                                  <img
                                    src={
                                      process.env.REACT_APP_RESOURCES +
                                      "ui/" +
                                      contactTypesById[contact.contact_type_id]
                                        .filename
                                    }
                                    alt={contact.text_sr}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closePreview}>
              Zatvori
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
