import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

export default function PreviewModal(props) {
  const [lang, setLang] = useState("sr");
  const [locale, setLocale] = useState("sr-RS");
  const { show, onClosePreview, data, loading, postType } = props;

  function formatDate(date, variant) {
    if (variant === "date") {
      let options = { year: "numeric", month: "2-digit", day: "2-digit" };
      let dateTime = new Date(parseInt(date));
      return dateTime.toLocaleDateString(locale, options);
    }
    if (variant === "time") {
      let options = { hour: "numeric", minute: "2-digit", second: undefined };
      let dateTime = new Date(parseInt(date));
      return dateTime.toLocaleTimeString(locale, options);
    }
    if (variant === "dateTime") {
      let options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      let dateTime = new Date(parseInt(date));
      return dateTime.toLocaleDateString(locale, options);
    }
  }

  const closePreview = () => {
    onClosePreview();
    setLang("sr");
    setLocale("sr-RS");
  };

  return (
    <>
      {!loading && (
        <Modal
          show={show}
          onHide={closePreview}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Pregled objave</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row-md">
              <div className="col-md mb-2">
                <Button
                  variant={
                    lang === "sr" ? "primary me-2" : "outline-primary me-2"
                  }
                  size="sm"
                  onClick={() => {
                    setLang("sr");
                    setLocale("sr-RS");
                  }}
                >
                  {global.getUseCP() ? "Srpski (lat)" : "Srpski"}
                </Button>
                {global.getUseCP() && (
                  <Button
                    variant={
                      lang === "cp" ? "primary me-2" : "outline-primary me-2"
                    }
                    size="sm"
                    onClick={() => {
                      setLang("cp");
                      setLocale("sr-RS");
                    }}
                  >
                    Srpski (ćir)
                  </Button>
                )}
                <Button
                  variant={lang === "en" ? "primary" : "outline-primary"}
                  size="sm"
                  onClick={() => {
                    setLang("en");
                    setLocale("en-US");
                  }}
                >
                  Engleski
                </Button>
              </div>
            </div>
            <div className="row-md mb-3">
              {!loading && data && (
                <div className="col-md">
                  <div className="news-main-photo">
                    <img
                      src={
                        process.env.REACT_APP_RESOURCES +
                        data.photos.path +
                        "large/" +
                        data.photos.filename
                      }
                      alt={data.photos["name_" + lang]}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="row-md mb-3">
              {!loading && data && postType === "event" && (
                <div className="single-events-date-time">
                  <div className="single-events-date-icon">
                    <i className="bi bi-calendar3"></i>
                  </div>
                  <div className="single-events-date-value">
                    {parseInt(data.starting_date) + 86400 <=
                    parseInt(data.finishing_date)
                      ? formatDate(data.starting_date + "000", "date") +
                        " - " +
                        formatDate(data.finishing_date + "000", "date")
                      : formatDate(data.starting_date + "000", "date")}
                  </div>
                  {parseInt(data.starting_date) + 86400 >
                    parseInt(data.finishing_date) && (
                    <>
                      <div className="single-events-date-icon">
                        <i className="bi bi-clock"></i>
                      </div>
                      <div className="single-events-date-value">
                        {formatDate(data.starting_date + "000", "time")}
                      </div>
                    </>
                  )}
                </div>
              )}
              {!loading && data && postType === "news" && (
                <div className="single-news-date mb-1">
                  {!loading &&
                    data &&
                    formatDate(data.published + "000", "dateTime")}
                </div>
              )}
              {!loading && data && (
                <h1 className="single-news-title">{data["title_" + lang]}</h1>
              )}
            </div>
            <div className="row-md mb-3">
              {!loading && data && (
                <div
                  className="single-news-text"
                  dangerouslySetInnerHTML={{ __html: data["text_" + lang] }}
                ></div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closePreview}>
              Zatvori
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
