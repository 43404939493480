import md5 from "md5";
import React, { useEffect, useState, useRef } from "react";
import { InputGroup, ToggleButton, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import sr from "date-fns/locale/sr-Latn";
import ErrorModal from "../components/errorModal";
import InfoModal from "../components/infoModal";
import Loader from "../components/loader";
registerLocale("sr", sr);

export default function MyProfile() {
  const [data, setData] = useState([]);
  const [modulesList, setModulesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [modulesApproved, setModulesApproved] = useState([]);
  const [citiesApproved, setCitiesApproved] = useState([]);
  const [loading, setLoading] = useState(true);
  const [savingData, setSavingData] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const moduleId = "dc7242155f0dd877e21dff7ccd92053e";
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordVerified, setPasswordVerified] = useState(true);
  const passwordRef = useRef();
  const repeatPasswordRef = useRef();
  const [passwordError, setPasswordError] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const [emailReport, setEmailReport] = useState(false);
  const [emailTitle, setEmailTitle] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_current_user.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(receivedData.user_data);
          setModulesApproved(receivedData.modules_approved);
          setCitiesApproved(receivedData.cities_approved);
          setModulesList(receivedData.modules_list);
          setCitiesList(receivedData.cities_list);
          setTimeout(() => {
            setLoading(false);
            setAuthorized(true);
          }, 200);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  }, []);

  const handleSave = () => {
    setSavingData(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
        user_id: data.user_id,
        user_name: data.user_name,
        user_email: data.user_email,
        password: password.length > 0 ? md5(password) : "",
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_edit_current_user.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.response) {
          setTimeout(() => {
            if (responseData.email) {
              if (responseData.email === "success") {
                setEmailTitle("Verifikaciona poruka poslata");
                setEmailMessage(
                  "Na e-mail adresu " +
                    responseData.user_data.user_email +
                    " poslata je poruka sa verifikacionim linkom."
                );
                setEmailReport(true);
              }
            }
            setData(responseData.user_data);
            setSavingData(false);
            setPassword("");
            setRepeatPassword("");
            passwordRef.current.value = "";
            repeatPasswordRef.current.value = "";
          }, 500);
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  const verifyPassword = () => {
    setPasswordError("");
    setPasswordVerified(true);
    if (
      passwordRef.current.value.length === 0 &&
      repeatPasswordRef.current.value.length === 0
    ) {
      setPasswordVerified(true);
    } else {
      if (passwordRef.current.value !== repeatPasswordRef.current.value) {
        setPasswordError("Lozinke moraju biti identične!");
        setPasswordVerified(false);
      }
      if (
        passwordRef.current.value.length < 8 ||
        repeatPasswordRef.current.value.length < 8
      ) {
        setPasswordError("Lozinke moraju sadržati najmanje 8 znakova!");
        setPasswordVerified(false);
      }
    }
  };

  function validateEmail(email) {
    if (/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  }

  return (
    <>
      <div className="container-lg pb-5">
        <div className="row">
          <div className="col-md mt-4 mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                <h3 className="mb-0">Moj profil</h3>
              </div>
            </div>
          </div>
        </div>
        {authorized && !loading && (
          <>
            <div className="row">
              <div className="col-md-4 mb-4">
                <div className="h-100 card card-small">
                  <div className="d-flex card-body p-4">
                    <h5 className="mb-3">Korisnički podaci</h5>
                    <Form.Label className="mb-0 standard-size">
                      Ime i prezime
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        className="form-control form-control-sm standard-size"
                        type="text"
                        defaultValue={data.user_name}
                        onChange={(cur) => {
                          let newData = { ...data };
                          newData.user_name = cur.target.value;
                          setData(newData);
                        }}
                      />
                    </InputGroup>
                    <Form.Label className="mb-0 standard-size">
                      E-mail adresa
                      {data.user_email_confirmed === "0" ? (
                        <i
                          title="E-mail adresa nije verifikovana. Nećete moći da se prijavite na sistem."
                          className="bi bi-patch-check ms-1 red"
                        ></i>
                      ) : (
                        <i
                          title="E-mail adresa je verifikovana"
                          className="bi bi-patch-check-fill ms-1 brand"
                        ></i>
                      )}
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        className="form-control form-control-sm standard-size"
                        type="email"
                        defaultValue={data.user_email}
                        onChange={(cur) => {
                          validateEmail(cur.target.value);
                          let newData = { ...data };
                          newData.user_email = cur.target.value;
                          setData(newData);
                        }}
                      />
                    </InputGroup>
                    {!validEmail ? (
                      <div className="red">E-mail adresa nije validna.</div>
                    ) : null}
                    {data.user_email_confirmed === "0" && (
                      <div className="mt-2">
                        <strong>Napomena:</strong>
                        <br />
                        E-mail adresa mora biti verifikovana pre sledećeg
                        prijavljivanja na sistem.
                      </div>
                    )}
                    <h5 className="mb-3 mt-3">Promena lozinke</h5>
                    <Form.Label className="mb-0 standard-size">
                      Nova lozinka
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        ref={passwordRef}
                        className="form-control form-control-sm standard-size"
                        type="password"
                        onChange={(cur) => {
                          setPassword(cur.target.value);
                          verifyPassword();
                        }}
                      />
                    </InputGroup>
                    <Form.Label className="mb-0 standard-size">
                      Ponovi novu lozinku
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        ref={repeatPasswordRef}
                        className="form-control form-control-sm standard-size"
                        type="password"
                        onChange={(cur) => {
                          setRepeatPassword(cur.target.value);
                          verifyPassword();
                        }}
                      />
                    </InputGroup>
                    <div className="red text-center">{passwordError}</div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="h-100 card card-small">
                  <div className="d-flex card-body p-4">
                    <h5 className="mb-3">Odobreni gradovi</h5>
                    {citiesList.map(
                      (city) =>
                        citiesApproved.indexOf(city.city_id) > -1 && (
                          <div className="standard-size">
                            <i className="bi bi-check-lg me-1 brand"></i>
                            {city.name_sr}
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="h-100 card card-small">
                  <div className="d-flex card-body p-4">
                    <h5 className="mb-3">Odobreni moduli</h5>
                    {modulesList.map(
                      (module) =>
                        modulesApproved.indexOf(module.module_id) > -1 && (
                          <div className="standard-size">
                            <i className="bi bi-check-lg me-1 brand"></i>
                            {module.module_name}
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <Loader loading={loading} />

      <div className="card card-small footer-overlay">
        <div className="card-body">
          <Button
            className="ms-2 btn-primary"
            size="sm"
            onClick={handleSave}
            disabled={
              validEmail === false ||
              passwordVerified === false ||
              data?.user_name === "" ||
              data?.user_email === ""
                ? true
                : false
            }
          >
            Sačuvaj
            {savingData ? <i className="ms-1 fa fa-spinner fa-spin"></i> : null}
          </Button>
        </div>
      </div>

      <ErrorModal show={error} message={errorMessage}></ErrorModal>
      <InfoModal
        show={emailReport}
        title={emailTitle}
        message={emailMessage}
        close={() => {
          setEmailReport(false);
        }}
      ></InfoModal>
    </>
  );
}
