import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ErrorModal from "../components/errorModal";
import Loader from "../components/loader";

export default function Notifications(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [previewModal, setPreviewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteNotification, setDeleteNotification] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [deleteNotificationTitle, setDeleteNotificationTitle] = useState("");
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { moduleId } = props;
  const [previewIndex, setPreviewIndex] = useState();

  useEffect(() => {
    getData();
  }, [global.getCity(), global.getCount()]);

  const getData = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT +
        "_private/_get_notifications_list.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(receivedData.notifications);
          setTimeout(() => {
            setLoading(false);
            setAuthorized(true);
          }, 200);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const readNotification = (i, id, notification_id) => {
    if (data[i]?.seen === "0") {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: id,
          notification_id: notification_id,
          city_id: global.getCity(),
          session_id: localStorage.getItem("authToken"),
          module: moduleId,
        }),
      };
      fetch(
        process.env.REACT_APP_BACKEND_ROOT + "_private/_mark_as_seen.php",
        requestOptions
      )
        .then((response) => response.json())
        .then((receivedData) => {
          if (!receivedData.response) {
            setData(receivedData.notifications);
            setPreviewIndex(i);
            setPreviewModal(true);
            global.triggerVerify();
          } else {
            setErrorMessage(receivedData.response);
            setError(true);
          }
        });
    } else {
      setPreviewIndex(i);
      setPreviewModal(true);
    }
  };

  const handleDeleteNotification = (id, notification_id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: id,
        notification_id: notification_id,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_delete_notification.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(
            data.filter(
              (notification) => notification.notification_id !== notification_id
            )
          );
          setDeleteNotification("");
          setDeleteNotificationTitle("");
          setDeleteModal(false);
          global.triggerVerify();
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const convertDate = (date) => {
    let options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    let dateTime = new Date(date * 1000);
    return dateTime.toLocaleDateString("sr", options);
  };

  return (
    <>
      <div className="home-scroll">
        <Table>
          <tbody>
            {authorized &&
              !loading &&
              data &&
              data.map((notification, i) => (
                <tr className="table-hover" key={notification.notification_id}>
                  <td valign="middle" className="standard-size cursor-arrow">
                    <div className="notification-wrap">
                      <div className="notification-marker">
                        {notification.type === "warning" && (
                          <i
                            className="bi bi-exclamation-triangle-fill red me-2"
                            title="Važna informacija / upozorenje"
                          ></i>
                        )}
                        {notification.type === "tip" && (
                          <i
                            className="bi bi-bar-chart-fill brand me-2"
                            title="Savet za poboljšanje kvaliteta usluge"
                          ></i>
                        )}
                        {notification.type === "info" && (
                          <i
                            className="bi bi-info-circle-fill me-2 brand"
                            title="Informacija"
                          ></i>
                        )}
                      </div>
                      <div
                        className={
                          notification.seen === "0"
                            ? "notification unseen"
                            : "notification"
                        }
                      >
                        <div>{notification.notification_title}</div>
                        <div>
                          <span className="me-3 small-size">
                            <i className="bi bi-calendar-date brand me-1"></i>
                            {convertDate(notification.sent)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td valign="middle" align={"right"} width="15%">
                    <button
                      className="icon-16 grey-blue ms-2"
                      title="Pročitaj obaveštenje"
                      onClick={() =>
                        readNotification(
                          i,
                          notification.id,
                          notification.notification_id
                        )
                      }
                    >
                      <i className="bi bi-eye-fill"></i>
                    </button>
                    <button
                      className="icon-16 grey-red ms-2"
                      title="Obriši obaveštenje"
                      onClick={() => {
                        setDeleteModal(true);
                        setDeleteNotification(notification.notification_id);
                        setDeleteId(notification.id);
                        setDeleteNotificationTitle(
                          notification.notification_title
                        );
                      }}
                    >
                      <i className="bi bi-trash3-fill"></i>
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {!loading && data?.length === 0 && (
          <div className="no-results">Nemate novih obaveštenja.</div>
        )}
      </div>

      <Loader loading={loading} />

      {!loading && (
        <Modal
          show={deleteModal}
          onHide={() => {
            setDeleteNotification("");
            setDeleteNotificationTitle("");
            setDeleteId("");
            setDeleteModal(false);
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="md"
        >
          <Modal.Header closeButton>
            <Modal.Title>Brisanje obaveštenja</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row-md">
              <div className="col-md mb-2">
                Obrisati obaveštenje <strong>{deleteNotificationTitle}?</strong>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setDeleteNotification("");
                setDeleteNotificationTitle("");
                setDeleteId("");
                setDeleteModal(false);
              }}
            >
              Zatvori
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                handleDeleteNotification(deleteId, deleteNotification);
              }}
            >
              Obriši
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {!loading && (
        <Modal
          show={previewModal}
          onHide={() => {
            setPreviewModal(false);
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Pročitaj obaveštenje</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <h5 className="mb-0">{data[previewIndex]?.notification_title}</h5>
            <div className="mb-3 small-size">
              {convertDate(data[previewIndex]?.sent)}
            </div>
            <div
              className="standard-size"
              dangerouslySetInnerHTML={{
                __html: data[previewIndex]?.notification_text,
              }}
            >
              {/* {data[previewIndex]?.notification_text} */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setPreviewModal(false);
              }}
            >
              Zatvori
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <ErrorModal show={error} message={errorMessage}></ErrorModal>
    </>
  );
}
