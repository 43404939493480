export function lat2cir(string) {
  string = string.replace(/DJ/g, "Ђ");
  string = string.replace(/Dj/g, "Ђ");
  string = string.replace(/LJ/g, "Љ");
  string = string.replace(/Lj/g, "Љ");
  string = string.replace(/NJ/g, "Њ");
  string = string.replace(/Nj/g, "Њ");
  string = string.replace(/DŽ/g, "Џ");
  string = string.replace(/Dž/g, "Џ");
  string = string.replace(/dj/g, "ђ");
  string = string.replace(/lj/g, "љ");
  string = string.replace(/nj/g, "њ");
  string = string.replace(/dž/g, "џ");
  string = string.replace(/A/g, "А");
  string = string.replace(/B/g, "Б");
  string = string.replace(/V/g, "В");
  string = string.replace(/G/g, "Г");
  string = string.replace(/D/g, "Д");
  string = string.replace(/Đ/g, "Ђ");
  string = string.replace(/E/g, "Е");
  string = string.replace(/Ž/g, "Ж");
  string = string.replace(/Z/g, "З");
  string = string.replace(/I/g, "И");
  string = string.replace(/J/g, "Ј");
  string = string.replace(/K/g, "К");
  string = string.replace(/L/g, "Л");
  string = string.replace(/M/g, "М");
  string = string.replace(/N/g, "Н");
  string = string.replace(/O/g, "О");
  string = string.replace(/P/g, "П");
  string = string.replace(/R/g, "Р");
  string = string.replace(/S/g, "С");
  string = string.replace(/T/g, "Т");
  string = string.replace(/Ć/g, "Ћ");
  string = string.replace(/U/g, "У");
  string = string.replace(/F/g, "Ф");
  string = string.replace(/H/g, "Х");
  string = string.replace(/C/g, "Ц");
  string = string.replace(/Č/g, "Ч");
  string = string.replace(/Š/g, "Ш");
  string = string.replace(/W/g, "В");
  string = string.replace(/Q/g, "К");
  string = string.replace(/Y/g, "Ј");
  string = string.replace(/X/g, "КС");
  string = string.replace(/a/g, "а");
  string = string.replace(/b/g, "б");
  string = string.replace(/v/g, "в");
  string = string.replace(/g/g, "г");
  string = string.replace(/d/g, "д");
  string = string.replace(/đ/g, "ђ");
  string = string.replace(/e/g, "е");
  string = string.replace(/ž/g, "ж");
  string = string.replace(/z/g, "з");
  string = string.replace(/i/g, "и");
  string = string.replace(/j/g, "ј");
  string = string.replace(/k/g, "к");
  string = string.replace(/l/g, "л");
  string = string.replace(/m/g, "м");
  string = string.replace(/n/g, "н");
  string = string.replace(/o/g, "о");
  string = string.replace(/p/g, "п");
  string = string.replace(/r/g, "р");
  string = string.replace(/s/g, "с");
  string = string.replace(/t/g, "т");
  string = string.replace(/ć/g, "ћ");
  string = string.replace(/u/g, "у");
  string = string.replace(/f/g, "ф");
  string = string.replace(/h/g, "х");
  string = string.replace(/c/g, "ц");
  string = string.replace(/č/g, "ч");
  string = string.replace(/š/g, "ш");
  string = string.replace(/q/g, "ш");
  string = string.replace(/w/g, "в");
  string = string.replace(/q/g, "к");
  string = string.replace(/y/g, "ј");
  string = string.replace(/x/g, "кс");
  return string;
}

export function cir2lat(string) {
  string = string.replace(/А/g, "A");
  string = string.replace(/Б/g, "B");
  string = string.replace(/В/g, "V");
  string = string.replace(/Г/g, "G");
  string = string.replace(/Д/g, "D");
  string = string.replace(/Ђ/g, "Đ");
  string = string.replace(/Е/g, "E");
  string = string.replace(/Ж/g, "Ž");
  string = string.replace(/З/g, "Z");
  string = string.replace(/И/g, "I");
  string = string.replace(/Ј/g, "J");
  string = string.replace(/К/g, "K");
  string = string.replace(/Л/g, "L");
  string = string.replace(/Љ/g, "Lj");
  string = string.replace(/М/g, "M");
  string = string.replace(/Н/g, "N");
  string = string.replace(/Њ/g, "Nj");
  string = string.replace(/О/g, "O");
  string = string.replace(/П/g, "P");
  string = string.replace(/Р/g, "R");
  string = string.replace(/С/g, "S");
  string = string.replace(/Т/g, "T");
  string = string.replace(/Ћ/g, "Ć");
  string = string.replace(/У/g, "U");
  string = string.replace(/Ф/g, "F");
  string = string.replace(/Х/g, "H");
  string = string.replace(/Ц/g, "C");
  string = string.replace(/Ч/g, "Č");
  string = string.replace(/Џ/g, "Dž");
  string = string.replace(/Ш/g, "Š");
  string = string.replace(/а/g, "a");
  string = string.replace(/б/g, "b");
  string = string.replace(/в/g, "v");
  string = string.replace(/г/g, "g");
  string = string.replace(/д/g, "d");
  string = string.replace(/ђ/g, "đ");
  string = string.replace(/е/g, "e");
  string = string.replace(/ж/g, "ž");
  string = string.replace(/з/g, "z");
  string = string.replace(/и/g, "i");
  string = string.replace(/ј/g, "j");
  string = string.replace(/к/g, "k");
  string = string.replace(/л/g, "l");
  string = string.replace(/љ/g, "lj");
  string = string.replace(/м/g, "m");
  string = string.replace(/н/g, "n");
  string = string.replace(/њ/g, "nj");
  string = string.replace(/о/g, "o");
  string = string.replace(/п/g, "p");
  string = string.replace(/р/g, "r");
  string = string.replace(/с/g, "s");
  string = string.replace(/т/g, "t");
  string = string.replace(/ћ/g, "ć");
  string = string.replace(/у/g, "u");
  string = string.replace(/ф/g, "f");
  string = string.replace(/х/g, "h");
  string = string.replace(/ц/g, "c");
  string = string.replace(/ч/g, "č");
  string = string.replace(/џ/g, "dž");
  string = string.replace(/ш/g, "š");
  return string;
}
