import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

export default function InfoModal(props) {
  const { show, title, message, close } = props;

  return (
    <>
      <Modal show={show} backdrop="static" keyboard={false} centered size="md">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row-md">
            <div className="col-md mb-2">{message}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Zatvori
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
