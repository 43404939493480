import React, { useEffect, useState } from "react";
import { Form, InputGroup, Container, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import PreviewModal from "../components/newsAndEvents/previewModal";
import ErrorModal from "../components/errorModal";
import Loader from "../components/loader";
import InfoModal from "../components/infoModal";
import ShareModal from "../components/shareModal";

export default function Events() {
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [eventModal, setEventModal] = useState(false);
  const [loadingEvent, setLoadingEvent] = useState(true);
  const [preview, setPreview] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteEvent, setDeleteEvent] = useState("");
  const [deleteEventTitle, setDeleteEventTitle] = useState("");
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const moduleId = "75c58d36157505a600e0695ed0b3a22d";
  const [averageViews, setAverageViews] = useState(0);
  const [averageComments, setAverageComments] = useState(0);
  const [averageLikes, setAverageLikes] = useState(0);
  const [errorToggle, setErrorToggle] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [shareBody, setShareBody] = useState("");
  const [shareSubject, setShareSubject] = useState("");
  const [shareMedia, setShareMedia] = useState("");
  const [shareUrl, setShareUrl] = useState("");

  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
        //   offset: offset,
        //   count: count,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_events_list.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(receivedData.events);
          setFullData(receivedData.events);
          setAverageViews(receivedData.average.views);
          setAverageComments(receivedData.average.comments);
          setAverageLikes(receivedData.average.likes);
          setTimeout(() => {
            setLoading(false);
            setAuthorized(true);
          }, 200);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  }, [global.getCity()]);

  const handleActive = (index, event_id, active) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        event_id: event_id,
        active: active,
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_toggle_event_state.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.response) {
          setLoading(true);
          let newData = data.map((event, i) => {
            if (i === index) {
              event.active = responseData.active;
              return event;
            } else {
              return event;
            }
          });
          setData(newData);
          setLoading(false);
          if (responseData.error) {
            setErrorToggle(true);
          }
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  const previewEvent = (event_id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        event_id: event_id,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_single_event.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setPreview(receivedData);
          setLoadingEvent(false);
          setEventModal(true);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const closeEventModal = () => {
    setEventModal(false);
  };

  const handleDeleteEvent = (event_id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        type: "event",
        id: event_id,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_delete_post.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(data.filter((events) => events.event_id !== event_id));
          setDeleteEvent("");
          setDeleteEventTitle("");
          setDeleteModal(false);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const closeDeleteModal = () => {
    setDeleteEvent("");
    setDeleteEventTitle("");
    setDeleteModal(false);
  };

  const convertDate = (date) => {
    let options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    let dateTime = new Date(date * 1000);
    return dateTime.toLocaleDateString("sr", options);
  };

  function status(dateStart, dateEnd) {
    dateStart = dateStart + "000";
    dateEnd = dateEnd + "000";
    let dateNow = Date.now();
    return parseInt(dateStart) < dateNow && dateNow < parseInt(dateEnd) ? (
      <div className="brand">U toku...</div>
    ) : dateNow > parseInt(dateEnd) ? (
      <div className="red">Istekla</div>
    ) : dateNow < parseInt(dateStart) ? (
      <div>Zakazana</div>
    ) : (
      ""
    );
  }

  return (
    <>
      <Container>
        <div className="row">
          <div className="col-md mt-4 mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                <div className="row">
                  <div className="col-md-8 m-0">
                    <h3 className="mb-0">Najave</h3>
                  </div>
                  <div className="col-md-4 m-0 text-right">
                    <Link to={"/events/add"}>
                      <button className="icon-24" title="Nova objava">
                        <i className="bi bi-plus-circle-fill"></i>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body p-4">
                <div className="row">
                  <div className="col-md-8"></div>
                  <div className="col-md-4">
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <i className="bi bi-search"></i>
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Pretraži"
                        onChange={(cur) => {
                          setData(
                            fullData.filter(
                              (news) =>
                                news.title_sr
                                  .toLowerCase()
                                  .indexOf(cur.target.value.toLowerCase()) !==
                                  -1 ||
                                convertDate(news.published).indexOf(
                                  cur.target.value.toLowerCase()
                                ) !== -1
                            )
                          );
                        }}
                      />
                    </InputGroup>
                  </div>
                </div>
                <Table>
                  <thead>
                    <tr>
                      <th>Naslov objave</th>
                      <th>Kategorija</th>
                      <th>Status najave</th>
                      <th className="text-right">Funkcije</th>
                    </tr>
                  </thead>
                  <tbody>
                    {authorized &&
                      !loading &&
                      data &&
                      data.map((event, i) => (
                        <tr className="table-hover" key={event.event_id}>
                          <td
                            valign="middle"
                            className="standard-size cursor-arrow"
                          >
                            <div>{event.title_sr}</div>
                            <div>
                              <span className="me-3 small-size">
                                <i className="bi bi-calendar-date brand"></i>{" "}
                                {convertDate(event.published)}
                              </span>
                              <span
                                className="me-2 small-size"
                                title={
                                  parseInt(event.views) < averageViews
                                    ? "Broj pregleda je ispod proseka"
                                    : parseInt(event.views) === averageViews
                                    ? "Broj pregleda je prosečan"
                                    : "Broj pregleda je iznad proseka"
                                }
                              >
                                <i className="bi bi-eye brand"></i>{" "}
                                {event.views}
                              </span>
                              <span
                                className="me-2 small-size"
                                title={
                                  parseInt(event.likes) < averageLikes
                                    ? "Broj sviđanja je ispod proseka"
                                    : parseInt(event.likes) === averageLikes
                                    ? "Broj sviđanja je prosečan"
                                    : "Broj sviđanja je iznad proseka"
                                }
                              >
                                <i className="bi bi-hand-thumbs-up brand"></i>{" "}
                                {event.likes}
                              </span>
                              <span
                                className="me-2 small-size"
                                title={
                                  parseInt(event.comments) < averageComments
                                    ? "Broj komentara je ispod proseka"
                                    : parseInt(event.comments) ===
                                      averageComments
                                    ? "Broj komentara je prosečan"
                                    : "Broj komentara je iznad proseka"
                                }
                              >
                                <i className="bi bi-chat-dots brand"></i>{" "}
                                {event.comments}
                              </span>
                            </div>
                          </td>
                          <td
                            valign="middle"
                            className="standard-size cursor-arrow"
                          >
                            {event.category_name}
                          </td>
                          <td
                            valign="middle"
                            className="standard-size cursor-arrow"
                          >
                            {status(event.starting_date, event.finishing_date)}
                          </td>
                          <td valign="middle" align={"right"} width="15%">
                            <Link to={"/events/edit/" + event.event_id}>
                              <button
                                className="icon-16 grey-blue ms-2"
                                title="Uredi objavu"
                              >
                                <i className="bi bi-pencil-fill"></i>
                              </button>
                            </Link>
                            <button
                              className="icon-16 grey-blue ms-2"
                              title="Pregled objave"
                              onClick={() => previewEvent(event.event_id)}
                            >
                              <i className="bi bi-eye-fill"></i>
                            </button>
                            <button
                              className={
                                event.active === "1"
                                  ? "icon-16 green ms-2"
                                  : "icon-16 red ms-2"
                              }
                              title={
                                event.active === "1"
                                  ? "Objavljeno"
                                  : "Nije objavljeno"
                              }
                              onClick={() =>
                                handleActive(i, event.event_id, event.active)
                              }
                            >
                              {event.active === "1" ? (
                                <i className="bi bi-toggle-on"></i>
                              ) : (
                                <i className="bi bi-toggle-off"></i>
                              )}
                            </button>
                            {event.active === "1" && (
                              <button
                                className="icon-16 grey-blue ms-2"
                                title="Podeli"
                                onClick={() => {
                                  setShareBody(event.text_sr);
                                  setShareSubject(event.title_sr);
                                  setShareMedia(
                                    process.env.REACT_APP_RESOURCES +
                                      event.path +
                                      "large/" +
                                      event.filename
                                  );
                                  setShareUrl(
                                    global.getCityUrl() +
                                      "najave/" +
                                      event.category_stub +
                                      "/" +
                                      event.stub
                                  );
                                  setShowShare(true);
                                }}
                              >
                                <i className="bi bi-share-fill"></i>
                              </button>
                            )}
                            <button
                              className="icon-16 grey-red ms-2"
                              title="Obriši objavu"
                              onClick={() => {
                                setDeleteModal(true);
                                setDeleteEvent(event.event_id);
                                setDeleteEventTitle(event.title_sr);
                              }}
                            >
                              <i className="bi bi-trash3-fill"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {!loading && data?.length === 0 && (
                  <div className="no-results">
                    Kategorija ne sadrži ni jednu objavu.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Loader loading={loading} />

      {!loadingEvent && (
        <PreviewModal
          show={eventModal}
          onClosePreview={closeEventModal}
          data={preview}
          loading={loadingEvent}
          postType="event"
        ></PreviewModal>
      )}
      {!loading && (
        <Modal
          show={deleteModal}
          onHide={closeDeleteModal}
          backdrop="static"
          keyboard={false}
          centered
          size="md"
        >
          <Modal.Header closeButton>
            <Modal.Title>Brisanje objave</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row-md">
              <div className="col-md mb-2">
                Obrisati objavu <strong>{deleteEventTitle}?</strong>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeDeleteModal}>
              Zatvori
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                handleDeleteEvent(deleteEvent);
              }}
            >
              Obriši
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <ErrorModal show={error} message={errorMessage}></ErrorModal>
      <InfoModal
        show={errorToggle}
        title="Greška"
        message="Objava ne može biti aktivirana jer ne ispunjava sve tehničke zahteve."
        close={() => {
          setErrorToggle(false);
        }}
      ></InfoModal>
      <ShareModal
        show={showShare}
        onCloseShare={() => {
          setShowShare(false);
        }}
        body={shareBody}
        subject={shareSubject}
        media={shareMedia}
        url={shareUrl}
      />
    </>
  );
}
