import React, { useEffect, useState } from "react";
import { Container, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ErrorModal from "../components/errorModal";
import Loader from "../components/loader";

export default function Notifications() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [previewModal, setPreviewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteNotification, setDeleteNotification] = useState("");
  const [deleteNotificationTitle, setDeleteNotificationTitle] = useState("");
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const moduleId = "12f19dc41aa8979ce0091797a82eb7a6";
  const [previewIndex, setPreviewIndex] = useState();

  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT +
        "_private/_get_admin_notifications_list.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(receivedData.notifications);
          setTimeout(() => {
            setLoading(false);
            setAuthorized(true);
          }, 200);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  }, []);

  const handleDeleteNotification = (notification_id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        notification_id: notification_id,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT +
        "_private/_delete_admin_notification.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(
            data.filter(
              (notification) => notification.notification_id !== notification_id
            )
          );
          setDeleteNotification("");
          setDeleteNotificationTitle("");
          setDeleteModal(false);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const convertDate = (date) => {
    let options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    let dateTime = new Date(date * 1000);
    return dateTime.toLocaleDateString("sr", options);
  };

  return (
    <>
      <Container>
        <div className="row">
          <div className="col-md mt-4 mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                <div className="row">
                  <div className="col-md-8 m-0">
                    <h3 className="mb-0">Obaveštenja</h3>
                  </div>
                  <div className="col-md-4 m-0 text-right">
                    <Link to={"/notifications/add"}>
                      <button className="icon-24" title="Novo obaveštenje">
                        <i className="bi bi-plus-circle-fill"></i>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body p-4">
                <Table>
                  <thead>
                    <tr>
                      <th>Naslov obaveštenja</th>
                      <th>Status</th>
                      <th className="text-right">Funkcije</th>
                    </tr>
                  </thead>
                  <tbody>
                    {authorized &&
                      !loading &&
                      data &&
                      data.map((notification, i) => (
                        <tr
                          className="table-hover"
                          key={notification.notification_id}
                        >
                          <td
                            valign="middle"
                            className="standard-size cursor-arrow"
                          >
                            <div className="notification-wrap">
                              <div className="notification-marker">
                                {notification.type === "warning" && (
                                  <i
                                    className="bi bi-exclamation-triangle-fill red me-2"
                                    title="Važna informacija / upozorenje"
                                  ></i>
                                )}
                                {notification.type === "tip" && (
                                  <i
                                    className="bi bi-bar-chart-fill brand me-2"
                                    title="Savet za poboljšanje kvaliteta usluge"
                                  ></i>
                                )}
                                {notification.type === "info" && (
                                  <i
                                    className="bi bi-info-circle-fill me-2 brand"
                                    title="Informacija"
                                  ></i>
                                )}
                              </div>
                              <div className="notification">
                                <div>{notification.notification_title}</div>
                                <div>
                                  <span className="me-3 small-size">
                                    <i className="bi bi-calendar-date brand me-1"></i>
                                    {notification.status === "draft"
                                      ? "Kreirano "
                                      : null}
                                    {convertDate(notification.sent)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td valign="middle">
                            {notification.status === "draft"
                              ? "U pripremi"
                              : "Poslato"}
                          </td>
                          <td valign="middle" align={"right"} width="15%">
                            <Link
                              to={
                                "/notifications/edit/" +
                                notification.notification_id
                              }
                            >
                              <button
                                className="icon-16 grey-blue ms-2"
                                title="Uredi obaveštenje"
                              >
                                <i className="bi bi-pencil-fill"></i>
                              </button>
                            </Link>
                            <button
                              className="icon-16 grey-blue ms-2"
                              title="Pregled obaveštenja"
                              onClick={() => {
                                setPreviewIndex(i);
                                setPreviewModal(true);
                              }}
                            >
                              <i className="bi bi-eye-fill"></i>
                            </button>

                            <button
                              className="icon-16 grey-red ms-2"
                              title="Obriši obaveštenje"
                              onClick={() => {
                                setDeleteModal(true);
                                setDeleteNotification(
                                  notification.notification_id
                                );
                                setDeleteNotificationTitle(
                                  notification.notification_title
                                );
                              }}
                            >
                              <i className="bi bi-trash3-fill"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {!loading && data?.length === 0 && (
                  <div className="no-results">Nemate poslatih obaveštenja.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Loader loading={loading} />

      {!loading && (
        <Modal
          show={previewModal}
          onHide={() => {
            setPreviewModal(false);
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Pročitaj obaveštenje</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <h5 className="mb-0">{data[previewIndex]?.notification_title}</h5>
            <div className="mb-3 small-size">
              {convertDate(data[previewIndex]?.sent)}
            </div>
            <div
              className="standard-size"
              dangerouslySetInnerHTML={{
                __html: data[previewIndex]?.notification_text,
              }}
            ></div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setPreviewModal(false);
              }}
            >
              Zatvori
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {!loading && (
        <Modal
          show={deleteModal}
          onHide={() => {
            setDeleteNotification("");
            setDeleteNotificationTitle("");
            setDeleteModal(false);
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="md"
        >
          <Modal.Header closeButton>
            <Modal.Title>Brisanje obaveštenja</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row-md">
              <div className="col-md mb-2">
                Obrisati obaveštenje <strong>{deleteNotificationTitle}?</strong>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setDeleteNotification("");
                setDeleteNotificationTitle("");
                setDeleteModal(false);
              }}
            >
              Zatvori
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                handleDeleteNotification(deleteNotification);
              }}
            >
              Obriši
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <ErrorModal show={error} message={errorMessage}></ErrorModal>
    </>
  );
}
