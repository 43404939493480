import React, { useEffect, useState } from "react";
import { Container, Table, Button, Form, InputGroup } from "react-bootstrap";
import ErrorModal from "../components/errorModal";
import Loader from "../components/loader";
import InfoModal from "./infoModal";
import ShareModal from "../components/shareModal";

export default function GeneralData(props) {
  const { moduleId } = props;
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [sitemapLoading, setSitemapLoading] = useState(false);
  const [sitemapHelp, setSitemapHelp] = useState(false);
  const [qrHelp, setQrHelp] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [shareBody, setShareBody] = useState("");
  const [shareSubject, setShareSubject] = useState("");
  const [shareMedia, setShareMedia] = useState("");
  const [shareUrl, setShareUrl] = useState("");

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_general_info.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(receivedData);
          setTimeout(() => {
            setLoading(false);
          }, 200);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  }, [global.getCity()]);

  const generateSitemap = () => {
    setSitemapLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
        path: data.path,
        url: data.url,
        use_cp: data.use_cp,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_build_sitemap_v2.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(receivedData);
          setSitemapLoading(false);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const convertDate = (date) => {
    let options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    let dateTime = new Date(date * 1000);
    return dateTime.toLocaleDateString("sr", options);
  };

  return (
    <>
      <div className="general-info-item border-bottom">
        <div className="general-info-inner">
          <div>
            Kratki link
            <button
              className="icon-16 grey-blue ms-2"
              onClick={() => {
                setQrHelp(true);
              }}
            >
              <i className="bi bi-question-circle"></i>
            </button>
          </div>
          <a
            href={"https://qr.inndex.rs/" + data?.shortlink}
            target="_blank"
            rel="noreferrer"
          >
            {"https://qr.inndex.rs/" + data?.shortlink}
          </a>
        </div>
        <button
          className="icon-16 grey-blue ms-2"
          title="Podeli"
          onClick={() => {
            setShareBody(data?.app_name_sr);
            setShareSubject(data?.app_name_sr);
            setShareUrl("https://qr.inndex.rs/" + data?.shortlink);
            setShowShare(true);
          }}
        >
          <i className="bi bi-share-fill"></i>
        </button>
      </div>
      <div className="general-info-item border-bottom">
        <div className="general-info-inner">
          <div>Web adresa</div>
          <a href={data?.url} target="_blank" rel="noreferrer">
            {data?.url}
          </a>
        </div>
        <button
          className="icon-16 grey-blue ms-2"
          title="Podeli"
          onClick={() => {
            setShareBody(data?.app_name_sr);
            setShareSubject(data?.app_name_sr);
            setShareUrl(data?.url);
            setShowShare(true);
          }}
        >
          <i className="bi bi-share-fill"></i>
        </button>
      </div>
      {data?.android_app !== "" && (
        <div className="general-info-item border-bottom">
          <div className="general-info-inner">
            <div>Adresa za preuzimanje Android aplikacije</div>
            <a href={data?.android_app} target="_blank" rel="noreferrer">
              {data?.android_app}
            </a>
          </div>
          <button
            className="icon-16 grey-blue ms-2"
            title="Podeli"
            onClick={() => {
              setShareBody(data?.app_name_sr);
              setShareSubject(data?.app_name_sr);
              setShareUrl(data?.android_app);
              setShowShare(true);
            }}
          >
            <i className="bi bi-share-fill"></i>
          </button>
        </div>
      )}
      {data?.ios_app !== "" && (
        <div className="general-info-item border-bottom">
          <div className="general-info-inner">
            <div>Adresa za preuzimanje iOS aplikacije</div>
            <a href={data?.ios_app} target="_blank" rel="noreferrer">
              {data?.ios_app}
            </a>
          </div>
          <button
            className="icon-16 grey-blue ms-2"
            title="Podeli"
            onClick={() => {
              setShareBody(data?.app_name_sr);
              setShareSubject(data?.app_name_sr);
              setShareUrl(data?.ios_app);
              setShowShare(true);
            }}
          >
            <i className="bi bi-share-fill"></i>
          </button>
        </div>
      )}
      {!loading && !data?.sitemap && (
        <div className="general-info-item">
          <div>
            <div>Za vašu stranicu nije generisan sitemap.xml</div>
          </div>
        </div>
      )}
      {!loading && data?.sitemap && (
        <div className="general-info-item">
          <div className="general-info-inner">
            <div>Lokacija sitemap.xml fajla:</div>
            <a
              href={data?.url + "sitemap.xml"}
              target="_blank"
              rel="noreferrer"
            >
              {data?.url + "sitemap.xml"}
            </a>
            <div className="mt-3">Datum i vreme generisanja:</div>
            <div>{convertDate(data?.sitemap_date)}</div>
          </div>
        </div>
      )}
      <div className="row mt-3">
        <div className="col-md">
          <Button
            variant="primary"
            onClick={() => {
              generateSitemap();
            }}
          >
            Generiši sitemap.xml
            {sitemapLoading ? (
              <i className="ms-1 fa fa-spinner fa-spin"></i>
            ) : null}
          </Button>
          <button
            className="icon-16 grey-blue ms-2"
            onClick={() => {
              setSitemapHelp(true);
            }}
          >
            <i className="bi bi-question-circle"></i>
          </button>
        </div>
      </div>

      <Loader loading={loading} />
      <ErrorModal show={error} message={errorMessage}></ErrorModal>
      <InfoModal
        show={sitemapHelp}
        title="Sitemap.xml"
        message={
          <i>
            Sitemap.xml je poseban dokument koji sadrži tačne adrese svih
            stranice na web sajtu kako bi pretraživačima pružio pregled svog
            dostupnog sadržaja.
            <br />
            <strong>
              Nakon svake izmene ili dodavanja novog sadržaja generišite novi
              sitemap.xml fajl.
            </strong>
          </i>
        }
        close={() => {
          setSitemapHelp(false);
        }}
      ></InfoModal>
      <InfoModal
        show={qrHelp}
        title="Kratki link"
        message={
          <i>
            Kratki link je zbog svoje dužine i funkcionalnosti najpogodniji link
            za deljenje na društvenim mrežama. Otvaranjem ovog linka, korisnici
            Android mobilnih uređaja biće preusmereni na stranicu za preuzimanje
            Android aplikacije u okviru Google Play prodavnice, korisnici iPhone
            i iPad uređaja biće preusmereni na stranicu za preuzimanje vaše iOS
            aplikacije u okviru App Store prodavnice, a korisnici ostalih
            uređaja biće preusmereni na adresu vašeg web portala.
          </i>
        }
        close={() => {
          setQrHelp(false);
        }}
      ></InfoModal>
      <ShareModal
        show={showShare}
        onCloseShare={() => {
          setShowShare(false);
        }}
        body={shareBody}
        subject={shareSubject}
        media={shareMedia}
        url={shareUrl}
      />
    </>
  );
}
