import React, { useEffect, useState } from "react";
import { Container, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ErrorModal from "../components/errorModal";
import Loader from "../components/loader";

export default function Users() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteUser, setDeleteUser] = useState("");
  const [deleteUserName, setDeleteUserName] = useState("");
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const moduleId = "f4e3ce3e7b581ff32e40968298ba013d";

  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
        //   offset: offset,
        //   count: count,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_users_list.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(receivedData);
          setTimeout(() => {
            setLoading(false);
            setAuthorized(true);
          }, 200);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  }, []);

  const handleActive = (index, user_id, active) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        user_id: user_id,
        active: active,
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_toggle_user_state.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.response) {
          let newData = data.map((user, i) => {
            if (i === index) {
              user.active = responseData.active;
              return user;
            } else {
              return user;
            }
          });
          setData(newData);
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  const handleDeleteUser = (user_id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        user_id: user_id,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_delete_user.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(data.filter((user) => user.user_id !== user_id));
          setDeleteUser("");
          setDeleteUserName("");
          setDeleteModal(false);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const closeDeleteModal = () => {
    setDeleteUser("");
    setDeleteUserName("");
    setDeleteModal(false);
  };

  return (
    <>
      <Container>
        <div className="row">
          <div className="col-md mt-4 mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                <div className="row">
                  <div className="col-md-8 m-0">
                    <h3 className="mb-0">Korisnici</h3>
                  </div>
                  <div className="col-md-4 m-0 text-right">
                    <Link to={"/users/add"}>
                      <button className="icon-24" title="Novi korisnik">
                        <i className="bi bi-plus-circle-fill"></i>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body p-4">
                <Table>
                  <thead>
                    <tr>
                      <th>Ime i prezime</th>
                      <th>E-mail adresa</th>
                      <th className="text-right">Funkcije</th>
                    </tr>
                  </thead>
                  <tbody>
                    {authorized &&
                      !loading &&
                      data &&
                      data.map((user, i) => (
                        <tr className="table-hover" key={user.user_id}>
                          <td className="standard-size">{user.user_name}</td>
                          <td className="standard-size">
                            {user.user_email_confirmed === "0" ? (
                              <i
                                title="E-mail adresa nije verifikovana. Korisnik neće moći da pristupi sistemu."
                                className="bi bi-patch-check me-1 red"
                              ></i>
                            ) : (
                              <i
                                title="E-mail adresa je verifikovana"
                                className="bi bi-patch-check-fill me-1 brand"
                              ></i>
                            )}
                            {user.user_email}
                          </td>
                          <td align={"right"} width="15%">
                            <Link to={"/users/edit/" + user.user_id}>
                              <button
                                className="icon-16 grey-blue ms-2"
                                title="Uredi nalog korisnika"
                              >
                                <i className="bi bi-pencil-fill"></i>
                              </button>
                            </Link>
                            <button
                              className={
                                user.active === "1"
                                  ? "icon-16 green ms-2"
                                  : "icon-16 red ms-2"
                              }
                              title={
                                user.active === "1"
                                  ? "Nalog je aktivan"
                                  : "Nalog nije aktivan. Korisnik neće moći da pristupi sistemu."
                              }
                              onClick={() =>
                                handleActive(i, user.user_id, user.active)
                              }
                            >
                              {user.active === "1" ? (
                                <i className="bi bi-toggle-on"></i>
                              ) : (
                                <i className="bi bi-toggle-off"></i>
                              )}
                            </button>
                            <button
                              className="icon-16 grey-red ms-2"
                              title="Obriši korisnika"
                              onClick={() => {
                                setDeleteModal(true);
                                setDeleteUser(user.user_id);
                                setDeleteUserName(user.user_name);
                              }}
                            >
                              <i className="bi bi-trash3-fill"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Loader loading={loading} />

      {!loading && (
        <Modal
          show={deleteModal}
          onHide={closeDeleteModal}
          backdrop="static"
          keyboard={false}
          centered
          size="md"
        >
          <Modal.Header closeButton>
            <Modal.Title>Brisanje korisnika</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row-md">
              <div className="col-md mb-2">
                Obrisati korisnika <strong>{deleteUserName}?</strong>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeDeleteModal}>
              Zatvori
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                handleDeleteUser(deleteUser);
              }}
            >
              Obriši
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <ErrorModal show={error} message={errorMessage}></ErrorModal>
    </>
  );
}
