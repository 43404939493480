import React from "react";
import ErrorModal from "../components/errorModal";

export default function Error404() {
  return (
    <>
      <ErrorModal
        show={true}
        message="Nemate pristup izabranom sadržaju."
      ></ErrorModal>
    </>
  );
}
