import React, { useEffect, useState } from "react";
import { Container, Table, Button, Form, InputGroup } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import ErrorModal from "../components/errorModal";
import Loader from "../components/loader";

export default function VisitsSpread(props) {
  const { moduleId, dateFrom, dateTo } = props;
  const [userData, setUserData] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, [dateFrom, dateTo, global.getCity(), global.getCount()]);

  const getData = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
        date_from: dateFrom,
        date_to: dateTo,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_spread_data.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setUserData({
            labels: receivedData.total.map((item) => item.label),
            datasets: [
              {
                label: "Web portal",
                data: receivedData.web.map((item) => item.value),
              },
              {
                label: "Mobilna aplikacija",
                data: receivedData.app.map((item) => item.value),
              },
            ],
          });
          setTimeout(() => {
            setLoading(false);
          }, 200);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  return (
    <>
      {!loading && userData && <Bar data={userData} />}
      <Loader loading={loading} />
      <ErrorModal show={error} message={errorMessage}></ErrorModal>
    </>
  );
}
