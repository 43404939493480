import "./App.css";
import Home from "./pages/home";
import Error404 from "./pages/404";
import News from "./pages/news";
import NewsEdit from "./pages/newsEdit";
import NewsAdd from "./pages/newsAdd";
import Events from "./pages/events";
import EventsEdit from "./pages/eventsEdit";
import EventsAdd from "./pages/eventsAdd";
import Users from "./pages/users";
import UsersEdit from "./pages/usersEdit";
import UsersAdd from "./pages/usersAdd";
import ModulesAndCities from "./pages/modulesAndCities";
import GroupsAndSubgroups from "./pages/groupsAndSubgroups";
import Locations from "./pages/locations";
import LocationsEdit from "./pages/locationsEdit";
import LocationsAdd from "./pages/locationsAdd";
import MyProfile from "./pages/myProfile";
import Comments from "./pages/comments";
import UserComments from "./pages/userComments";
import CollectGarbage from "./pages/collectGarbage";
import Verify from "./pages/verify";
import ResetPassword from "./pages/resetPassword";
import Notifications from "./pages/notifications";
import NotificationsEdit from "./pages/notificationsEdit";
import NotificationsAdd from "./pages/notificationsAdd";
import QRCode from "./pages/qrCode";
import SEOSettings from "./pages/seoSettings";
import Container from "react-bootstrap/Container";
import ScrollTop from "./helpers/scrollToTop";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import {
  Route,
  Routes,
  useMatch,
  useResolvedPath,
  NavLink,
  useNavigate,
} from "react-router-dom";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { useState, useEffect } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import md5 from "md5";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";

function App() {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCity, setSelectedCity] = useState("Izaberi");
  const [selectedCityId, setSelectedCityId] = useState("");
  const [disableChangeCity, setDisableChangeCity] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [count, setCount] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [modules, setModules] = useState([]);
  const [loginResponse, setLoginResponse] = useState("");
  const [pendingComments, setPendingComments] = useState(0);
  const [pendingUserComments, setPendingUserComments] = useState(0);
  const [pendingNotifications, setPendingNotifications] = useState(0);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [reset, setReset] = useState(false);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [role, setRole] = useState("");
  const [useCP, setUseCP] = useState(false);
  const [appNameSr, setAppNameSr] = useState("");
  const [appNameCp, setAppNameCp] = useState("");
  const [appNameEn, setAppNameEn] = useState("");
  const [appSloganSr, setAppSloganSr] = useState("");
  const [appSloganCp, setAppSloganCp] = useState("");
  const [appSloganEn, setAppSloganEn] = useState("");
  const [baseUrl, setBaseUrl] = useState("");
  const navigate = useNavigate();

  const resetData = () => {
    localStorage.setItem("authToken", "");
    localStorage.setItem("city", "");
    localStorage.setItem("city_url", "");
    setModules([]);
    setCities([]);
    setAuthorized(false);
    setLoading(false);
    setSelectedCity("Izaberi");
    setSelectedCityId("");
    setUseCP(false);
    setAppNameSr("");
    setAppNameCp("");
    setAppNameEn("");
    setAppSloganSr("");
    setAppSloganCp("");
    setAppSloganEn("");
  };

  const verify = () => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        session_id: localStorage.getItem("authToken"),
        city_id: global.getCity(),
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_verify.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (receivedData.response !== "Error") {
          localStorage.setItem("authToken", receivedData.response);
          setModules(receivedData.modules);
          setCities(receivedData.cities);
          setPendingComments(receivedData.pending_comments);
          setPendingUserComments(receivedData.pending_user_comments);
          setPendingNotifications(receivedData.pending_notifications);
          setRole(receivedData.role);
          if (localStorage.getItem("city")) {
            receivedData.cities.map((city) => {
              if (city.city_id === localStorage.getItem("city")) {
                setSelectedCity(city.name_sr);
                setSelectedCityId(city.city_id);
                city.use_cp === "1" ? setUseCP(true) : setUseCP(false);
                setAppNameSr(city.app_name_sr);
                setAppNameCp(city.app_name_cp);
                setAppNameEn(city.app_name_en);
                setAppSloganSr(city.description_sr);
                setAppSloganCp(city.description_cp);
                setAppSloganEn(city.description_en);
                setBaseUrl(city.url);
              }
              return city;
            });
          } else {
            setSelectedCity(receivedData.cities[0].name_sr);
            setSelectedCityId(receivedData.cities[0].city_id);
            localStorage.setItem("city", receivedData.cities[0].city_id);
            localStorage.setItem("city_url", receivedData.cities[0].url);
            receivedData.cities[0].use_cp === "1"
              ? setUseCP(true)
              : setUseCP(false);
            setAppNameSr(receivedData.cities[0].app_name_sr);
            setAppNameCp(receivedData.cities[0].app_name_cp);
            setAppNameEn(receivedData.cities[0].app_name_en);
            setAppSloganSr(receivedData.cities[0].description_sr);
            setAppSloganCp(receivedData.cities[0].description_cp);
            setAppSloganEn(receivedData.cities[0].description_en);
            setBaseUrl(receivedData.cities[0].url);
          }

          setAuthorized(true);
          setLoading(false);
        } else {
          resetData();
        }
      });
  };

  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      verify();
    } else {
      resetData();
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (localStorage.getItem("authToken")) {
        verify();
      } else {
        resetData();
      }
      setCount(count + 1);
    }, 60000);
    return () => clearTimeout(timer);
  }, [count]);

  function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: false });
    return (
      <li className="nav-item">
        <NavLink
          className={isActive ? "nav-link active" : "nav-link"}
          to={to}
          {...props}
        >
          {children}
        </NavLink>
      </li>
    );
  }

  const handleLogin = () => {
    setLoginResponse("");
    setLoadingResponse(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        forgot_password: forgotPassword,
        email: email,
        password: md5(password),
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_login.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (receivedData.response === "Reset") {
          setForgotPassword(false);
          setEmail("");
          setPassword("");
          setReset(true);
        } else if (receivedData.response === "Error") {
          setReset(false);
          setLoginResponse(receivedData.error);
          resetData();
        } else {
          setReset(false);
          localStorage.setItem("authToken", receivedData.response);
          setForgotPassword(false);
          setEmail("");
          setPassword("");
          verify();
        }
        setLoadingResponse(false);
      });
  };

  global.baseUrl = function () {
    return baseUrl;
  };

  global.getAppSlogan = function (lang) {
    if (lang === "sr") {
      return appSloganSr;
    }
    if (lang === "cp") {
      return appSloganCp;
    }
    if (lang === "en") {
      return appSloganEn;
    }
  };

  global.appName = function (lang) {
    if (lang === "sr") {
      return appNameSr;
    }
    if (lang === "cp") {
      return appNameCp;
    }
    if (lang === "en") {
      return appNameEn;
    }
  };

  global.logout = function () {
    localStorage.setItem("authToken", "");
    verify();
  };

  global.getCity = function () {
    return localStorage.getItem("city");
  };

  global.getCount = function () {
    return count;
  };

  global.disableChangeCity = function (value) {
    setDisableChangeCity(value);
  };

  global.triggerVerify = function () {
    verify();
  };

  global.pendingComments = function () {
    return pendingComments;
  };

  global.pendingUserComments = function () {
    return pendingUserComments;
  };

  global.pendingNotifications = function () {
    return pendingNotifications;
  };

  global.getCityUrl = function () {
    return localStorage.getItem("city_url");
  };

  global.getUserRole = function () {
    return role;
  };

  global.getUseCP = function () {
    return useCP;
  };

  return (
    <>
      {authorized && (
        <>
          <Navbar className="sticky-nav" bg="white" expand="lg">
            <Container>
              <Navbar.Brand href="/">
                <img
                  src="https://cdn.inndex.rs/assets/belgrade/static/logo.png"
                  alt="Inndex Dashboard"
                  style={{ height: "37px" }}
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  {selectedCityId !== "" && (
                    <>
                      <Nav.Link as={CustomLink} to="/home">
                        <i className="bi bi-house brand me-1"></i>Naslovna
                        {pendingNotifications > 0 ? (
                          <Badge className="ms-1 badge-fix" bg="danger">
                            {pendingNotifications}
                          </Badge>
                        ) : null}
                      </Nav.Link>
                      {modules.indexOf("082a8bbf2c357c09f26675f9cf5bcba3") >
                        -1 && (
                        <Nav.Link as={CustomLink} to="/news">
                          <i className="bi bi-newspaper brand me-1"></i>Vesti
                        </Nav.Link>
                      )}
                      {modules.indexOf("75c58d36157505a600e0695ed0b3a22d") >
                        -1 && (
                        <Nav.Link as={CustomLink} to="/events">
                          <i className="bi bi-calendar-date me-1 brand"></i>
                          Najave
                        </Nav.Link>
                      )}
                      {modules.indexOf("e6a4f65e7355bb8b7671c3a18003b146") >
                        -1 && (
                        <Nav.Link as={CustomLink} to="/locations">
                          <i className="bi bi-geo-alt me-1 brand"></i>Lokacije
                        </Nav.Link>
                      )}
                      {modules.indexOf("947018640bf36a2bb609d3557a285329") >
                        -1 && (
                        <Nav.Link as={CustomLink} to="/groups-subgroups">
                          <i className="bi bi-collection me-1 brand"></i>Grupe i
                          podgrupe
                        </Nav.Link>
                      )}
                      {modules.indexOf("85b991ae924d726b0a109024f364c06e") >
                        -1 && (
                        <Nav.Link as={CustomLink} to="/qr-code">
                          <i className="bi bi-collection me-1 brand"></i>QR
                          kodovi
                        </Nav.Link>
                      )}
                      {modules.indexOf("b2cc937e9c8b44fef1700bf6c4d8ca37") >
                        -1 && (
                        <Nav.Link as={CustomLink} to="/user-comments">
                          <i className="bi bi-chat me-1 brand"></i>
                          Komentari
                          {pendingUserComments > 0 ? (
                            <Badge className="ms-1 badge-fix" bg="danger">
                              {pendingUserComments}
                            </Badge>
                          ) : null}
                        </Nav.Link>
                      )}
                      {modules.indexOf("6f7b1a46e2e0f06fa771edded8d88cb2") >
                        -1 && (
                        <Nav.Link as={CustomLink} to="/seo-settings">
                          <i className="bi bi-sliders me-1 brand"></i>SEO
                          podešavanja
                        </Nav.Link>
                      )}
                      {modules.indexOf("f4e3ce3e7b581ff32e40968298ba013d") >
                        -1 ||
                      modules.indexOf("c783eed3cfc1c978fe76e15af007e0d0") >
                        -1 ||
                      modules.indexOf("61751849c3c200015af75fb612fd7f51") >
                        -1 ||
                      modules.indexOf("e4b58a0c4c350ec8bb52751a4b0fcb20") >
                        -1 ? (
                        <NavDropdown
                          title={
                            <span>
                              <i className="bi bi-gear brand me-1"></i> Admin
                              alati
                              {pendingComments > 0 ? (
                                <Badge className="ms-1 badge-fix" bg="danger">
                                  {pendingComments}
                                </Badge>
                              ) : null}
                            </span>
                          }
                          id="basic-nav-dropdown"
                        >
                          {modules.indexOf("12f19dc41aa8979ce0091797a82eb7a6") >
                            -1 && (
                            <NavDropdown.Item
                              as={CustomLink}
                              to="/notifications"
                            >
                              <i className="bi bi-bell me-1 brand"></i>
                              Obaveštenja
                            </NavDropdown.Item>
                          )}
                          {modules.indexOf("f4e3ce3e7b581ff32e40968298ba013d") >
                            -1 && (
                            <NavDropdown.Item as={CustomLink} to="/users">
                              <i className="bi bi-person-lines-fill me-1 brand"></i>
                              Korisnici
                            </NavDropdown.Item>
                          )}
                          {modules.indexOf("c783eed3cfc1c978fe76e15af007e0d0") >
                            -1 && (
                            <NavDropdown.Item
                              as={CustomLink}
                              to="/modules-cities"
                            >
                              <i className="bi bi-puzzle me-1 brand"></i>
                              Moduli i gradovi
                            </NavDropdown.Item>
                          )}

                          {modules.indexOf("61751849c3c200015af75fb612fd7f51") >
                            -1 && (
                            <NavDropdown.Item as={CustomLink} to="/comments">
                              <i className="bi bi-chat me-1 brand"></i>
                              Komentari
                              {pendingComments > 0 ? (
                                <Badge className="ms-1 badge-fix" bg="danger">
                                  {pendingComments}
                                </Badge>
                              ) : null}
                            </NavDropdown.Item>
                          )}
                          {modules.indexOf("e4b58a0c4c350ec8bb52751a4b0fcb20") >
                            -1 && (
                            <NavDropdown.Item as={CustomLink} to="/garbage">
                              <i className="bi bi-trash3 me-1 brand"></i>
                              Otpad
                            </NavDropdown.Item>
                          )}
                        </NavDropdown>
                      ) : null}
                    </>
                  )}
                </Nav>
                {cities && cities.length > 1 && (
                  <DropdownButton
                    size="sm"
                    title={selectedCity}
                    align="end"
                    variant="primary"
                    disabled={disableChangeCity}
                    onSelect={(selected) => {
                      localStorage.setItem("city", selected);
                      setSelectedCityId(selected);
                      cities.map((city) => {
                        if (city.city_id === selected) {
                          setSelectedCity(city.name_sr);
                          setSelectedCityId(city.city_id);
                          city.use_cp === "1"
                            ? setUseCP(true)
                            : setUseCP(false);
                          localStorage.setItem("city_url", city.url);
                        }
                        return city;
                      });
                      verify();
                    }}
                  >
                    {!loading &&
                      cities &&
                      cities.map((city) => (
                        <Dropdown.Item
                          key={city.city_id}
                          eventKey={city.city_id}
                        >
                          {city.name_sr}
                        </Dropdown.Item>
                      ))}
                  </DropdownButton>
                )}
                <DropdownButton
                  className="ms-2"
                  size="sm"
                  title={<i className="bi bi-person-fill"></i>}
                  align="end"
                  variant="primary"
                >
                  <Dropdown.Item
                    key="my-profile"
                    onClick={() => {
                      navigate("/my-profile");
                    }}
                  >
                    Moj profil
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    key="logout"
                    onClick={() => {
                      global.logout();
                    }}
                  >
                    Odjava
                  </Dropdown.Item>
                </DropdownButton>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          <Routes>
            {modules.indexOf("92a1b1aa4ae5b397014eae92d23b7cf9") > -1 ? (
              <Route path="/" element={<Home />} />
            ) : (
              <Route path="/" element={<Error404 />} />
            )}
            {modules.indexOf("92a1b1aa4ae5b397014eae92d23b7cf9") > -1 ? (
              <Route path="/home" element={<Home />} />
            ) : (
              <Route path="/home" element={<Error404 />} />
            )}
            {modules.indexOf("082a8bbf2c357c09f26675f9cf5bcba3") > -1 ? (
              <Route path="/news" element={<News />} />
            ) : (
              <Route path="/news" element={<Error404 />} />
            )}
            {modules.indexOf("0af854284f4ab0cfea8fcfd889cbb41a") > -1 ? (
              <Route path="/news/add" element={<NewsAdd />} />
            ) : (
              <Route path="/news/add" element={<Error404 />} />
            )}
            {modules.indexOf("0b6a27e2bfcb010e762109f0d2e042dc") > -1 ? (
              <Route path="/news/edit/:news_id" element={<NewsEdit />} />
            ) : (
              <Route path="/news/edit/:news_id" element={<Error404 />} />
            )}
            {modules.indexOf("75c58d36157505a600e0695ed0b3a22d") > -1 ? (
              <Route path="/events" element={<Events />} />
            ) : (
              <Route path="/events" element={<Error404 />} />
            )}
            {modules.indexOf("84e2d85ac232c681a641da1ec663888c") > -1 ? (
              <Route path="/events/add" element={<EventsAdd />} />
            ) : (
              <Route path="/events/add" element={<Error404 />} />
            )}
            {modules.indexOf("c77cfd5563c8ec4bfcde94c09098ba84") > -1 ? (
              <Route path="/events/edit/:event_id" element={<EventsEdit />} />
            ) : (
              <Route path="/events/edit/:event_id" element={<Error404 />} />
            )}
            {modules.indexOf("f4e3ce3e7b581ff32e40968298ba013d") > -1 ? (
              <Route path="/users" element={<Users />} />
            ) : (
              <Route path="/users" element={<Error404 />} />
            )}
            {modules.indexOf("575425a3f433138553be468c9d1ecba7") > -1 ? (
              <Route path="/users/edit/:user_id" element={<UsersEdit />} />
            ) : (
              <Route path="/users/edit/:user_id" element={<Error404 />} />
            )}
            {modules.indexOf("91576cbf171986154e523305a69c79d3") > -1 ? (
              <Route path="/users/add" element={<UsersAdd />} />
            ) : (
              <Route path="/users/add" element={<Error404 />} />
            )}
            {modules.indexOf("c783eed3cfc1c978fe76e15af007e0d0") > -1 ? (
              <Route path="/modules-cities" element={<ModulesAndCities />} />
            ) : (
              <Route path="/modules-cities" element={<Error404 />} />
            )}
            {modules.indexOf("947018640bf36a2bb609d3557a285329") > -1 ? (
              <Route
                path="/groups-subgroups"
                element={<GroupsAndSubgroups />}
              />
            ) : (
              <Route path="/groups-subgroups" element={<Error404 />} />
            )}
            {modules.indexOf("e6a4f65e7355bb8b7671c3a18003b146") > -1 ? (
              <Route path="/locations" element={<Locations />} />
            ) : (
              <Route path="/locations" element={<Error404 />} />
            )}
            {modules.indexOf("6467c327eaf8940b4dd07a08c63c5e85") > -1 ? (
              <Route
                path="/locations/edit/:location_id"
                element={<LocationsEdit />}
              />
            ) : (
              <Route
                path="/locations/edit/:location_id"
                element={<Error404 />}
              />
            )}
            {modules.indexOf("41e01664ee9c9583e5b0d968b533effe") > -1 ? (
              <Route path="/locations/add" element={<LocationsAdd />} />
            ) : (
              <Route path="/locations/add" element={<Error404 />} />
            )}
            {modules.indexOf("dc7242155f0dd877e21dff7ccd92053e") > -1 ? (
              <Route path="/my-profile" element={<MyProfile />} />
            ) : (
              <Route path="/my-profile" element={<Error404 />} />
            )}
            {modules.indexOf("61751849c3c200015af75fb612fd7f51") > -1 ? (
              <Route path="/comments" element={<Comments />} />
            ) : (
              <Route path="/comments" element={<Error404 />} />
            )}
            {modules.indexOf("e4b58a0c4c350ec8bb52751a4b0fcb20") > -1 ? (
              <Route path="/garbage" element={<CollectGarbage />} />
            ) : (
              <Route path="/garbage" element={<Error404 />} />
            )}
            {modules.indexOf("12f19dc41aa8979ce0091797a82eb7a6") > -1 ? (
              <Route path="/notifications" element={<Notifications />} />
            ) : (
              <Route path="/notifications" element={<Error404 />} />
            )}
            {modules.indexOf("3efcb63b2d22bea513eebd2c6382ff19") > -1 ? (
              <Route
                path="/notifications/edit/:notification_id"
                element={<NotificationsEdit />}
              />
            ) : (
              <Route
                path="/notifications/edit/:notification_id"
                element={<Error404 />}
              />
            )}
            {modules.indexOf("5052c616c1428bd3f9846c1f257f8da6") > -1 ? (
              <Route path="/notifications/add" element={<NotificationsAdd />} />
            ) : (
              <Route path="/notifications/add" element={<Error404 />} />
            )}
            {modules.indexOf("85b991ae924d726b0a109024f364c06e") > -1 ? (
              <Route path="/qr-code" element={<QRCode />} />
            ) : (
              <Route path="/qr-code" element={<Error404 />} />
            )}
            {modules.indexOf("b2cc937e9c8b44fef1700bf6c4d8ca37") > -1 ? (
              <Route path="/user-comments" element={<UserComments />} />
            ) : (
              <Route path="/user-comments" element={<Error404 />} />
            )}
            {modules.indexOf("6f7b1a46e2e0f06fa771edded8d88cb2") > -1 ? (
              <Route path="/seo-settings" element={<SEOSettings />} />
            ) : (
              <Route path="/seo-settings" element={<Error404 />} />
            )}
          </Routes>
        </>
      )}
      {window.location.pathname.indexOf("verify") !== -1 && (
        <Routes>
          <Route path="/verify/:verify_id" element={<Verify />} />
        </Routes>
      )}
      {window.location.pathname.indexOf("reset-password") !== -1 && (
        <Routes>
          <Route
            path="/reset-password/:verify_id"
            element={<ResetPassword />}
          />
        </Routes>
      )}
      {!authorized &&
        !loading &&
        window.location.pathname.indexOf("verify") === -1 &&
        window.location.pathname.indexOf("reset-password") === -1 && (
          <div className="login-window">
            <div className="card card-small p-5">
              <div className="row pb-5">
                <div className="col-md">
                  <img
                    style={{
                      transition: "all 500ms",
                      margin: "auto",
                      display: "block",
                    }}
                    width="30%"
                    src={
                      process.env.REACT_APP_RESOURCES +
                      "belgrade/static/logo.png"
                    }
                    alt="Inndex Dashboard"
                  />
                </div>
              </div>
              <div className="row pb-3">
                <div className="col-md">
                  <Form.Label className="mb-0 standard-size">
                    E-mail adresa
                  </Form.Label>
                  <InputGroup className={forgotPassword ? "mb-0" : "mb-2"}>
                    <Form.Control
                      className="form-control form-control-sm standard-size"
                      type="email"
                      onChange={(email) => {
                        setEmail(email.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleLogin();
                        }
                      }}
                    />
                  </InputGroup>
                  {forgotPassword && (
                    <div
                      className="act-as-link mb-2"
                      onClick={() => {
                        setForgotPassword(!forgotPassword);
                      }}
                    >
                      Nazad na prijavu
                    </div>
                  )}
                  {!forgotPassword && (
                    <>
                      <Form.Label className="mb-0 standard-size">
                        Lozinka
                      </Form.Label>
                      <InputGroup className="mb-0">
                        <Form.Control
                          className="form-control form-control-sm standard-size"
                          type="password"
                          onChange={(password) => {
                            setPassword(password.target.value);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleLogin();
                            }
                          }}
                        />
                      </InputGroup>
                      <div
                        className="act-as-link mb-2"
                        onClick={() => {
                          setForgotPassword(!forgotPassword);
                        }}
                      >
                        Zaboravljena lozinka
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="row">
                {loginResponse !== "" && (
                  <div className="col-md standard-size red">
                    {loginResponse}
                  </div>
                )}
                {reset && (
                  <div className="col-md standard-size">
                    Na vašu e-mail adresu poslata je poruka sa uputstvom za
                    resetovanje lozinke.
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-md text-right">
                  <Button className="btn-primary" onClick={handleLogin}>
                    {!forgotPassword ? "Prijavi se" : "Resetuj lozinku"}
                    {loadingResponse ? (
                      <i className="ms-1 fa fa-spinner fa-spin"></i>
                    ) : null}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      <ScrollTop />
    </>
  );
}

export default App;
