import React, { useEffect, useState } from "react";
import { Container, Table, Button, Form, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import DeleteModal from "../components/deleteModal";
import ErrorModal from "../components/errorModal";
import PreviewModal from "../components/locations/previewModal";
import Loader from "../components/loader";
import InfoModal from "../components/infoModal";
import ShareModal from "../components/shareModal";

export default function Locations() {
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [groups, setGroups] = useState([]);
  const [subgroups, setSubgroups] = useState([]);
  const [groupsById, setGroupsById] = useState([]);
  const [subgroupsById, setSubgroupsById] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("0");
  const [selectedSubgroup, setSelectedSubgroup] = useState("0");

  const [loading, setLoading] = useState(true);
  const [locationModal, setLocationModal] = useState(false);
  const [loadingNews, setLoadingNews] = useState(true);
  const [preview, setPreview] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLocation, setDeleteLocation] = useState("");
  const [deleteLocationTitle, setDeleteLocationTitle] = useState("");
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const moduleId = "e6a4f65e7355bb8b7671c3a18003b146";
  const [singleLocationData, setSingleLocationData] = useState([]);
  const [singleLocationLoading, setSingleLocationLoading] = useState(false);
  const [singleLocationPhotos, setSingleLocationPhotos] = useState([]);
  const [singleLocationContacts, setSingleLocationContacts] = useState([]);
  const [singleLocationContactTypesById, setSingleLocationContactTypesById] =
    useState([]);
  const [averageViews, setAverageViews] = useState(0);
  const [averageComments, setAverageComments] = useState(0);
  const [averageLikes, setAverageLikes] = useState(0);
  const [errorToggle, setErrorToggle] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [shareBody, setShareBody] = useState("");
  const [shareSubject, setShareSubject] = useState("");
  const [shareMedia, setShareMedia] = useState("");
  const [shareUrl, setShareUrl] = useState("");

  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
        //   offset: offset,
        //   count: count,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_locations_list.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(receivedData.locations);
          setFullData(receivedData.locations);
          setAverageViews(receivedData.average.views);
          setAverageComments(receivedData.average.comments);
          setAverageLikes(receivedData.average.likes);
          setGroups(receivedData.groups);
          setSubgroups(receivedData.subgroups);
          setGroupsById(receivedData.groups_id);
          setSubgroupsById(receivedData.subgroups_id);
          setSelectedGroup("0");
          setSelectedSubgroup("0");
          setTimeout(() => {
            setLoading(false);
            setAuthorized(true);
          }, 200);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  }, [global.getCity()]);

  const handleActive = (index, location_id, active) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        location_id: location_id,
        active: active,
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT +
        "_private/_toggle_location_state.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.response) {
          setLoading(true);
          let newData = data.map((location, i) => {
            if (i === index) {
              location.active = responseData.active;
              return location;
            } else {
              return location;
            }
          });
          setData(newData);
          setLoading(false);
          if (responseData.error) {
            setErrorToggle(true);
          }
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  const previewLocation = (location_id) => {
    setSingleLocationLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        location_id: location_id,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_single_location.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setSingleLocationData(receivedData.data);
          setSingleLocationPhotos(receivedData.photos);
          setSingleLocationContacts(receivedData.contacts);
          setSingleLocationContactTypesById(receivedData.contact_types_id);
          setSingleLocationLoading(false);
          setLocationModal(true);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const closeModal = () => {
    setLocationModal(false);
  };

  const handleDeleteLocation = (location_id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        location_id: location_id,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_delete_location.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(
            data.filter((location) => location.location_id !== location_id)
          );
          setDeleteLocation("");
          setDeleteLocationTitle("");
          setDeleteModal(false);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  const convertDate = (date) => {
    return (
      date.substring(8, 10) +
      "." +
      date.substring(5, 7) +
      "." +
      date.substring(0, 4) +
      ". " +
      date.substring(11, 13) +
      ":" +
      date.substring(14, 16)
    );
  };

  return (
    <>
      <Container>
        <div className="row">
          <div className="col-md mt-4 mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                <div className="row">
                  <div className="col-md-8 m-0">
                    <h3 className="mb-0">Lokacije</h3>
                  </div>
                  <div className="col-md-4 m-0 text-right">
                    <Link to={"/locations/add"}>
                      <button className="icon-24" title="Nova lokacija">
                        <i className="bi bi-plus-circle-fill"></i>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                <div className="row">
                  <div className="col-md-6 m-0">
                    <Form.Label className="mb-0 standard-size">
                      Grupe
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Select
                        size="sm"
                        className="form-control form-control-sm standard-size"
                        onChange={(cur) => {
                          setSelectedSubgroup("0");
                          setSelectedGroup(cur.target.value);
                        }}
                      >
                        <option value="0">Izaberi grupu</option>
                        {groups &&
                          groups.map((item) => (
                            <option
                              key={"sel_group" + item.group_id}
                              value={item.group_id}
                            >
                              {item.name_sr}
                            </option>
                          ))}
                      </Form.Select>
                    </InputGroup>
                  </div>
                  <div className="col-md-6 m-0">
                    <Form.Label className="mb-0 standard-size">
                      Podgrupe
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Select
                        size="sm"
                        className="form-control form-control-sm standard-size"
                        onChange={(cur) => {
                          setSelectedSubgroup(cur.target.value);
                        }}
                        disabled={selectedGroup === "0" ? true : false}
                      >
                        <option value="0">Izaberi podgrupu</option>
                        {subgroups &&
                          subgroups.map(
                            (item) =>
                              item.group_id === selectedGroup && (
                                <option
                                  key={"sel_subgroup" + item.subgroup_id}
                                  value={item.subgroup_id}
                                >
                                  {item.name_sr}
                                </option>
                              )
                          )}
                      </Form.Select>
                    </InputGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body p-4">
                <div className="row">
                  <div className="col-md-8"></div>
                  <div className="col-md-4">
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <i className="bi bi-search"></i>
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Pretraži"
                        onChange={(cur) => {
                          setData(
                            fullData.filter(
                              (news) =>
                                news.name_sr
                                  .toLowerCase()
                                  .indexOf(cur.target.value.toLowerCase()) !==
                                  -1 ||
                                convertDate(news.published).indexOf(
                                  cur.target.value.toLowerCase()
                                ) !== -1
                            )
                          );
                        }}
                      />
                    </InputGroup>
                  </div>
                </div>
                <Table>
                  <thead>
                    <tr>
                      <th>Naziv lokacije</th>
                      <th>Grupa</th>
                      <th>Podgrupa</th>
                      <th className="text-right">Funkcije</th>
                    </tr>
                  </thead>
                  <tbody>
                    {authorized &&
                      !loading &&
                      data &&
                      groupsById &&
                      subgroupsById &&
                      data.map(
                        (location, i) =>
                          (selectedSubgroup === "0" ||
                            selectedSubgroup === location.subgroup_id) && (
                            <tr
                              className="table-hover"
                              key={location.location_id}
                            >
                              <td
                                valign="middle"
                                className="standard-size cursor-arrow"
                              >
                                <div>{location.name_sr}</div>
                                <div>
                                  <span className="me-3 small-size">
                                    <i className="bi bi-calendar-date brand"></i>{" "}
                                    {convertDate(location.published)}
                                  </span>
                                  <span
                                    className="me-2 small-size"
                                    title={
                                      parseInt(location.views) < averageViews
                                        ? "Broj pregleda je ispod proseka"
                                        : parseInt(location.views) ===
                                          averageViews
                                        ? "Broj pregleda je prosečan"
                                        : "Broj pregleda je iznad proseka"
                                    }
                                  >
                                    <i className="bi bi-eye brand"></i>{" "}
                                    {location.views}
                                  </span>
                                  <span
                                    className="me-2 small-size"
                                    title={
                                      parseInt(location.likes) < averageLikes
                                        ? "Broj sviđanja je ispod proseka"
                                        : parseInt(location.likes) ===
                                          averageLikes
                                        ? "Broj sviđanja je prosečan"
                                        : "Broj sviđanja je iznad proseka"
                                    }
                                  >
                                    <i className="bi bi-hand-thumbs-up brand"></i>{" "}
                                    {location.likes}
                                  </span>
                                  <span
                                    className="me-2 small-size"
                                    title={
                                      parseInt(location.comments) <
                                      averageComments
                                        ? "Broj komentara je ispod proseka"
                                        : parseInt(location.comments) ===
                                          averageComments
                                        ? "Broj komentara je prosečan"
                                        : "Broj komentara je iznad proseka"
                                    }
                                  >
                                    <i className="bi bi-chat-dots brand"></i>{" "}
                                    {location.comments}
                                  </span>
                                </div>
                              </td>
                              <td valign="middle" className="standard-size">
                                {
                                  groupsById[
                                    subgroupsById[location.subgroup_id].group_id
                                  ].name_sr
                                }
                              </td>
                              <td valign="middle" className="standard-size">
                                {subgroupsById[location.subgroup_id].name_sr}
                              </td>
                              <td valign="middle" align={"right"} width="15%">
                                <Link
                                  to={"/locations/edit/" + location.location_id}
                                >
                                  <button
                                    className="icon-16 grey-blue ms-2"
                                    title="Uredi lokaciju"
                                  >
                                    <i className="bi bi-pencil-fill"></i>
                                  </button>
                                </Link>
                                <button
                                  className="icon-16 grey-blue ms-2"
                                  title="Pregled lokacije"
                                  onClick={() =>
                                    previewLocation(location.location_id)
                                  }
                                >
                                  <i className="bi bi-eye-fill"></i>
                                </button>
                                <button
                                  className={
                                    location.active === "1"
                                      ? "icon-16 green ms-2"
                                      : "icon-16 red ms-2"
                                  }
                                  title={
                                    location.active === "1"
                                      ? "Objavljeno"
                                      : "Nije objavljeno"
                                  }
                                  onClick={() =>
                                    handleActive(
                                      i,
                                      location.location_id,
                                      location.active
                                    )
                                  }
                                >
                                  {location.active === "1" ? (
                                    <i className="bi bi-toggle-on"></i>
                                  ) : (
                                    <i className="bi bi-toggle-off"></i>
                                  )}
                                </button>
                                {location.active === "1" && (
                                  <button
                                    className="icon-16 grey-blue ms-2"
                                    title="Podeli"
                                    onClick={() => {
                                      setShareBody(location.text_sr);
                                      setShareSubject(location.name_sr);
                                      setShareMedia(
                                        process.env.REACT_APP_RESOURCES +
                                          location.photo_path +
                                          "large/" +
                                          location.photo_filename
                                      );
                                      setShareUrl(
                                        global.getCityUrl() +
                                          groupsById[
                                            subgroupsById[location.subgroup_id]
                                              .group_id
                                          ].stub +
                                          "/" +
                                          subgroupsById[location.subgroup_id]
                                            .stub +
                                          "/" +
                                          location.stub
                                      );
                                      setShowShare(true);
                                    }}
                                  >
                                    <i className="bi bi-share-fill"></i>
                                  </button>
                                )}
                                <button
                                  className="icon-16 grey-red ms-2"
                                  title="Obriši lokaciju"
                                  onClick={() => {
                                    setDeleteModal(true);
                                    setDeleteLocation(location.location_id);
                                    setDeleteLocationTitle(location.name_sr);
                                  }}
                                >
                                  <i className="bi bi-trash3-fill"></i>
                                </button>
                              </td>
                            </tr>
                          )
                      )}
                  </tbody>
                </Table>
                {!loading && data?.length === 0 && (
                  <div className="no-results">
                    Kategorija ne sadrži ni jednu lokaciju.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Loader loading={loading} />

      {!singleLocationLoading && (
        <PreviewModal
          show={locationModal}
          onClosePreview={() => {
            setLocationModal(false);
          }}
          data={singleLocationData}
          loading={singleLocationLoading}
          photos={singleLocationPhotos}
          contacts={singleLocationContacts}
          contactTypesById={singleLocationContactTypesById}
        ></PreviewModal>
      )}
      {!loading && (
        <DeleteModal
          show={deleteModal}
          close={() => {
            setDeleteModal(false);
            setDeleteLocation("");
            setDeleteLocationTitle("");
          }}
          title="Brisanje lokacije"
          message="Obrisati lokaciju"
          name={deleteLocationTitle}
          action={() => {
            handleDeleteLocation(deleteLocation);
          }}
        ></DeleteModal>
      )}

      <ErrorModal show={error} message={errorMessage}></ErrorModal>
      <InfoModal
        show={errorToggle}
        title="Greška"
        message="Lokacija ne može biti aktivirana jer ne ispunjava sve tehničke zahteve."
        close={() => {
          setErrorToggle(false);
        }}
      ></InfoModal>
      <ShareModal
        show={showShare}
        onCloseShare={() => {
          setShowShare(false);
        }}
        body={shareBody}
        subject={shareSubject}
        media={shareMedia}
        url={shareUrl}
      />
    </>
  );
}
