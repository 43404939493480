import React, { useEffect, useState } from "react";
import { Container, Table, Button, Form, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ErrorModal from "../components/errorModal";
import Loader from "../components/loader";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { lat2cir, cir2lat } from "../helpers/transcribe";

export default function SeoSettings() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [previewModal, setPreviewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteNotification, setDeleteNotification] = useState("");
  const [deleteNotificationTitle, setDeleteNotificationTitle] = useState("");
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const moduleId = "6f7b1a46e2e0f06fa771edded8d88cb2";
  const [savingData, setSavingData] = useState(false);
  const [importingData, setImportingData] = useState(false);

  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_seo_data.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(receivedData);
          setTimeout(() => {
            setLoading(false);
            setAuthorized(true);
          }, 200);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  }, [global.getCity()]);

  const handleSave = () => {
    setSavingData(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
        seo_data: data,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_edit_seo_settings.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.response) {
          setData(responseData);
          setTimeout(() => {
            setSavingData(false);
          }, 500);
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  const importDefault = (section) => {
    setImportingData(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
        section: section,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_get_seo_default.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          let newData = { ...data };
          newData[section].seo_title_sr = receivedData.seo_title_sr;
          newData[section].seo_title_en = receivedData.seo_title_en;
          newData[section].seo_title_cp = receivedData.seo_title_cp;
          newData[section].seo_description_sr = receivedData.seo_description_sr;
          newData[section].seo_description_en = receivedData.seo_description_en;
          newData[section].seo_description_cp = receivedData.seo_description_cp;
          setData(newData);
          setTimeout(() => {
            setImportingData(false);
          }, 200);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  };

  return (
    <>
      <Container>
        <div className="row">
          <div className="col-md mt-4 mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                <div className="row">
                  <div className="col-md-12 m-0">
                    <h3 className="mb-0">SEO podešavanja</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!loading && (
          <div className="row">
            <div className="col-md mb-4">
              <div className="h-100 card card-small">
                <div className="d-flex card-body p-4">
                  <Tabs
                    defaultActiveKey="home"
                    id="seo-tabs"
                    className="mb-3"
                    fill
                  >
                    <Tab eventKey="home" title="Naslovna">
                      <div className="row mb-3">
                        <div className="col-8 pt-2">
                          <div className="small-url">
                            URL:{" "}
                            <a
                              href={global.baseUrl()}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {global.baseUrl()}
                            </a>
                          </div>
                        </div>
                        <div className="col-4 text-right">
                          <Button
                            className="btn-primary"
                            size="sm"
                            title="Uvezi podrazumevane vrednosti"
                            onClick={() => importDefault("home")}
                          >
                            Uvezi podrazumevane vrednosti{" "}
                            {importingData ? (
                              <i className="ms-1 fa fa-spinner fa-spin"></i>
                            ) : null}
                          </Button>
                        </div>
                      </div>
                      <div className="row">
                        <div className={global.getUseCP() ? "col-4" : "col-6"}>
                          <Form.Label
                            className={
                              data.home.seo_title_sr === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            {global.getUseCP()
                              ? "SEO naslov srpski (lat)"
                              : "SEO naslov srpski"}
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data.home.seo_title_sr}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.home.seo_title_sr = cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <Form.Label
                            className={
                              data.home.seo_description_sr === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            {global.getUseCP()
                              ? "SEO opis srpski (lat)"
                              : "SEO opis srpski"}
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={10}
                              as="textarea"
                              value={data.home.seo_description_sr}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.home.seo_description_sr =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <div className="col mt-3 text-right">
                            <Button
                              className="btn-primary"
                              size="sm"
                              title="Preslovi u ćirilicu"
                              disabled={
                                data.home.seo_title_sr === "" ||
                                data.home.seo_description_sr === ""
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                let newData = { ...data };
                                newData.home.seo_description_cp = lat2cir(
                                  data.home.seo_description_sr
                                );
                                newData.home.seo_title_cp = lat2cir(
                                  data.home.seo_title_sr
                                );
                                setData(newData);
                              }}
                            >
                              {"Preslovi u ćirilicu >>"}
                            </Button>
                          </div>
                        </div>
                        {global.getUseCP() && (
                          <div className="col-4">
                            <Form.Label
                              className={
                                data.home.seo_title_cp === ""
                                  ? "mb-0 standard-size red"
                                  : "mb-0 standard-size"
                              }
                            >
                              SEO naslov srpski (ćir)
                            </Form.Label>
                            <InputGroup className="mb-2">
                              <Form.Control
                                className="form-control form-control-sm standard-size"
                                type="text"
                                value={data.home.seo_title_cp}
                                onChange={(cur) => {
                                  let newData = { ...data };
                                  newData.home.seo_title_cp = cur.target.value;
                                  setData(newData);
                                }}
                              />
                            </InputGroup>
                            <Form.Label
                              className={
                                data.home.seo_description_cp === ""
                                  ? "mb-0 standard-size red"
                                  : "mb-0 standard-size"
                              }
                            >
                              SEO opis srpski (ćir)
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                className="form-control form-control-sm standard-size"
                                type="text"
                                rows={10}
                                as="textarea"
                                value={data.home.seo_description_cp}
                                onChange={(cur) => {
                                  let newData = { ...data };
                                  newData.home.seo_description_cp =
                                    cur.target.value;
                                  setData(newData);
                                }}
                              />
                            </InputGroup>
                            <div className="col mt-3">
                              <Button
                                className="btn-primary"
                                size="sm"
                                title="Preslovi u latinicu"
                                disabled={
                                  data.home.seo_title_cp === "" ||
                                  data.home.seo_description_cp === ""
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  let newData = { ...data };
                                  newData.home.seo_description_sr = cir2lat(
                                    data.home.seo_description_cp
                                  );
                                  newData.home.seo_title_sr = cir2lat(
                                    data.home.seo_title_cp
                                  );
                                  setData(newData);
                                }}
                              >
                                {"<< Preslovi u latinicu"}
                              </Button>
                            </div>
                          </div>
                        )}
                        <div className={global.getUseCP() ? "col-4" : "col-6"}>
                          <Form.Label
                            className={
                              data.home.seo_title_en === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO naslov engleski
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data.home.seo_title_en}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.home.seo_title_en = cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <Form.Label
                            className={
                              data.home.seo_description_en === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO opis engleski
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={10}
                              as="textarea"
                              value={data.home.seo_description_en}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.home.seo_description_en =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="locations" title="Lokacije">
                      <div className="row mb-3">
                        <div className="col-8 pt-2">
                          <div className="small-url">
                            URL:{" "}
                            <a
                              href={global.baseUrl() + "lokacije/"}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {global.baseUrl() + "lokacije/"}
                            </a>
                          </div>
                        </div>
                        <div className="col-4 text-right">
                          <Button
                            className="btn-primary"
                            size="sm"
                            title="Uvezi podrazumevane vrednosti"
                            onClick={() => importDefault("locations")}
                          >
                            Uvezi podrazumevane vrednosti{" "}
                            {importingData ? (
                              <i className="ms-1 fa fa-spinner fa-spin"></i>
                            ) : null}
                          </Button>
                        </div>
                      </div>
                      <div className="row">
                        <div className={global.getUseCP() ? "col-4" : "col-6"}>
                          <Form.Label
                            className={
                              data.locations.seo_title_sr === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            {global.getUseCP()
                              ? "SEO naslov srpski (lat)"
                              : "SEO naslov srpski"}
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data.locations.seo_title_sr}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.locations.seo_title_sr =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <Form.Label
                            className={
                              data.locations.seo_description_sr === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            {global.getUseCP()
                              ? "SEO opis srpski (lat)"
                              : "SEO opis srpski"}
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={10}
                              as="textarea"
                              value={data.locations.seo_description_sr}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.locations.seo_description_sr =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <div className="col mt-3 text-right">
                            <Button
                              className="btn-primary"
                              size="sm"
                              title="Preslovi u ćirilicu"
                              disabled={
                                data.locations.seo_title_sr === "" ||
                                data.locations.seo_description_sr === ""
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                let newData = { ...data };
                                newData.locations.seo_description_cp = lat2cir(
                                  data.locations.seo_description_sr
                                );
                                newData.locations.seo_title_cp = lat2cir(
                                  data.locations.seo_title_sr
                                );
                                setData(newData);
                              }}
                            >
                              {"Preslovi u ćirilicu >>"}
                            </Button>
                          </div>
                        </div>
                        {global.getUseCP() && (
                          <div className="col-4">
                            <Form.Label
                              className={
                                data.locations.seo_title_cp === ""
                                  ? "mb-0 standard-size red"
                                  : "mb-0 standard-size"
                              }
                            >
                              SEO naslov srpski (ćir)
                            </Form.Label>
                            <InputGroup className="mb-2">
                              <Form.Control
                                className="form-control form-control-sm standard-size"
                                type="text"
                                value={data.locations.seo_title_cp}
                                onChange={(cur) => {
                                  let newData = { ...data };
                                  newData.locations.seo_title_cp =
                                    cur.target.value;
                                  setData(newData);
                                }}
                              />
                            </InputGroup>
                            <Form.Label
                              className={
                                data.locations.seo_description_cp === ""
                                  ? "mb-0 standard-size red"
                                  : "mb-0 standard-size"
                              }
                            >
                              SEO opis srpski (ćir)
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                className="form-control form-control-sm standard-size"
                                type="text"
                                rows={10}
                                as="textarea"
                                value={data.locations.seo_description_cp}
                                onChange={(cur) => {
                                  let newData = { ...data };
                                  newData.locations.seo_description_cp =
                                    cur.target.value;
                                  setData(newData);
                                }}
                              />
                            </InputGroup>
                            <div className="col mt-3">
                              <Button
                                className="btn-primary"
                                size="sm"
                                title="Preslovi u latinicu"
                                disabled={
                                  data.locations.seo_title_cp === "" ||
                                  data.locations.seo_description_cp === ""
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  let newData = { ...data };
                                  newData.locations.seo_description_sr =
                                    cir2lat(data.locations.seo_description_cp);
                                  newData.locations.seo_title_sr = cir2lat(
                                    data.locations.seo_title_cp
                                  );
                                  setData(newData);
                                }}
                              >
                                {"<< Preslovi u latinicu"}
                              </Button>
                            </div>
                          </div>
                        )}
                        <div className={global.getUseCP() ? "col-4" : "col-6"}>
                          <Form.Label
                            className={
                              data.locations.seo_title_en === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO naslov engleski
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data.locations.seo_title_en}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.locations.seo_title_en =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <Form.Label
                            className={
                              data.locations.seo_description_en === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO opis engleski
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={10}
                              as="textarea"
                              value={data.locations.seo_description_en}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.locations.seo_description_en =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="objects" title="Lokacije - podgrupa">
                      <div className="row mb-3">
                        <div className="col-8 pt-2">
                          <div className="small-url">
                            URL (primer):{" "}
                            <a
                              href={
                                global.baseUrl() +
                                data.example.stub_1 +
                                "/" +
                                data.example.stub_2 +
                                "/"
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              {global.baseUrl() +
                                data.example.stub_1 +
                                "/" +
                                data.example.stub_2 +
                                "/"}
                            </a>
                          </div>
                        </div>
                        <div className="col-4 text-right">
                          <Button
                            className="btn-primary"
                            size="sm"
                            title="Uvezi podrazumevane vrednosti"
                            onClick={() => importDefault("objects")}
                          >
                            Uvezi podrazumevane vrednosti{" "}
                            {importingData ? (
                              <i className="ms-1 fa fa-spinner fa-spin"></i>
                            ) : null}
                          </Button>
                        </div>
                      </div>
                      <div className="row">
                        <div className={global.getUseCP() ? "col-4" : "col-6"}>
                          <Form.Label
                            className={
                              data.objects.seo_title_sr === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            {global.getUseCP()
                              ? "SEO naslov srpski (lat)"
                              : "SEO naslov srpski"}
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data.objects.seo_title_sr}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.objects.seo_title_sr = cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <Form.Label
                            className={
                              data.objects.seo_description_sr === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            {global.getUseCP()
                              ? "SEO opis srpski (lat)"
                              : "SEO opis srpski"}
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={10}
                              as="textarea"
                              value={data.objects.seo_description_sr}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.objects.seo_description_sr =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <div className="col mt-3 text-right">
                            <Button
                              className="btn-primary"
                              size="sm"
                              title="Preslovi u ćirilicu"
                              disabled={
                                data.objects.seo_title_sr === "" ||
                                data.objects.seo_description_sr === ""
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                let newData = { ...data };
                                newData.objects.seo_description_cp = lat2cir(
                                  data.objects.seo_description_sr
                                );
                                newData.objects.seo_title_cp = lat2cir(
                                  data.objects.seo_title_sr
                                );
                                setData(newData);
                              }}
                            >
                              {"Preslovi u ćirilicu >>"}
                            </Button>
                          </div>
                        </div>
                        {global.getUseCP() && (
                          <div className="col-4">
                            <Form.Label
                              className={
                                data.objects.seo_title_cp === ""
                                  ? "mb-0 standard-size red"
                                  : "mb-0 standard-size"
                              }
                            >
                              SEO naslov srpski (ćir)
                            </Form.Label>
                            <InputGroup className="mb-2">
                              <Form.Control
                                className="form-control form-control-sm standard-size"
                                type="text"
                                value={data.objects.seo_title_cp}
                                onChange={(cur) => {
                                  let newData = { ...data };
                                  newData.objects.seo_title_cp =
                                    cur.target.value;
                                  setData(newData);
                                }}
                              />
                            </InputGroup>
                            <Form.Label
                              className={
                                data.objects.seo_description_cp === ""
                                  ? "mb-0 standard-size red"
                                  : "mb-0 standard-size"
                              }
                            >
                              SEO opis srpski (ćir)
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                className="form-control form-control-sm standard-size"
                                type="text"
                                rows={10}
                                as="textarea"
                                value={data.objects.seo_description_cp}
                                onChange={(cur) => {
                                  let newData = { ...data };
                                  newData.objects.seo_description_cp =
                                    cur.target.value;
                                  setData(newData);
                                }}
                              />
                            </InputGroup>
                            <div className="col mt-3">
                              <Button
                                className="btn-primary"
                                size="sm"
                                title="Preslovi u latinicu"
                                disabled={
                                  data.objects.seo_title_cp === "" ||
                                  data.objects.seo_description_cp === ""
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  let newData = { ...data };
                                  newData.objects.seo_description_sr = cir2lat(
                                    data.objects.seo_description_cp
                                  );
                                  newData.objects.seo_title_sr = cir2lat(
                                    data.objects.seo_title_cp
                                  );
                                  setData(newData);
                                }}
                              >
                                {"<< Preslovi u latinicu"}
                              </Button>
                            </div>
                          </div>
                        )}
                        <div className={global.getUseCP() ? "col-4" : "col-6"}>
                          <Form.Label
                            className={
                              data.objects.seo_title_en === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO naslov engleski
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data.objects.seo_title_en}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.objects.seo_title_en = cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <Form.Label
                            className={
                              data.objects.seo_description_en === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO opis engleski
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={10}
                              as="textarea"
                              value={data.objects.seo_description_en}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.objects.seo_description_en =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="events" title="Najave">
                      <div className="row mb-3">
                        <div className="col-8 pt-2">
                          <div className="small-url">
                            URL:{" "}
                            <a
                              href={global.baseUrl() + "najave/"}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {global.baseUrl() + "najave/"}
                            </a>
                          </div>
                        </div>
                        <div className="col-4 text-right">
                          <Button
                            className="btn-primary"
                            size="sm"
                            title="Uvezi podrazumevane vrednosti"
                            onClick={() => importDefault("events")}
                          >
                            Uvezi podrazumevane vrednosti{" "}
                            {importingData ? (
                              <i className="ms-1 fa fa-spinner fa-spin"></i>
                            ) : null}
                          </Button>
                        </div>
                      </div>
                      <div className="row">
                        <div className={global.getUseCP() ? "col-4" : "col-6"}>
                          <Form.Label
                            className={
                              data.events.seo_title_sr === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            {global.getUseCP()
                              ? "SEO naslov srpski (lat)"
                              : "SEO naslov srpski"}
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data.events.seo_title_sr}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.events.seo_title_sr = cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <Form.Label
                            className={
                              data.events.seo_description_sr === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            {global.getUseCP()
                              ? "SEO opis srpski (lat)"
                              : "SEO opis srpski"}
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={10}
                              as="textarea"
                              value={data.events.seo_description_sr}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.events.seo_description_sr =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <div className="col mt-3 text-right">
                            <Button
                              className="btn-primary"
                              size="sm"
                              title="Preslovi u ćirilicu"
                              disabled={
                                data.events.seo_title_sr === "" ||
                                data.events.seo_description_sr === ""
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                let newData = { ...data };
                                newData.events.seo_description_cp = lat2cir(
                                  data.events.seo_description_sr
                                );
                                newData.events.seo_title_cp = lat2cir(
                                  data.events.seo_title_sr
                                );
                                setData(newData);
                              }}
                            >
                              {"Preslovi u ćirilicu >>"}
                            </Button>
                          </div>
                        </div>
                        {global.getUseCP() && (
                          <div className="col-4">
                            <Form.Label
                              className={
                                data.events.seo_title_cp === ""
                                  ? "mb-0 standard-size red"
                                  : "mb-0 standard-size"
                              }
                            >
                              SEO naslov srpski (ćir)
                            </Form.Label>
                            <InputGroup className="mb-2">
                              <Form.Control
                                className="form-control form-control-sm standard-size"
                                type="text"
                                value={data.events.seo_title_cp}
                                onChange={(cur) => {
                                  let newData = { ...data };
                                  newData.events.seo_title_cp =
                                    cur.target.value;
                                  setData(newData);
                                }}
                              />
                            </InputGroup>
                            <Form.Label
                              className={
                                data.events.seo_description_cp === ""
                                  ? "mb-0 standard-size red"
                                  : "mb-0 standard-size"
                              }
                            >
                              SEO opis srpski (ćir)
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                className="form-control form-control-sm standard-size"
                                type="text"
                                rows={10}
                                as="textarea"
                                value={data.events.seo_description_cp}
                                onChange={(cur) => {
                                  let newData = { ...data };
                                  newData.events.seo_description_cp =
                                    cur.target.value;
                                  setData(newData);
                                }}
                              />
                            </InputGroup>
                            <div className="col mt-3">
                              <Button
                                className="btn-primary"
                                size="sm"
                                title="Preslovi u latinicu"
                                disabled={
                                  data.events.seo_title_cp === "" ||
                                  data.events.seo_description_cp === ""
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  let newData = { ...data };
                                  newData.events.seo_description_sr = cir2lat(
                                    data.events.seo_description_cp
                                  );
                                  newData.events.seo_title_sr = cir2lat(
                                    data.events.seo_title_cp
                                  );
                                  setData(newData);
                                }}
                              >
                                {"<< Preslovi u latinicu"}
                              </Button>
                            </div>
                          </div>
                        )}
                        <div className={global.getUseCP() ? "col-4" : "col-6"}>
                          <Form.Label
                            className={
                              data.events.seo_title_en === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO naslov engleski
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data.events.seo_title_en}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.events.seo_title_en = cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <Form.Label
                            className={
                              data.events.seo_description_en === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO opis engleski
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={10}
                              as="textarea"
                              value={data.events.seo_description_en}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.events.seo_description_en =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="news" title="Vesti">
                      <div className="row mb-3">
                        <div className="col-8 pt-2">
                          <div className="small-url">
                            URL:{" "}
                            <a
                              href={global.baseUrl() + "vesti/"}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {global.baseUrl() + "vesti/"}
                            </a>
                          </div>
                        </div>
                        <div className="col-4 text-right">
                          <Button
                            className="btn-primary"
                            size="sm"
                            title="Uvezi podrazumevane vrednosti"
                            onClick={() => importDefault("news")}
                          >
                            Uvezi podrazumevane vrednosti{" "}
                            {importingData ? (
                              <i className="ms-1 fa fa-spinner fa-spin"></i>
                            ) : null}
                          </Button>
                        </div>
                      </div>
                      <div className="row">
                        <div className={global.getUseCP() ? "col-4" : "col-6"}>
                          <Form.Label
                            className={
                              data.news.seo_title_sr === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            {global.getUseCP()
                              ? "SEO naslov srpski (lat)"
                              : "SEO naslov srpski"}
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data.news.seo_title_sr}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.news.seo_title_sr = cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <Form.Label
                            className={
                              data.news.seo_description_sr === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            {global.getUseCP()
                              ? "SEO opis srpski (lat)"
                              : "SEO opis srpski"}
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={10}
                              as="textarea"
                              value={data.news.seo_description_sr}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.news.seo_description_sr =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <div className="col mt-3 text-right">
                            <Button
                              className="btn-primary"
                              size="sm"
                              title="Preslovi u ćirilicu"
                              disabled={
                                data.news.seo_title_sr === "" ||
                                data.news.seo_description_sr === ""
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                let newData = { ...data };
                                newData.news.seo_description_cp = lat2cir(
                                  data.news.seo_description_sr
                                );
                                newData.news.seo_title_cp = lat2cir(
                                  data.news.seo_title_sr
                                );
                                setData(newData);
                              }}
                            >
                              {"Preslovi u ćirilicu >>"}
                            </Button>
                          </div>
                        </div>
                        {global.getUseCP() && (
                          <div className="col-4">
                            <Form.Label
                              className={
                                data.news.seo_title_cp === ""
                                  ? "mb-0 standard-size red"
                                  : "mb-0 standard-size"
                              }
                            >
                              SEO naslov srpski (ćir)
                            </Form.Label>
                            <InputGroup className="mb-2">
                              <Form.Control
                                className="form-control form-control-sm standard-size"
                                type="text"
                                value={data.news.seo_title_cp}
                                onChange={(cur) => {
                                  let newData = { ...data };
                                  newData.news.seo_title_cp = cur.target.value;
                                  setData(newData);
                                }}
                              />
                            </InputGroup>
                            <Form.Label
                              className={
                                data.news.seo_description_cp === ""
                                  ? "mb-0 standard-size red"
                                  : "mb-0 standard-size"
                              }
                            >
                              SEO opis srpski (ćir)
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                className="form-control form-control-sm standard-size"
                                type="text"
                                rows={10}
                                as="textarea"
                                value={data.news.seo_description_cp}
                                onChange={(cur) => {
                                  let newData = { ...data };
                                  newData.news.seo_description_cp =
                                    cur.target.value;
                                  setData(newData);
                                }}
                              />
                            </InputGroup>
                            <div className="col mt-3">
                              <Button
                                className="btn-primary"
                                size="sm"
                                title="Preslovi u latinicu"
                                disabled={
                                  data.news.seo_title_cp === "" ||
                                  data.news.seo_description_cp === ""
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  let newData = { ...data };
                                  newData.news.seo_description_sr = cir2lat(
                                    data.news.seo_description_cp
                                  );
                                  newData.news.seo_title_sr = cir2lat(
                                    data.news.seo_title_cp
                                  );
                                  setData(newData);
                                }}
                              >
                                {"<< Preslovi u latinicu"}
                              </Button>
                            </div>
                          </div>
                        )}
                        <div className={global.getUseCP() ? "col-4" : "col-6"}>
                          <Form.Label
                            className={
                              data.news.seo_title_en === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO naslov engleski
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data.news.seo_title_en}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.news.seo_title_en = cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <Form.Label
                            className={
                              data.news.seo_description_en === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO opis engleski
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={10}
                              as="textarea"
                              value={data.news.seo_description_en}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.news.seo_description_en =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="cookies" title="Kolačići">
                      <div className="row mb-3">
                        <div className="col-8 pt-2">
                          <div className="small-url">
                            URL:{" "}
                            <a
                              href={global.baseUrl() + "politika-o-kolacicima/"}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {global.baseUrl() + "politika-o-kolacicima/"}
                            </a>
                          </div>
                        </div>
                        <div className="col-4 text-right">
                          <Button
                            className="btn-primary"
                            size="sm"
                            title="Uvezi podrazumevane vrednosti"
                            onClick={() => importDefault("cookies")}
                          >
                            Uvezi podrazumevane vrednosti{" "}
                            {importingData ? (
                              <i className="ms-1 fa fa-spinner fa-spin"></i>
                            ) : null}
                          </Button>
                        </div>
                      </div>
                      <div className="row">
                        <div className={global.getUseCP() ? "col-4" : "col-6"}>
                          <Form.Label
                            className={
                              data.cookies.seo_title_sr === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            {global.getUseCP()
                              ? "SEO naslov srpski (lat)"
                              : "SEO naslov srpski"}
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data.cookies.seo_title_sr}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.cookies.seo_title_sr = cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <Form.Label
                            className={
                              data.cookies.seo_description_sr === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            {global.getUseCP()
                              ? "SEO opis srpski (lat)"
                              : "SEO opis srpski"}
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={10}
                              as="textarea"
                              value={data.cookies.seo_description_sr}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.cookies.seo_description_sr =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <div className="col mt-3 text-right">
                            <Button
                              className="btn-primary"
                              size="sm"
                              title="Preslovi u ćirilicu"
                              disabled={
                                data.cookies.seo_title_sr === "" ||
                                data.cookies.seo_description_sr === ""
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                let newData = { ...data };
                                newData.cookies.seo_description_cp = lat2cir(
                                  data.cookies.seo_description_sr
                                );
                                newData.cookies.seo_title_cp = lat2cir(
                                  data.cookies.seo_title_sr
                                );
                                setData(newData);
                              }}
                            >
                              {"Preslovi u ćirilicu >>"}
                            </Button>
                          </div>
                        </div>
                        {global.getUseCP() && (
                          <div className="col-4">
                            <Form.Label
                              className={
                                data.cookies.seo_title_cp === ""
                                  ? "mb-0 standard-size red"
                                  : "mb-0 standard-size"
                              }
                            >
                              SEO naslov srpski (ćir)
                            </Form.Label>
                            <InputGroup className="mb-2">
                              <Form.Control
                                className="form-control form-control-sm standard-size"
                                type="text"
                                value={data.cookies.seo_title_cp}
                                onChange={(cur) => {
                                  let newData = { ...data };
                                  newData.cookies.seo_title_cp =
                                    cur.target.value;
                                  setData(newData);
                                }}
                              />
                            </InputGroup>
                            <Form.Label
                              className={
                                data.cookies.seo_description_cp === ""
                                  ? "mb-0 standard-size red"
                                  : "mb-0 standard-size"
                              }
                            >
                              SEO opis srpski (ćir)
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                className="form-control form-control-sm standard-size"
                                type="text"
                                rows={10}
                                as="textarea"
                                value={data.cookies.seo_description_cp}
                                onChange={(cur) => {
                                  let newData = { ...data };
                                  newData.cookies.seo_description_cp =
                                    cur.target.value;
                                  setData(newData);
                                }}
                              />
                            </InputGroup>
                            <div className="col mt-3">
                              <Button
                                className="btn-primary"
                                size="sm"
                                title="Preslovi u latinicu"
                                disabled={
                                  data.cookies.seo_title_cp === "" ||
                                  data.cookies.seo_description_cp === ""
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  let newData = { ...data };
                                  newData.cookies.seo_description_sr = cir2lat(
                                    data.cookies.seo_description_cp
                                  );
                                  newData.cookies.seo_title_sr = cir2lat(
                                    data.cookies.seo_title_cp
                                  );
                                  setData(newData);
                                }}
                              >
                                {"<< Preslovi u latinicu"}
                              </Button>
                            </div>
                          </div>
                        )}
                        <div className={global.getUseCP() ? "col-4" : "col-6"}>
                          <Form.Label
                            className={
                              data.cookies.seo_title_en === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO naslov engleski
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data.cookies.seo_title_en}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.cookies.seo_title_en = cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <Form.Label
                            className={
                              data.cookies.seo_description_en === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO opis engleski
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={10}
                              as="textarea"
                              value={data.cookies.seo_description_en}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.cookies.seo_description_en =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="privacyPolicy" title="Politika privatnosti">
                      <div className="row mb-3">
                        <div className="col-8 pt-2">
                          <div className="small-url">
                            URL:{" "}
                            <a
                              href={global.baseUrl() + "politika-privatnosti/"}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {global.baseUrl() + "politika-privatnosti/"}
                            </a>
                          </div>
                        </div>
                        <div className="col-4 text-right">
                          <Button
                            className="btn-primary"
                            size="sm"
                            title="Uvezi podrazumevane vrednosti"
                            onClick={() => importDefault("privacyPolicy")}
                          >
                            Uvezi podrazumevane vrednosti{" "}
                            {importingData ? (
                              <i className="ms-1 fa fa-spinner fa-spin"></i>
                            ) : null}
                          </Button>
                        </div>
                      </div>
                      <div className="row">
                        <div className={global.getUseCP() ? "col-4" : "col-6"}>
                          <Form.Label
                            className={
                              data.privacyPolicy.seo_title_sr === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            {global.getUseCP()
                              ? "SEO naslov srpski (lat)"
                              : "SEO naslov srpski"}
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data.privacyPolicy.seo_title_sr}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.privacyPolicy.seo_title_sr =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <Form.Label
                            className={
                              data.privacyPolicy.seo_description_sr === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            {global.getUseCP()
                              ? "SEO opis srpski (lat)"
                              : "SEO opis srpski"}
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={10}
                              as="textarea"
                              value={data.privacyPolicy.seo_description_sr}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.privacyPolicy.seo_description_sr =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <div className="col mt-3 text-right">
                            <Button
                              className="btn-primary"
                              size="sm"
                              title="Preslovi u ćirilicu"
                              disabled={
                                data.privacyPolicy.seo_title_sr === "" ||
                                data.privacyPolicy.seo_description_sr === ""
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                let newData = { ...data };
                                newData.privacyPolicy.seo_description_cp =
                                  lat2cir(
                                    data.privacyPolicy.seo_description_sr
                                  );
                                newData.privacyPolicy.seo_title_cp = lat2cir(
                                  data.privacyPolicy.seo_title_sr
                                );
                                setData(newData);
                              }}
                            >
                              {"Preslovi u ćirilicu >>"}
                            </Button>
                          </div>
                        </div>
                        {global.getUseCP() && (
                          <div className="col-4">
                            <Form.Label
                              className={
                                data.privacyPolicy.seo_title_cp === ""
                                  ? "mb-0 standard-size red"
                                  : "mb-0 standard-size"
                              }
                            >
                              SEO naslov srpski (ćir)
                            </Form.Label>
                            <InputGroup className="mb-2">
                              <Form.Control
                                className="form-control form-control-sm standard-size"
                                type="text"
                                value={data.privacyPolicy.seo_title_cp}
                                onChange={(cur) => {
                                  let newData = { ...data };
                                  newData.privacyPolicy.seo_title_cp =
                                    cur.target.value;
                                  setData(newData);
                                }}
                              />
                            </InputGroup>
                            <Form.Label
                              className={
                                data.privacyPolicy.seo_description_cp === ""
                                  ? "mb-0 standard-size red"
                                  : "mb-0 standard-size"
                              }
                            >
                              SEO opis srpski (ćir)
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                className="form-control form-control-sm standard-size"
                                type="text"
                                rows={10}
                                as="textarea"
                                value={data.privacyPolicy.seo_description_cp}
                                onChange={(cur) => {
                                  let newData = { ...data };
                                  newData.privacyPolicy.seo_description_cp =
                                    cur.target.value;
                                  setData(newData);
                                }}
                              />
                            </InputGroup>
                            <div className="col mt-3">
                              <Button
                                className="btn-primary"
                                size="sm"
                                title="Preslovi u latinicu"
                                disabled={
                                  data.privacyPolicy.seo_title_cp === "" ||
                                  data.privacyPolicy.seo_description_cp === ""
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  let newData = { ...data };
                                  newData.privacyPolicy.seo_description_sr =
                                    cir2lat(
                                      data.privacyPolicy.seo_description_cp
                                    );
                                  newData.privacyPolicy.seo_title_sr = cir2lat(
                                    data.privacyPolicy.seo_title_cp
                                  );
                                  setData(newData);
                                }}
                              >
                                {"<< Preslovi u latinicu"}
                              </Button>
                            </div>
                          </div>
                        )}
                        <div className={global.getUseCP() ? "col-4" : "col-6"}>
                          <Form.Label
                            className={
                              data.privacyPolicy.seo_title_en === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO naslov engleski
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data.privacyPolicy.seo_title_en}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.privacyPolicy.seo_title_en =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <Form.Label
                            className={
                              data.privacyPolicy.seo_description_en === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO opis engleski
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={10}
                              as="textarea"
                              value={data.privacyPolicy.seo_description_en}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.privacyPolicy.seo_description_en =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="searchResults" title="Rezultati pretrage">
                      <div className="row mb-3">
                        <div className="col-8 pt-2">
                          <div className="small-url">
                            URL:{" "}
                            <a
                              href={
                                global.baseUrl() +
                                "rezultati-pretrage?search_term=test&search_group=0"
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              {global.baseUrl() +
                                "rezultati-pretrage?search_term=test&search_group=0"}
                            </a>
                          </div>
                        </div>
                        <div className="col-4 text-right">
                          <Button
                            className="btn-primary"
                            size="sm"
                            title="Uvezi podrazumevane vrednosti"
                            onClick={() => importDefault("searchResults")}
                          >
                            Uvezi podrazumevane vrednosti{" "}
                            {importingData ? (
                              <i className="ms-1 fa fa-spinner fa-spin"></i>
                            ) : null}
                          </Button>
                        </div>
                      </div>
                      <div className="row">
                        <div className={global.getUseCP() ? "col-4" : "col-6"}>
                          <Form.Label
                            className={
                              data.searchResults.seo_title_sr === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            {global.getUseCP()
                              ? "SEO naslov srpski (lat)"
                              : "SEO naslov srpski"}
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data.searchResults.seo_title_sr}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.searchResults.seo_title_sr =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <Form.Label
                            className={
                              data.searchResults.seo_description_sr === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            {global.getUseCP()
                              ? "SEO opis srpski (lat)"
                              : "SEO opis srpski"}
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={10}
                              as="textarea"
                              value={data.searchResults.seo_description_sr}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.searchResults.seo_description_sr =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <div className="col mt-3 text-right">
                            <Button
                              className="btn-primary"
                              size="sm"
                              title="Preslovi u ćirilicu"
                              disabled={
                                data.searchResults.seo_title_sr === "" ||
                                data.searchResults.seo_description_sr === ""
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                let newData = { ...data };
                                newData.searchResults.seo_description_cp =
                                  lat2cir(
                                    data.searchResults.seo_description_sr
                                  );
                                newData.searchResults.seo_title_cp = lat2cir(
                                  data.searchResults.seo_title_sr
                                );
                                setData(newData);
                              }}
                            >
                              {"Preslovi u ćirilicu >>"}
                            </Button>
                          </div>
                        </div>
                        {global.getUseCP() && (
                          <div className="col-4">
                            <Form.Label
                              className={
                                data.searchResults.seo_title_cp === ""
                                  ? "mb-0 standard-size red"
                                  : "mb-0 standard-size"
                              }
                            >
                              SEO naslov srpski (ćir)
                            </Form.Label>
                            <InputGroup className="mb-2">
                              <Form.Control
                                className="form-control form-control-sm standard-size"
                                type="text"
                                value={data.searchResults.seo_title_cp}
                                onChange={(cur) => {
                                  let newData = { ...data };
                                  newData.searchResults.seo_title_cp =
                                    cur.target.value;
                                  setData(newData);
                                }}
                              />
                            </InputGroup>
                            <Form.Label
                              className={
                                data.searchResults.seo_description_cp === ""
                                  ? "mb-0 standard-size red"
                                  : "mb-0 standard-size"
                              }
                            >
                              SEO opis srpski (ćir)
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                className="form-control form-control-sm standard-size"
                                type="text"
                                rows={10}
                                as="textarea"
                                value={data.searchResults.seo_description_cp}
                                onChange={(cur) => {
                                  let newData = { ...data };
                                  newData.searchResults.seo_description_cp =
                                    cur.target.value;
                                  setData(newData);
                                }}
                              />
                            </InputGroup>
                            <div className="col mt-3">
                              <Button
                                className="btn-primary"
                                size="sm"
                                title="Preslovi u latinicu"
                                disabled={
                                  data.searchResults.seo_title_cp === "" ||
                                  data.searchResults.seo_description_cp === ""
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  let newData = { ...data };
                                  newData.searchResults.seo_description_sr =
                                    cir2lat(
                                      data.searchResults.seo_description_cp
                                    );
                                  newData.searchResults.seo_title_sr = cir2lat(
                                    data.searchResults.seo_title_cp
                                  );
                                  setData(newData);
                                }}
                              >
                                {"<< Preslovi u latinicu"}
                              </Button>
                            </div>
                          </div>
                        )}
                        <div className={global.getUseCP() ? "col-4" : "col-6"}>
                          <Form.Label
                            className={
                              data.searchResults.seo_title_en === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO naslov engleski
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data.searchResults.seo_title_en}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.searchResults.seo_title_en =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <Form.Label
                            className={
                              data.searchResults.seo_description_en === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO opis engleski
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={10}
                              as="textarea"
                              value={data.searchResults.seo_description_en}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.searchResults.seo_description_en =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="contact" title="Kontakt">
                      <div className="row mb-3">
                        <div className="col-8 pt-2">
                          <div className="small-url">
                            URL:{" "}
                            <a
                              href={global.baseUrl() + "kontakt/"}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {global.baseUrl() + "kontakt/"}
                            </a>
                          </div>
                        </div>
                        <div className="col-4 text-right">
                          <Button
                            className="btn-primary"
                            size="sm"
                            title="Uvezi podrazumevane vrednosti"
                            onClick={() => importDefault("contact")}
                          >
                            Uvezi podrazumevane vrednosti{" "}
                            {importingData ? (
                              <i className="ms-1 fa fa-spinner fa-spin"></i>
                            ) : null}
                          </Button>
                        </div>
                      </div>
                      <div className="row">
                        <div className={global.getUseCP() ? "col-4" : "col-6"}>
                          <Form.Label
                            className={
                              data.contact.seo_title_sr === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            {global.getUseCP()
                              ? "SEO naslov srpski (lat)"
                              : "SEO naslov srpski"}
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data.contact.seo_title_sr}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.contact.seo_title_sr = cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <Form.Label
                            className={
                              data.contact.seo_description_sr === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            {global.getUseCP()
                              ? "SEO opis srpski (lat)"
                              : "SEO opis srpski"}
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={10}
                              as="textarea"
                              value={data.contact.seo_description_sr}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.contact.seo_description_sr =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <div className="col mt-3 text-right">
                            <Button
                              className="btn-primary"
                              size="sm"
                              title="Preslovi u ćirilicu"
                              disabled={
                                data.contact.seo_title_sr === "" ||
                                data.contact.seo_description_sr === ""
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                let newData = { ...data };
                                newData.contact.seo_description_cp = lat2cir(
                                  data.contact.seo_description_sr
                                );
                                newData.contact.seo_title_cp = lat2cir(
                                  data.contact.seo_title_sr
                                );
                                setData(newData);
                              }}
                            >
                              {"Preslovi u ćirilicu >>"}
                            </Button>
                          </div>
                        </div>
                        {global.getUseCP() && (
                          <div className="col-4">
                            <Form.Label
                              className={
                                data.contact.seo_title_cp === ""
                                  ? "mb-0 standard-size red"
                                  : "mb-0 standard-size"
                              }
                            >
                              SEO naslov srpski (ćir)
                            </Form.Label>
                            <InputGroup className="mb-2">
                              <Form.Control
                                className="form-control form-control-sm standard-size"
                                type="text"
                                value={data.contact.seo_title_cp}
                                onChange={(cur) => {
                                  let newData = { ...data };
                                  newData.contact.seo_title_cp =
                                    cur.target.value;
                                  setData(newData);
                                }}
                              />
                            </InputGroup>
                            <Form.Label
                              className={
                                data.contact.seo_description_cp === ""
                                  ? "mb-0 standard-size red"
                                  : "mb-0 standard-size"
                              }
                            >
                              SEO opis srpski (ćir)
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                className="form-control form-control-sm standard-size"
                                type="text"
                                rows={10}
                                as="textarea"
                                value={data.contact.seo_description_cp}
                                onChange={(cur) => {
                                  let newData = { ...data };
                                  newData.contact.seo_description_cp =
                                    cur.target.value;
                                  setData(newData);
                                }}
                              />
                            </InputGroup>
                            <div className="col mt-3">
                              <Button
                                className="btn-primary"
                                size="sm"
                                title="Preslovi u latinicu"
                                disabled={
                                  data.contact.seo_title_cp === "" ||
                                  data.contact.seo_description_cp === ""
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  let newData = { ...data };
                                  newData.contact.seo_description_sr = cir2lat(
                                    data.contact.seo_description_cp
                                  );
                                  newData.contact.seo_title_sr = cir2lat(
                                    data.contact.seo_title_cp
                                  );
                                  setData(newData);
                                }}
                              >
                                {"<< Preslovi u latinicu"}
                              </Button>
                            </div>
                          </div>
                        )}
                        <div className={global.getUseCP() ? "col-4" : "col-6"}>
                          <Form.Label
                            className={
                              data.contact.seo_title_en === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO naslov engleski
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              value={data.contact.seo_title_en}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.contact.seo_title_en = cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                          <Form.Label
                            className={
                              data.contact.seo_description_en === ""
                                ? "mb-0 standard-size red"
                                : "mb-0 standard-size"
                            }
                          >
                            SEO opis engleski
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              className="form-control form-control-sm standard-size"
                              type="text"
                              rows={10}
                              as="textarea"
                              value={data.contact.seo_description_en}
                              onChange={(cur) => {
                                let newData = { ...data };
                                newData.contact.seo_description_en =
                                  cur.target.value;
                                setData(newData);
                              }}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>

      <Loader loading={loading} />

      <div className="card card-small footer-overlay">
        <div className="card-body">
          <Button
            className="ms-2 btn-primary"
            size="sm"
            onClick={handleSave}
            disabled={false}
          >
            Sačuvaj
            {savingData ? <i className="ms-1 fa fa-spinner fa-spin"></i> : null}
          </Button>
        </div>
      </div>

      <ErrorModal show={error} message={errorMessage}></ErrorModal>
    </>
  );
}
