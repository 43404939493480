import React from "react";
import { BallTriangle } from "react-loader-spinner";
export default function Loader(props) {
  const { loading } = props;
  return (
    <>
      {loading && (
        <div className="loader-wrap">
          <div className="loader-inner">
            <BallTriangle
              height={100}
              width={100}
              radius={5}
              color="#01b4bc"
              ariaLabel="ball-triangle-loading"
              wrapperClass={{}}
              wrapperStyle=""
              visible={true}
            />
          </div>
        </div>
      )}
    </>
  );
}
