import React, { useEffect, useState } from "react";
import { Container, Table, Button, Form, InputGroup } from "react-bootstrap";
import ErrorModal from "../components/errorModal";
import VisitsByType from "../components/visitsByType";
import VisitsGeneral from "../components/visitsGeneral";
import VisitsSpread from "../components/visitsSpread";
import Notifications from "../components/notifications";
import Active from "../components/active";
import GeneralData from "../components/generalData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import sr from "date-fns/locale/sr-Latn";
import Badge from "react-bootstrap/Badge";
registerLocale("sr", sr);

export default function Home() {
  const moduleId = "92a1b1aa4ae5b397014eae92d23b7cf9";
  const [dateFrom, setDateFrom] = useState(Date.now() - 518400000);
  const [dateTo, setDateTo] = useState(Date.now());
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_register_activity.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  }, [dateFrom, dateTo, global.getCity()]);

  return (
    <>
      <Container>
        <div className="row">
          <div className="col-md-6 mt-4 mb-4">
            <div
              className="h-100 card card-small"
              style={{ minHeight: "19vw" }}
            >
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                <h5 className="mb-3">Osnovni podaci</h5>
                <GeneralData moduleId={moduleId} />
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-4 mb-4">
            <div
              className="h-100 card card-small"
              style={{ minHeight: "19vw" }}
            >
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                {global.getUserRole() !== "admin" && (
                  <>
                    <h5 className="mb-3">
                      Obaveštenja
                      {global.pendingNotifications() > 0 ? (
                        <Badge className="ms-1 badge-fix" bg="danger">
                          {global.pendingNotifications()}
                        </Badge>
                      ) : null}
                    </h5>
                    <Notifications moduleId={moduleId} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                <div className="row">
                  <div className="col-md-6 m-0">
                    <h3 className="mb-0">Statistika</h3>
                  </div>
                  <div className="col-md-6 m-0">
                    <div className="row">
                      <div className="col-md-6 date-holder">
                        <Form.Label className="mb-0 standard-size date-label">
                          Datum od
                        </Form.Label>
                        <InputGroup className="mb-0 date-field">
                          <DatePicker
                            locale="sr"
                            dateFormat="dd.MM.yyyy."
                            className="form-control form-control-sm standard-size"
                            popperPlacement="bottom"
                            selected={dateFrom}
                            onChange={(date) => {
                              let newDate = Math.floor(
                                new Date(date).getTime()
                              );
                              setDateFrom(newDate);
                              if (newDate >= dateTo) {
                                setDateTo(newDate);
                              }
                            }}
                            popperModifiers={[
                              {
                                name: "offset",
                                options: {
                                  offset: [10, 0],
                                },
                              },
                            ]}
                          />
                        </InputGroup>
                      </div>
                      <div className="col-md-6 date-holder">
                        <Form.Label className="mb-0 standard-size date-label">
                          Datum do
                        </Form.Label>
                        <InputGroup className="mb-0 date-field">
                          <DatePicker
                            locale="sr"
                            dateFormat="dd.MM.yyyy."
                            className="form-control form-control-sm standard-size"
                            popperPlacement="bottom"
                            selected={dateTo}
                            onChange={(date) => {
                              let newDate = Math.floor(
                                new Date(date).getTime()
                              );
                              setDateTo(newDate);
                              if (dateFrom >= newDate) {
                                setDateFrom(newDate);
                              }
                            }}
                            popperModifiers={[
                              {
                                name: "offset",
                                options: {
                                  offset: [10, 0],
                                },
                              },
                            ]}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-4">
            <div
              className="h-100 card card-small"
              style={{ minHeight: "19vw" }}
            >
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                <h5 className="mb-3">Broj pregleda (stranice sa objavama)</h5>
                <VisitsByType
                  moduleId={moduleId}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div
              className="h-100 card card-small"
              style={{ minHeight: "19vw" }}
            >
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                <h5 className="mb-3">Broj pregleda (sve stranice)</h5>
                <VisitsGeneral
                  moduleId={moduleId}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div
              className="h-100 card card-small"
              style={{ minHeight: "19vw" }}
            >
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                <h5 className="mb-3">
                  Broj jedinstvenih korisnika (web / app)
                </h5>
                <VisitsSpread
                  moduleId={moduleId}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div
              className="h-100 card card-small"
              style={{ minHeight: "19vw" }}
            >
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                <h5 className="mb-3">
                  Broj objavljenih/neobjavljenih stranica
                </h5>
                <Active moduleId={moduleId} />
              </div>
            </div>
          </div>
        </div>
      </Container>
      <ErrorModal show={error} message={errorMessage}></ErrorModal>
    </>
  );
}
