import React, { useEffect, useState, useRef } from "react";
import { InputGroup, ToggleButton, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import sr from "date-fns/locale/sr-Latn";
import ErrorModal from "../components/errorModal";
import Loader from "../components/loader";
import { Editor } from "@tinymce/tinymce-react";
registerLocale("sr", sr);

export default function NotificationsEdit() {
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [receivers, setReceivers] = useState([]);
  const [savingData, setSavingData] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const { notification_id } = useParams();
  const [previewModal, setPreviewModal] = useState(false);
  const [previewText, setPreviewText] = useState("");
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const moduleId = "3efcb63b2d22bea513eebd2c6382ff19";
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        notification_id: notification_id,
        city_id: global.getCity(),
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT +
        "_private/_get_single_admin_notification.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((receivedData) => {
        if (!receivedData.response) {
          setData(receivedData.notification);
          setUsers(receivedData.users);
          setReceivers(receivedData.notification.receivers);
          setTimeout(() => {
            setLoading(false);
            setAuthorized(true);
          }, 200);
        } else {
          setErrorMessage(receivedData.response);
          setError(true);
        }
      });
  }, [notification_id]);

  const handleSave = () => {
    setSavingData(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        notification_id: data.notification_id,
        notification_title: data.notification_title,
        notification_text: editorRef.current.getContent(),
        type: data.type,
        receivers: receivers,
        dummy: data.dummy,
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT +
        "_private/_edit_single_admin_notification.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.response) {
          setData(responseData.notification);
          setReceivers(responseData.notification.receivers);
          setTimeout(() => {
            setSavingData(false);
          }, 500);
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  const handleSend = () => {
    setSendingData(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: global.getCity(),
        notification_id: data.notification_id,
        notification_title: data.notification_title,
        notification_text: editorRef.current.getContent(),
        type: data.type,
        receivers: receivers,
        dummy: data.dummy,
        session_id: localStorage.getItem("authToken"),
        module: moduleId,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "_private/_send_notification.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (!responseData.response) {
          setTimeout(() => {
            setData(responseData.notification);
            setReceivers(responseData.notification.receivers);
            setSendingData(false);
          }, 500);
        } else {
          setErrorMessage(responseData.response);
          setError(true);
        }
      });
  };

  const convertDate = (date) => {
    let options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    let dateTime = new Date(date * 1000);
    return dateTime.toLocaleDateString("sr", options);
  };

  return (
    <>
      <div className="container-lg pb-5">
        <div className="row">
          <div className="col-md mt-4 mb-4">
            <div className="h-100 card card-small">
              <div className="d-flex card-body pt-3 pb-3 ps-4 pe-4">
                <h3 className="mb-0">Obaveštenja / Uredi obaveštenje</h3>
              </div>
            </div>
          </div>
        </div>
        {authorized && !loading && (
          <>
            <div className="row">
              <div className="col-md-8 mb-4">
                <div className="h-100 card card-small">
                  <div className="d-flex card-body p-4">
                    <h5 className="mb-3">Sadržaj obaveštenja</h5>
                    <div className="row">
                      <div className="col-md-9">
                        <Form.Label
                          className={
                            data?.notification_title === ""
                              ? "mb-0 standard-size red"
                              : "mb-0 standard-size"
                          }
                        >
                          Naslov
                        </Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Control
                            className="form-control form-control-sm standard-size"
                            type="text"
                            value={data?.notification_title}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.notification_title = cur.target.value;
                              setData(newData);
                            }}
                          />
                        </InputGroup>
                      </div>
                      <div className="col-md-3">
                        <Form.Label className="mb-0 standard-size">
                          Vrsta
                        </Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Select
                            size="sm"
                            className="form-control form-control-sm standard-size"
                            defaultValue={data?.type}
                            onChange={(cur) => {
                              let newData = { ...data };
                              newData.type = cur.target.value;
                              setData(newData);
                            }}
                          >
                            <option
                              //key={category.category_id}
                              value="info"
                            >
                              Informacija
                            </option>
                            <option
                              //key={category.category_id}
                              value="tip"
                            >
                              Savet
                            </option>
                            <option
                              //key={category.category_id}
                              value="warning"
                            >
                              Upozorenje
                            </option>
                          </Form.Select>
                        </InputGroup>
                      </div>
                    </div>

                    <Editor
                      apiKey="gmbdns17h9y2uedqt9u6r4eioxvg9c406ri5hb6p85eycvpn"
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue={data?.notification_text}
                      init={{
                        entity_encoding: "raw",
                        height: 500,
                        menubar: false,
                        plugins: [
                          "advlist",
                          "autolink",
                          "lists",
                          "link",
                          "image",
                          "charmap",
                          "preview",
                          "anchor",
                          "searchreplace",
                          "visualblocks",
                          "code",
                          "fullscreen",
                          "insertdatetime",
                          "media",
                          "table",
                          "code",
                          "help",
                          "wordcount",
                        ],
                        toolbar:
                          "undo redo | blocks | " +
                          "image code table link | bold italic forecolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="h-100 card card-small">
                  <div className="d-flex card-body p-4">
                    <h5 className="mb-3">Status</h5>
                    {data.status === "draft"
                      ? "U pripremi"
                      : "Poslato " + convertDate(data.sent)}

                    <h5 className="mt-3 mb-3">Primaoci</h5>
                    {data.status === "sent" &&
                      receivers.map((receiver) =>
                        users.map(
                          (user) =>
                            receiver.user_id === user.user_id && (
                              <div className="user" key={receiver.user_id}>
                                <div>{user.user_name}</div>
                                <div className="user-report">
                                  <div className="ms-2">
                                    <i
                                      className={
                                        receiver.sent
                                          ? "bi bi-send-check-fill brand"
                                          : "bi bi-send-slash-fill grey"
                                      }
                                      title={
                                        receiver.sent
                                          ? "Poslata"
                                          : "Nije poslata"
                                      }
                                    ></i>
                                  </div>
                                  <div className="ms-2">
                                    <i
                                      className={
                                        receiver.seen
                                          ? "bi bi-eye-fill brand"
                                          : "bi bi-eye-slash-fill grey"
                                      }
                                      title={
                                        receiver.seen
                                          ? "Pročitana"
                                          : "Nije pročitana"
                                      }
                                    ></i>
                                  </div>
                                  <div className="ms-2">
                                    <i
                                      className={
                                        receiver.deleted
                                          ? "bi bi-trash3-fill red"
                                          : "bi bi-trash3-fill grey"
                                      }
                                      title={
                                        receiver.deleted
                                          ? "Obrisana"
                                          : "Nije obrisana"
                                      }
                                    ></i>
                                  </div>
                                </div>
                              </div>
                            )
                        )
                      )}
                    {data.status === "draft" &&
                      users.map((user) => (
                        <Form.Check
                          key={user.user_id}
                          size="sm"
                          className="standard-size"
                          checked={
                            receivers.indexOf(user.user_id) > -1 ? true : false
                          }
                          onChange={(clicked) => {
                            if (clicked.target.checked === true) {
                              setReceivers((current) => [
                                ...current,
                                user.user_id,
                              ]);
                            } else {
                              setReceivers(
                                receivers.filter(
                                  (current) => current !== user.user_id
                                )
                              );
                            }
                          }}
                          type="checkbox"
                          label={user.user_name}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <Loader loading={loading} />

      <div className="card card-small footer-overlay">
        <div className="card-body">
          <Button
            className="ms-2 btn-primary"
            size="sm"
            title="Pregled objave"
            onClick={() => {
              setPreviewText(editorRef.current.getContent());
              setPreviewModal(true);
            }}
          >
            <i className="bi bi-eye-fill"></i>
          </Button>
          {data.status === "draft" && (
            <>
              <Button
                className="ms-2 btn-primary"
                size="sm"
                onClick={handleSave}
                disabled={
                  data?.notification_title === "" || !data ? true : false
                }
              >
                Sačuvaj
                {savingData ? (
                  <i className="ms-1 fa fa-spinner fa-spin"></i>
                ) : null}
              </Button>
              <Button
                className="ms-2 btn-primary"
                size="sm"
                onClick={handleSend}
                disabled={
                  receivers?.length < 1 ||
                  !receivers ||
                  data?.notification_title === "" ||
                  !data
                    ? true
                    : false
                }
              >
                Pošalji
                {sendingData ? (
                  <i className="ms-1 fa fa-spinner fa-spin"></i>
                ) : null}
              </Button>
            </>
          )}
        </div>
      </div>

      {!loading && (
        <Modal
          show={previewModal}
          onHide={() => {
            setPreviewModal(false);
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Pročitaj obaveštenje</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <h5 className="mb-0">{data?.notification_title}</h5>
            <div className="mb-3 small-size">{convertDate(data?.sent)}</div>
            <div
              className="standard-size"
              dangerouslySetInnerHTML={{
                __html: previewText,
              }}
            ></div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setPreviewModal(false);
              }}
            >
              Zatvori
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <ErrorModal show={error} message={errorMessage}></ErrorModal>
    </>
  );
}
